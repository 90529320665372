import { Button } from "@progress/kendo-react-buttons";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Input, TextBox, Checkbox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { pencilIcon, trashIcon } from "@progress/kendo-svg-icons";
import Breadcrumbs from "../components/Breadcrumbs";
import { useEffect, useState } from "react";
import { process, State } from "@progress/kendo-data-query";
import { commonBaseUrl } from "../../services/CommonUtil";

const PackageUtilization = ()=>{
    const [statusDialog, statusDialogSet] = useState(false);
    const [alert, alertSet] = useState("");
    const breadCrumbsData = [
        {
          id: "1",
          text: "Booking Management",
          route: "",
        },
        {
          id: "2",
          text: "Package Utilization",
          route: "",
        },
      ];
      const baseURL = commonBaseUrl();
      const initialDataState: State = {
        take: 5,
        skip: 0,
        group: [],
      };
      const [dataState, setDataState] = useState<State>(initialDataState);
      
      const handlePageChange = (pageState: any) => {
        setDataState({ ...dataState, ...pageState });
      };
    
      const getPageableSettings = () => {
        let len = 50;
        const length = len;
        if (length === 0) {
          return false;
        } else if (length <= 5) {
          return { pageSizes: false, buttonCount: 5 };
        } else if (length <= 10) {
          return { pageSizes: [5, 10], buttonCount: 5 };
        } else if (length <= 20) {
          return { pageSizes: [5, 10, 20], buttonCount: 5 };
        } else {
          return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
        }
      };
      const [searchTerm, setSearchTerm] = useState("");

      const data = [
        { sno: '1' , bookingid: '001', qty: '2 Tea,4 WaterBottle', package: 'Package 1', user: 'ABC Corp', consume: '-', remaining: '-' },
        { sno: '2' , bookingid: '002', qty: '2 Tea,10 WaterBottle', package: 'Package 2', user: 'Corp', consume: '-', remaining: '-'  },
        { sno: '3' , bookingid: '003', qty: '20 Tea,1 WaterBottle', package: 'Package 3', user: 'ABC', consume: '-', remaining: '-'  },
        { sno: '4' , bookingid: '004', qty: '207 Tea,70 WaterBottle', package: 'Package 4', user: 'ABC ABC', consume: '-', remaining: '-'  },
    ];
      const [packageutilize, packageutilizeSet] = useState(data);
      const [filteredData, setFilteredData] = useState(packageutilize);
      useEffect(() => {
        if (searchTerm) {
          const lowerCaseTerm = searchTerm.toLowerCase();
        //   setFilteredData(
        //     packageutilize.filter(
        //       (item: any) =>
        //         item.Name.toLowerCase().includes(lowerCaseTerm)
        //     )
        //   );
          setDataState(initialDataState);
        } else {
        //   setFilteredData(data);
        }
      }, [searchTerm, packageutilize]);
    
      const handleSearchChange = (e: any) => {
        setSearchTerm(e.target.value);
      };
    
      const handleDataStateChange = (e: any) => {
        setDataState(e.dataState);
      };
     
      const [visibleDialog, visibleDialogSet] = useState(false);
      const [dataItem, dataItemSet] = useState<any>({});
      useEffect(()=>{
        console.log("The updated data item was -->", dataItem.consume);
      },[dataItem])
    return ( 
    <div className="overall-view ">
        {visibleDialog == true ? (
        <Dialog
          height={400}
          onClose={() => visibleDialogSet(false)}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "350px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px",
            }}
          >
            <div className="dialog-content">
              <h1>Edit Quantities</h1>
              <div>
                <div className="dialog-item">
                  <div>
                    Description <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                  value={dataItem.qty}
                    maxLength={25}
                    />
                </div>
              </div>
              <div>
                <div className="dialog-item">
                  <div>
                    Quantity
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    type = "number"
                    maxLength={25}
                    onChange={(e) => {
                      console.log("Changed amenity qty", e.value);
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#42B677",
                      padding: "10px",
                      color: "#fff",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    UPDATE
                  </Button>
                  <div style={{ padding: "10px" }} />
                  <Button
                    onClick={(e) => {
                      visibleDialogSet(false);
                    //   amenityErrorSet("");
                    //   amenitydescriptionErrorSet("");
                    }}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#ED823A",
                      padding: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color: "#fff",
                    }}
                  >
                    CANCEL
                  </Button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <div> </div>
      )}
        {statusDialog && (
          <Dialog>
            <div
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                alignContent: "center",
              }}
            >
              <h3>{alert}</h3>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  paddingTop: "30px",
                }}
              >
                <Button
                  className="button-view"
                  onClick={() => window.location.reload()}
                >
                  Ok
                </Button>
              </div>
            </div>
          </Dialog>
        )}
        <div className="overall-view">
          <Breadcrumbs breadCrumbData={breadCrumbsData} />
          <hr className="hr-border" />
        </div>
        <div className="view-margin">
          <div className="common-margin view-margin">
            <div>
              <Label><b>List of Utilized Packages</b></Label>
              <Grid
                data={process(filteredData, dataState)}
                pageable={getPageableSettings()}
                skip={dataState.skip}
                take={dataState.take}
                total={filteredData.length}
                onPageChange={(e) => setDataState(e.page)}
                onDataStateChange={handleDataStateChange}
              >
                <GridColumn
                  field="sno"
                  title="S.No."
                  width="75px"
                  cell={(props) => <td>{props.dataIndex + 1}</td>}
                />
                <GridColumn
                  field="bookingid"
                  title="Booking Id"
                  cell={(props) => <td>{props.dataItem.bookingid}</td>}
                />
                <GridColumn
                  field="user"
                  title="User Name"
                  cell={(props) => <td>{props.dataItem.user}</td>}
                />
                 <GridColumn
                  field="package"
                  title="Package"
                //   cell={(props) => (
                //     <td>
                //       {props.dataItem.IsAvailable === true ? "Available" : "Unavailable"}
                //     </td>
                //   )}
                />
                <GridColumn
                  field="qty"
                  title="Quantity (inUnits)"
                  cell={(props) => <td>{props.dataItem.qty}</td>}
                />
                <GridColumn
                  field="consume"
                  title="Consumed Units"
                  cell={(props) => <td>{props.dataItem.consume}</td>}
                />
                <GridColumn
                  field="remaining"
                  title="Remaining Units"
                  cell={(props) => <td>{props.dataItem.remaining}</td>}
                />
                <GridColumn
                  title="Action"
                  className="header-center"
                  width="300px"
                  cell={(props) => (
                    <td>
                      <Button
                        className="edit-color"
                        disabled = {props.dataItem.remaining === "0"}
                        // disabled = {true}
                        onClick={() => {
                            console.log(props.dataItem.remaining);
                            // enableField(props.dataItem);
                            dataItemSet(props.dataItem);
                            visibleDialogSet(true);
                          console.log("Selected Data", props.dataItem);
                        }}
                        svgIcon={pencilIcon}
                      />
                    </td>
                  )}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
    )
}
export default PackageUtilization;