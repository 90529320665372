import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, RadioButton, TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import {
  checkboxCheckedIcon,
  chevronDownIcon,
  chevronLeftIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import axios from "axios";
import { MyCommandCell } from "./common/MyCommandCell";
import { deleteItem, insertItem, getItems } from "./common/MyCommandService";
import { duration } from "moment";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Card } from "@progress/kendo-react-layout";
import { commonBaseUrl } from "../../services/CommonUtil";

interface DurationStructure {
  Id: number;
  DurationType?: String;
  inEdit?: boolean | string;
}

interface durationDataList {
  sno: number;
  Id: number;
  DurationName: string;
  DurationType: string;
  HasVarient: string;
  VarientName: string;
}

export const Duration = () => {
  const baseURL = commonBaseUrl();
  const hasFetched = useRef(false);
  const [durations, setDurations] = useState([
    { id: 1, type: "One Hour" },
    { id: 2, type: "Four Hours" },
  ]);
  const initialDataState: State = {
    take: 5,
    skip: 0,
    group: [],
  };
  const [newDuration, setNewDuration] = useState("");
  const [editID, setEditID] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [newDurationError, newDurationErrorSet] = useState("");
  const [newVarientError, newVarientErrorSet] = useState("");
  const [newTypeError, newTypeErrorSet] = useState("");
  const [newVarient1Error, newVarient1ErrorSet] = useState("");
  const [newVariantTypeError, newVariantTypeErrorSet] = useState("");
  const [newVarient2Error, newVarient2ErrorSet] = useState("");
  var editEnable = false;
  const [edit, setEdit] = useState(false);
  const [newCheck, newCheckSet] = useState(false);
  const [isHourlyCheck, setIsHourlyCheck] = useState(false);
  const [updateHourlyCheck, updateHourlyCheckSet] = useState(false);
  const [newVarient1Check, newVarient1CheckSet] = useState(false);
  const [newVarient2Check, newVarient2CheckSet] = useState(false);

  const [durationList, setDurationList] = useState<any>([]);
  const [durationTypeList, setDurationTypeList] = useState<any>([]);
  const defaultType = { id: null, duration_type: "Select Type" };
  const [selectedType, selectedTypeSet] = useState(defaultType);
  const [selectedValue, selectedValueSet] = useState("");
  const handleDropdown = (e: any) => {
    console.log("Value from dropdown change-->", e.value);
    selectedTypeSet(e.value);
    if (e.value.duration_type === defaultType.duration_type) {
      newTypeErrorSet("Type is required");
    } else {
      newTypeErrorSet("");
    }
    if (e.value.duration_type === "Hourly") {
      setIsHourlyCheck(true);
    } else {
      setIsHourlyCheck(false);
    }
  };

  const [firsttime, setFirstTime] = useState(true);
  const [variant1, variant1Set] = useState("");
  const [variant2, variant2Set] = useState("");
  const [updateType, updateTypeSet] = useState({
    id: null,
    duration_type: "Choose",
  });
  const [updateValue, updateValueSet] = useState("");
  const [updateVariant1, updateVariant1Set] = useState("");
  const [updateVariant2, updateVariant2Set] = useState("");
  const updateDropdown = (e: any) => {
    console.log("Value from dropdown change-->", e.value);
    updateTypeSet(e.value);
    if (e.value.duration_type === "Hourly") {
      updateHourlyCheckSet(true);
    } else {
      updateHourlyCheckSet(false);
      updateValueSet("2");
      variant1Set("");
      variant2Set("");
    }
  };
  const updateVariant = (e: any) => {
    console.log("Value from dropdown change-->", e.value);
    updateVariantTypeSet(e.value);
    if (e.value.id !== null) {
      variantTypeErrorSet("");
    }
  };
  // Add duration
  const addDuration = () => {
    if (newDuration.trim() === "") {
      newCheckSet(true);
      newDurationErrorSet("Duration name is required");
    }

    if (selectedType.duration_type === defaultType.duration_type) {
      newTypeErrorSet("Type is required");
    }

    if (isHourlyCheck === true) {
      newVarient1CheckSet(true);
      newVarient2CheckSet(true);
      if (selectedValue === "") {
        newVarientErrorSet("Has varient is required");
      }
      if (selectedVariantType.id === defaultVariantType.id) {
        newVariantTypeErrorSet("Variant Type is required");
      } else {
        newVariantTypeErrorSet("");
      }
      console.log("The selected variant type is =>", selectedVariantType);
    }
    if (
      newDuration.trim() !== "" &&
      selectedType.duration_type !== defaultType.duration_type &&
      isHourlyCheck === true &&
      selectedValue.trim() !== "" &&
      selectedValue.trim() !== "2" &&
      selectedVariantType.id !== defaultVariantType.id
    ) {
      newDurationApi(newDuration);
    } else if (
      newDuration.trim() !== "" &&
      selectedType.duration_type !== defaultType.duration_type &&
      isHourlyCheck === false
    ) {
      newDurationApi(newDuration);
    } else if (
      newDuration.trim() !== "" &&
      selectedType.duration_type !== defaultType.duration_type &&
      isHourlyCheck === true &&
      selectedValue.trim() !== "" &&
      selectedValue.trim() !== "1"
    ) {
      newDurationApi(newDuration);
    }
  };

  const newDurationApi = async (name: any) => {
    let createDuration = {
      durationType: name,
      description: name,
      durationTypeId: selectedType.id,
      hasVarient: selectedType.id === 1 ? parseInt(selectedValue) : 2,
      varient1:
        parseInt(selectedValue) === 1 && selectedType.id === 1
          ? selectedVariantType.varientName
          : "",
      varinetId: selectedVariantType.id,
    };
    console.log(createDuration, "Received data");
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(
        `${baseURL}odata/Durations`,
        createDuration
      );
      console.log("Duration Added", response.data);
      alertSet("Duration Added successfully");
      statusDialogSet(true);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };

  // Reset input fields
  const clearInput = () => {
    selectedTypeSet(defaultType);
    setNewDuration("");
    newCheckSet(false);
    setIsHourlyCheck(false);
    newCheckSet(false);
    newVarient1CheckSet(false);
    newVarient2CheckSet(false);
    newDurationErrorSet("");
    newTypeErrorSet("");
    newVarient1ErrorSet("");
    newVarient2ErrorSet("");
    newVarientErrorSet("");
    selectedValueSet("");
    variant1Set("");
    variant2Set("");
  };

  // Delete duration particular row
  const deleteDuration = () => {
    setDurations(durations.filter((d) => d.id !== deleteID));
    setDeleteID(null);
  };

  // Pagination navigation
  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  // Enable edit field
  const enableField = (id: any) => {
    getDurationAPI(id.Id);
  };

  // Pagination item view
  const getPageableSettings = () => {
    const length = durationList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, length], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Duration",
      route: "",
    },
  ];

  const [statusDialog, statusDialogSet] = useState(false);
  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    DurationApi();
    DurationVarientTypeApi();
  });

  // All Duration Data
  const DurationApi = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/Durations`);
      const durations: any[] = [];
      const responseData = response.data.value;
      console.log("Duration list =>", responseData);
      for (let d = 0; d < responseData.length; d++) {
        const durationData = {
          Id: responseData[d].Id,
          DurationName: responseData[d].DurationType,
          DurationType: responseData[d].DurationTypeId,
          HasVarient: responseData[d].HasVarient === 1 ? "Yes" : "No",
          VarientName:
            responseData[d].Varient1 === "" ? "-" : responseData[d].Varient1,
        };
        durations.push(durationData);
      }
      DurationTypeApi(durations);
      // SetDurationTypeApi(durations);
    } catch (err) {
      console.log(err);
    }
  };

  // All Duration Type
  const DurationTypeApi = async (durations: any[]) => {
    try {
      const response = await axios.get(`${baseURL}odata/GetAllDurationType`);
      setDurationTypeList(response.data);
      const updatedDuration = await Promise.all(
        durations.map(async (item: durationDataList) => {
          for (let t = 0; t < response.data.length; t++) {
            if (item.DurationType === response.data[t].id) {
              item.DurationType = response.data[t].duration_type || "";
              break;
            }
          }
          return item;
        })
      );
      setDurationList(updatedDuration);
    } catch (err) {}
  };

  const [varientType, varientTypeSet] = useState([]);
  const DurationVarientTypeApi = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/GetAllDurationVarientType`
      );
      console.log("The updated duration varient type ==>", response.data);
      varientTypeSet(response.data);
    } catch (err) {}
  };

  const getDurationAPI = async (DurationType: any) => {
    try {
      const durationData = await axios.get(
        `${baseURL}odata/Durations(${DurationType})`
      );
      console.log(durationData.data.value[0]);
      const responseData = durationData.data.value[0];
      console.log("Duration Data -->", responseData);
      durationIdSet(responseData.Id);
      durationNameSet(responseData.DurationType);
      if (responseData.DurationTypeId === 1) {
        updateHourlyCheckSet(true);
      }
      for (let t = 0; t < durationTypeList.length; t++) {
        if (responseData.DurationTypeId === durationTypeList[t].id) {
          updateTypeSet(durationTypeList[t]);
          break;
        }
      }
      updateValueSet(
        `${
          responseData.HasVarient === null || responseData.HasVarient === 2
            ? 2
            : 1
        }`
      );
      updateVariantTypeSet({
        id: responseData.VarinetId,
        varientName: responseData.Varient1,
      });
      updateVariant1Set(
        responseData.Varient1 === null ? "" : responseData.Varient1
      );
      updateVariant2Set(
        responseData.Varient2 === null ? "" : responseData.Varient2
      );
      durationDescriptionSet(responseData.Description);
      setVisibleDialog(true);
    } catch (err) {}
  };

  const pageSizes = [durationList.length];

  const [durationId, durationIdSet] = useState(0);
  const [durationName, durationNameSet] = useState("");
  const [durationDescription, durationDescriptionSet] = useState("");
  const [durationError, durationErrorSet] = useState("");
  const [varient1Error, varient1ErrorSet] = useState("");
  const [varient2Error, varient2ErrorSet] = useState("");
  const [descriptionError, descriptionErrorSet] = useState("");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [check, checkSet] = useState(false);
  const [varient1Check, varient1CheckSet] = useState(false);
  const [varient2Check, varient2CheckSet] = useState(false);
  const [variantTypeError, variantTypeErrorSet] = useState("");
  const handleView = () => {
    if (durationName.trim() === "") {
      checkSet(true);
      durationErrorSet("Duration name is required");
    } else {
      durationErrorSet("");
    }
    // if (durationDescription === "") {
    //   descriptionErrorSet("Description cannot be empty");
    // } else {
    //   descriptionErrorSet("");
    // }

    if (parseInt(updateValue) === 1) {
      varient1CheckSet(true);
      varient2CheckSet(true);
      console.log("variant1", variant1, "variant2", variant2);
      if (updateVariantType.id === null) {
        variantTypeErrorSet("Please select the variant type");
      } else {
        variantTypeErrorSet("");
      }
    }

    console.log(durationName, updateValue, updateVariant1, updateVariant2);
    if (
      durationName.trim() !== "" &&
      parseInt(updateValue) === 1 &&
      updateVariantType.id !== null
    ) {
      UpdateDuration(durationId);
    } else if (durationName.trim() !== "" && parseInt(updateValue) === 2) {
      UpdateDuration(durationId);
    }

    // if (
    //   durationError === "" &&
    //   descriptionError === "" &&
    //   durationName !== "" &&
    //   durationDescription !== ""
    // ) {
    //   console.log("the Selected id is -->", durationId);
    //   // UpdateDuration(durationId);
    // }
  };
  const [alert, alertSet] = useState("");
  const UpdateDuration = async (Id: any) => {
    let update = {
      id: Id,
      durationType: durationName,
      description: durationDescription,
      durationTypeId: updateType.id,
      hasVarient: updateType.id === 1 ? parseInt(updateValue) : 2,
      varient1:
        parseInt(updateValue) === 1 && updateType.id === 1
          ? updateVariantType.varientName
          : "",
      varinetId: updateVariantType.id,
    };
    console.log("Updated Duration -->", update);
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(
        `${baseURL}odata/UpdateDuration?key=${Id}`,
        update
      );
      console.log("Response -->", response);
      statusDialogSet(true);
      alertSet("Duration updated successfully");
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(durationList);
  useEffect(() => {
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      setFilteredData(
        durationList.filter((item: any) =>
          item.DurationName.toLowerCase().includes(lowerCaseTerm)
        )
      );
      setDataState(initialDataState);
    } else {
      setFilteredData(durationList);
    }
  }, [searchTerm, durationList]);

  // Reset filter values
  const resetSearch = () => {
    setSearchTerm("");
    setFilteredData(durationList); // Reset to original data
  };
  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };
  const defaultVariantType = { id: null, varientName: "Select Variant Type" };
  const [selectedVariantType, selectedVariantTypeSet] =
    useState(defaultVariantType);
  const handleVariantDropdown = (e: any) => {
    console.log("Value from dropdown change-->", e.value);
    selectedVariantTypeSet(e.value);
  };
  const [durationVariantTypeList, durationVariantTypeListSet] = useState<any>(
    []
  );
  const [updateVariantType, updateVariantTypeSet] =
    useState(defaultVariantType);
  return (
    <div className="overall-view ">
      {visibleDialog == true ? (
        <Dialog
          height={
            updateHourlyCheck === true
              ? "90%"
              : updateValue === "1"
              ? "90%"
              : "60%"
          }
          onClose={() => setVisibleDialog(false)}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "100%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px",
            }}
          >
            <div className="dialog-content">
              <h1>Edit Duration</h1>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Name <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    value={durationName}
                    maxLength={25}
                    required={check}
                    onChange={(e) => {
                      durationNameSet(e.value);
                      if (durationName.trim() !== "") {
                        durationErrorSet("");
                      }
                      console.log("Changed duration name", e.value);
                    }}
                  />
                </div>
                {durationError && <Error>{durationError}</Error>}
              </div>
              <div style={{ width: "100%" }}>
                <div className="dialog-item">
                  <div>
                    Type <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <DropDownList
                    data={durationTypeList}
                    svgIcon={chevronDownIcon}
                    dataItemKey="id"
                    textField="duration_type"
                    value={updateType}
                    onChange={updateDropdown}
                  />
                </div>
              </div>
              {updateType.duration_type === "Hourly" && (
                <div style={{ width: "100%" }}>
                  <div className="dialog-item">
                    <div>
                      Has Variant? <span style={{ color: "red" }}>*</span>
                    </div>
                  </div>
                  <div style={{ display: "flex" }}>
                    <RadioButton
                      name="group1"
                      value={updateValue}
                      checked={updateValue === "1"}
                      label="Yes"
                      onChange={(e) => {
                        updateValueSet("1");
                        console.log("Value changed to first");
                      }}
                    />
                    <div style={{ width: "20px" }}></div>
                    <RadioButton
                      name="group1"
                      value={updateValue}
                      checked={updateValue === "2"}
                      label="No"
                      onChange={(e) => {
                        updateValueSet("2");
                        console.log("Value changed from first to second");
                      }}
                    />
                  </div>
                  {updateValue === "1" && (
                    <div style={{ width: "100%", marginTop: "3%" }}>
                      <div style={{ width: "100%" }}>
                        <div className="dialog-item">
                          <div>
                            Variant Type <span style={{ color: "red" }}>*</span>
                          </div>
                        </div>
                        <div className="dialog-item">
                          <DropDownList
                            data={varientType}
                            svgIcon={chevronDownIcon}
                            dataItemKey="id"
                            textField="varientName"
                            value={updateVariantType}
                            onChange={updateVariant}
                          />
                        </div>
                        {variantTypeError && <Error>{variantTypeError}</Error>}
                      </div>
                    </div>
                  )}
                </div>
              )}
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="content-button-padding"> */}
                  <Button
                    onClick={handleView}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#42B677",
                      padding: "10px",
                      color: "#fff",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    {/* <Button primary={true} onClick={handleView} onClickCapture={handleView}> */}
                    UPDATE
                  </Button>
                  <div style={{ padding: "10px" }} />
                  {/* </div> */}
                  {/* <div className="content-button-padding"> */}
                  <Button
                    onClick={(e) => {
                      setVisibleDialog(false);
                      durationErrorSet("");
                      varient1ErrorSet("");
                      varient2ErrorSet("");
                    }}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#ED823A",
                      padding: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color: "#fff",
                    }}
                  >
                    CANCEL
                  </Button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <div> </div>
      )}
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() => window.location.reload()}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin">
          <div className="custom-inputs-container" style={{ display: "flex" }}>
            <div className="custom-inputs-view">
              <Label className="common-label-size">
                Duration Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                // style={{width:"200px",height: "45px"}}
                className="custom-dropdowns"
                type="text"
                name="customername"
                maxLength={25}
                value={newDuration}
                required={newCheck}
                onChange={(e) => {
                  setNewDuration(e.value);
                  if (e.value.trim() !== "") {
                    newDurationErrorSet("");
                    newCheckSet(false);
                  }
                }}
                placeholder="Enter duration name"
              />
              {newDurationError && <Error>{newDurationError}</Error>}
            </div>
            <div style={{ width: "40px" }}> </div>
            <div className="custom-inputs-view">
              <Label className="common-label-size">
                Type <span style={{ color: "red" }}>*</span>
              </Label>
              <DropDownList
                data={durationTypeList}
                className="custom-dropdowns"
                svgIcon={chevronDownIcon}
                dataItemKey="id"
                textField="duration_type"
                value={selectedType}
                onChange={handleDropdown}
              />
              {newTypeError && <Error>{newTypeError}</Error>}
              {/* {newInclusionError && <Error>{newInclusionError}</Error>} */}
            </div>
          </div>
          <div
            className="custom-inputs-container"
            style={{
              marginTop: "20px",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            {isHourlyCheck === true && (
              <div
                className="custom-inputs-container"
                style={{
                  marginTop: "20px",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                }}
              >
                <div>
                  <Label>
                    Has variant ? <span style={{ color: "red" }}>*</span>
                  </Label>
                  <div style={{ display: "flex" }}>
                    <RadioButton
                      name="group1"
                      value={1}
                      checked={selectedValue === "1"}
                      label="Yes"
                      onChange={(e) => {
                        selectedValueSet(`${e.value}`);
                        if (e.value === "") {
                          newVarientErrorSet("Has varient is required");
                        } else {
                          newVarientErrorSet("");
                        }
                        console.log("Value changed to first");
                      }}
                      // style={{marginRight:"5%"}}
                    />
                    <div style={{ width: "20px" }}></div>
                    <RadioButton
                      name="group1"
                      value={2}
                      checked={selectedValue === "2"}
                      label="No"
                      onChange={(e) => {
                        console.log("Value changed -->", e.value);
                        selectedValueSet(`${e.value}`);
                        if (e.value === "") {
                          newVarientErrorSet("Has varient is required");
                        } else {
                          newVarientErrorSet("");
                        }
                        console.log("Value changed from first to second");
                      }}
                      // style={{marginRight:"5%"}}
                    />
                  </div>
                  {newVarientError && <Error>{newVarientError}</Error>}
                </div>
                <div style={{ width: "20px" }}></div>
                {selectedValue === "1" && (
                  <div style={{ width: "30%" }}>
                    <Label className="common-label-size">
                      Variant Type <span style={{ color: "red" }}>*</span>
                    </Label>
                    <DropDownList
                      data={varientType}
                      className="custom-dropdowns"
                      svgIcon={chevronDownIcon}
                      dataItemKey="id"
                      textField="varientName"
                      value={selectedVariantType}
                      onChange={handleVariantDropdown}
                    />
                    {newVariantTypeError && (
                      <Error>{newVariantTypeError}</Error>
                    )}
                    {/* {newInclusionError && <Error>{newInclusionError}</Error>} */}
                  </div>
                )}
              </div>
            )}

            <div
              className="custom-inputs-view common-label-padding"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "flex-end",
              }}
            >
              <Label className="common-label-size"></Label>
              <div>
                <Label className="common-label-size"></Label>
                <Button className="button-view " onClick={addDuration}>
                  Create
                </Button>
              </div>
              <div>
                <Label className="common-label-size"></Label>
                <Button className="common-button-view" onClick={clearInput}>
                  Clear
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div className="common-margin view-margin">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              marginBottom: "1%",
              marginTop: "5%",
              alignItems: "center",
            }}
          >
            <Label className="list-heading">Duration List</Label>
            <TextBox
              className="textBoxcustom"
              placeholder="Search using Duration Name"
              name="roomcost"
              value={searchTerm}
              onChange={handleSearchChange}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {searchTerm ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn
                field="Id"
                title="S.No."
                cell={(props) => <td>{props.dataIndex + 1}</td>}
              />
              <GridColumn
                field="DurationName"
                title="Duration Name"
                // cell={(props) => <td>{props.dataItem.DurationType}</td>}
              />
              <GridColumn field="DurationType" title="Type" />
              <GridColumn field="HasVarient" title="Has Variant?" />
              <GridColumn
                field="VarientName"
                title="Variant Names"
                cell={(props) => <td>{props.dataItem.VarientName}</td>}
              />
              <GridColumn
                title="Action"
                className="header-center"
                cell={(props) => (
                  <td>
                    {/* {edit === false ? ( */}
                    <Button
                      className="edit-color"
                      onClick={() => {
                        enableField(props.dataItem);
                        console.log("Selected Data", props.dataItem);
                      }}
                      svgIcon={pencilIcon}
                    />
                    {/* ) : (
                      <Button
                        className="edit-button-view"
                        onClick = {() =>
                          editDuration(props.dataItem.Id, props.dataItem.value)
                        }
                        svgIcon={checkboxCheckedIcon}
                      />
                    )} */}
                    {/* <Button
                      className="delete-color"
                      // onClick={() => setDeleteID(props.dataItem.Id)}
                      svgIcon={trashIcon}
                    /> */}
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog title="Confirm" onClose={() => setDeleteID(null)}>
                <p>Are you sure you want to delete this row?</p>
                <DialogActionsBar>
                  <Button onClick={deleteDuration}>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
