import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Checkbox,
  Input,
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import {
  chevronDownIcon,
  eyeIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { Card } from "@progress/kendo-react-layout";
import { DateInput, DatePicker } from "@progress/kendo-react-dateinputs";
import { useNavigate } from "react-router-dom";
import { Error } from "@progress/kendo-react-labels";
import { start } from "repl";
import { MyDatePicker } from "./MyDatePicker";
import { Loader } from "@progress/kendo-react-indicators";
import { commonBaseUrl } from "../../services/CommonUtil";

interface Spac {
  Id: number;
  SpaceType: string;
  SpaceDetails: DurationDetail[];
  checked: boolean;
}

interface DurationData {
  DurationId: number;
  DurationName: string;
  RentAmount: number;
  DiscountValue?: number;
  DiscountPrice?: number;
}

type DurationDetail = {
  durationId: number;
  durationName: string;
  actualPrice: number;
  discountValue: number;
  discountPrice: number;
};

type Space = {
  id: number;
  spaceName: string;
  durationDetails: DurationDetail[];
};

export const CreateDiscount = () => {
  const baseURL = commonBaseUrl();
  const navigate = useNavigate();
  const initialDataState: State = {
    take: 3,
    skip: 0,
    group: [],
  };
  //   const [newPackage, setNewPackage] = useState("");
  //   const [newInclusion, setNewInclusion] = useState(defaultInclusionItem);
  //   const [newExclusion, setNewExclusion] = useState(defaultExclusionItem);
  const [editID, setEditID] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [alertState, alertStateSet] = useState(false);

  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = spaceList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Discount",
      route: "/masters/discount",
    },
    {
      id: "3",
      text: "Create new discount",
      route: "",
    },
  ];

  //mani sir code
  const [spaces, setSpaces] = useState<Space[]>();

  const [selectedSpaces, setSelectedSpaces] = useState<string[]>([]);
  //   const [durationSpaceList, setDurationSpaceList] = useState<DurationData[]>(
  //     []
  //   );
  const [discountTypeList, discountTypeListSet] = useState<any>();

  const [spaceList, spaceListSet] = useState<Spac[]>([]);
  const [firsttime, setFirstTime] = useState(true);
  const [selectedDiscount, selectedDiscountSet] = useState();
  //   const [durationSpaceList, durationSpaceListSet] = useState<any>([]);
  const [allDiscountData, setAllDiscountData] = useState<any>();
  const storeDiscountData: any[] = [];

  const reset = () => {
    console.log("Data in the list before selecting ==>", allDiscountData);
    if (allDiscountData !== undefined) {
      if (allDiscountData.length !== 0) {
        setAllDiscountData((prevSpaces: any) =>
          prevSpaces.map((space: Space) => {
            return {
              ...space,
              durationDetails: space.durationDetails.map(
                (detail: DurationDetail) => {
                  return {
                    ...detail,
                    discountValue: 0,
                    discountPrice: 0,
                  };
                  // return detail;
                }
              ),
            };
          })
        );
      }
    }
  };

  useEffect(() => {
    const SpacesApi = async () => {
      try {
        // https://dev-cw-api.equadriga.in/odata/SpaceHeaders?$filter=IsActive eq true&$expand=SpaceDetails($filter=IsActive eq true;$expand=Duration($select=DurationType))
        const response = await axios.get(
          `${baseURL}odata/SpaceHeaders?$filter=IsActive eq true&$expand=SpaceDetails($filter=IsActive eq true;$expand=Duration($select=DurationType))`
        );
        const ss = response.data.value;
        console.log("Response -->", ss);
        const dats = ss.map((dataItem: any, index: number) => {
          console.log("Space Index -->", index);
          return {
            Index: index,
            Id: dataItem.Id,
            checked: false,
            SpaceType: dataItem.SpaceName,
            SpaceDetails: dataItem.SpaceDetails,
          };
        });
        spaceListSet(dats);
        // console.log(response.data.value, "Api value");
        // console.log(dats, "Value");
      } catch (err) {}
    };
    const discountTypeApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/GetAllDiscountType`);
        discountTypeListSet(response.data);
        // console.log("Discount type api response", response.data);
      } catch (err) {}
    };
    if (firsttime === true) {
      //   console.log("Called");
      SpacesApi();
      discountTypeApi();
      setFirstTime(false);
    }
  });
  const usageRef = useRef<any>(null);

  useEffect(() => {
    const inputElement = usageRef.current?.element?.querySelector("input");
    if (inputElement) {
      // Disable arrow keys
      inputElement.addEventListener("keydown", (event: KeyboardEvent) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      });

      // Disable mouse wheel
      inputElement.addEventListener("wheel", (event: WheelEvent) => {
        event.preventDefault();
      });

      // Clean up event listeners
      return () => {
        inputElement.removeEventListener("keydown", () => {});
        inputElement.removeEventListener("wheel", () => {});
      };
    }
  }, [usageRef]);

  //   useEffect(() => {
  //     console.log(durationSpaceList.spaceName, "Value changes");
  //   }, [durationSpaceList]);

  const [allDurationData, setAllDurationData] = useState<{
    [key: string]: DurationData[];
  }>({});
  const datas: { [key: string]: DurationData[] } = {};

  const durationSpaceApi = async (data: any, selectedSpaceType: any) => {
    // var list;
    // try {
    //   const response = await axios.get(
    //     `${baseURL}odata/SpaceDetails?$filter=SpaceHeaderId eq ${spaceId}`
    //   );
    //   const durationList = data;
    //   const transformedData = transformResponse(
    //     durationList,
    //     selectedSpaceType
    //   );
    //   if (allDiscountData !== undefined) {
    //     for (let d = 0; d < allDiscountData.length; d++) {
    //       storeDiscountData.push(allDiscountData[d]);
    //     }
    //   }

    //   storeDiscountData.push(transformedData);
    //   setDurationSpaceList(transformedData);
    //   setAllDiscountData(storeDiscountData);
    //   console.log("After Store", storeDiscountData);
    // } catch (err) {}
    // return list;
    const durationList = data;
    console.log("the upcoming data was->", data);
    const transformedData = transformResponse(durationList, selectedSpaceType);
    if (allDiscountData !== undefined) {
      console.log("calling", storeDiscountData);
      for (let d = 0; d < allDiscountData.length; d++) {
        storeDiscountData.push(allDiscountData[d]);
      }
    }
    console.log("Before storing Data -->", storeDiscountData);
    console.log("Transformed Data -->", transformedData);
    storeDiscountData.push(transformedData);
    // setDurationSpaceList(transformedData);
    setAllDiscountData(storeDiscountData);
    console.log("After Store", storeDiscountData);
  };
  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}
 
.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}
 
.no-wheel input::-moz-inner-spin-button {
  display: none;
}`;
  const transformResponse = (response: any, selectedSpaceType: string) => {
    return {
      spaceName: selectedSpaceType,
      durationDetails: response.map((detail: any) => ({
        applicableSpacesId: detail.SpaceHeaderId,
        durationId: detail.DurationId,
        durationName: detail.Duration.DurationType,
        actualPrice: detail.RentAmount,
        discountValue: 0, // Default value
        discountPrice: 0, // Default value
      })),
    };
  };

  //   const setDurationList = (list: any) => {
  //     durationSpaceListSet;
  //   };

  const [edit, setEdit] = useState(false);
  const [search, searchSet] = useState("");
  const [discountList, discountListSet] = useState([
    "Fixed off",
    "Percentage off",
  ]);
  const [selectedValue, setSelectedValue] = useState("1");

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const [checked, checkedSet] = useState(false);
  const checkclicked = (item: any, index: any, checked: boolean) => {
    // console.log(index, item, "<");
    // console.log("State -->", checked);
    const field = item.Id;
    const checkupdate = spaceList.map((item: any, idx: any) => {
      //   console.log(idx, index, "<s");
      if (idx === index) {
        // console.log(idx, index, "<st");
        // console.log("Check when change", checked);
        return { ...item, ["checked"]: !checked };
      }
      return item;
    });
    spaceListSet(checkupdate);
    // console.log("Space List", checkupdate);
    // checkedSet(!checked);
    if (!checked) {
      //   durationSpaceApi(item.Id);
    }
  };
  const [selectedSpaceType, selectedSpaceTypeSet] = useState("");
  // Handle checkbox click
  const handleCheckboxChange = (item: any, index: any) => {
    console.log("Space Details->", item.SpaceDetails);
    selectedSpaceTypeSet("");
    const updatedList = [...spaceList];
    console.log("Updated space list is ->", updatedList);
    updatedList[index].checked = !updatedList[index].checked;
    spaceListSet(updatedList);
    const selectedSpaceTypes = updatedList[index].SpaceType;
    selectedSpaceTypeSet(selectedSpaceTypes);
    // Toggle selected spaces and update duration data
    if (updatedList[index].checked) {
      setAllDiscountData(updatedList[index].SpaceDetails);
      console.log("The selected spaces -->", selectedSpaces);
      console.log("The selected spacetypes -->", selectedSpaceTypes);
      console.log("The alldisount data -->", allDiscountData);
      setSelectedSpaces([...selectedSpaces, selectedSpaceTypes]);
      durationSpaceApi(item.SpaceDetails, item.SpaceType);
      console.log("Selected -->", selectedSpaces.length);
    } else {
      console.log("space", selectedSpaces, allDiscountData);
      setSelectedSpaces(
        selectedSpaces.filter((space) => space !== selectedSpaceTypes)
      );
      setAllDiscountData(
        allDiscountData.filter(
          (space: any) => space.spaceName !== selectedSpaceTypes
        )
      );
      console.log("Selected -->", selectedSpaces.length);
      // selectedSpaceType = ""
      // console.log("Space removed -->",selectedSpaceType);
      // setDurationSpaceList(undefined);
    }
  };

  //Handle mani code
  const handleDiscountChange = (
    spaceId: string,
    durationId: number,
    field: keyof DurationDetail,
    value: number
  ) => {
    setAllDiscountData((prevSpaces: any) =>
      prevSpaces.map((space: Space) => {
        if (space.spaceName === spaceId) {
          return {
            ...space,
            durationDetails: space.durationDetails.map(
              (detail: DurationDetail) => {
                if (detail.durationId === durationId) {
                  const updatedDetail = detail;
                  updatedDetail.discountValue = value;
                  if (selectType.id === 2) {
                    const discountAmount =
                      (updatedDetail.discountValue *
                        updatedDetail.actualPrice) /
                      100;
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - discountAmount >= 0
                        ? updatedDetail.actualPrice - discountAmount
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  } else {
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - updatedDetail.discountValue >=
                      0
                        ? updatedDetail.actualPrice -
                          updatedDetail.discountValue
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  }
                  // }
                  return updatedDetail;
                }
                return detail;
              }
            ),
          };
        }
        return space;
      })
    );
  };

  // Handle discount input change
  const handleDiscountChanges = (e: any, index: any, indexes: any) => {
    const updatedList = allDiscountData;
    console.log(
      "Data came from -->",
      updatedList[indexes].durationDetails[index]
    );
    var data = updatedList[indexes].durationDetails[index];
    data.discountValue = parseInt(e.target.value);
    data.discountPrice =
      parseInt(data.actualPrice) - parseInt(data.discountValue) >= 0
        ? parseInt(data.actualPrice) - parseInt(data.discountValue)
        : 0;
    // updatedList[indexes].durationDetails[index].discountValue = e.target.value;
    // updatedList[indexes].durationDetails[index].discountPrice = e.target.value;
    console.log("Value come from", data);
    console.log(
      "Before updation-->",
      updatedList[indexes].durationDetails[index]
    );
    updatedList[indexes].durationDetails[index] = data;
    console.log(
      "After updation-->",
      updatedList[indexes].durationDetails[index]
    );
    console.log("Updated Whole data-->", updatedList);
    setAllDiscountData(updatedList);
    // updatedList[index].DiscountPrice =
    //   updatedList[index].RentAmount - e.target.value;
    // setDurationSpaceList(updatedList);
  };

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const [startDate, startDateSet] = useState();
  const [endDate, endDateSet] = useState();
  const [selectedUsage, selectedUsageSet] = useState("");
  const convertToISOFormat = (inputDate: any): string => {
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);

    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );

    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;

    return isoFormattedDate;
  };
  const addDiscount = () => {
    let Validate = true;
    checkSet(true);
    console.log("Discount data ==>", allDiscountData);
    console.log(
      "Start date and End date",
      startDate,
      endDate,
      startDate === undefined
    );
    if (discountName.trim() === "") {
      nameErrorSet("Discount name must be provided");
      Validate = false;
    } else {
      nameErrorSet("");
    }
    if (discountDescription.trim() === "") {
      descriptionErrorSet("Description must be provided");
      Validate = false;
    } else {
      descriptionErrorSet("");
    }
    if (selectType.id === null) {
      typeErrorSet("Select the type");
      Validate = false;
    } else {
      typeErrorSet("");
    }
    if (startDate === undefined) {
      console.log("happen");
      startErrorSet("Choose the Start Date");
      Validate = false;
    } else {
      startErrorSet("");
    }
    if (endDate === undefined) {
      endErrorSet("Choose the End Date");
      Validate = false;
    } else {
      endErrorSet("");
    }
    if (selectedUsage === "") {
      limitErrorSet("Enter the Usage limit");
      Validate = false;
    } else {
      limitErrorSet("");
    }
    if (parseInt(selectedUsage) <= 0) {
      limitErrorSet("Enter the Usage limit");
      Validate = false;
    } else {
      limitErrorSet("");
    }
    if (selectedSpaces.length === 0) {
      spaceErrorSet("Select the Spaces");
      Validate = false;
      console.log(
        "Error handling",
        selectedSpaceType,
        selectedSpaceType === ""
      );
    } else {
      spaceErrorSet("");
    }
    allDiscountData.forEach((item: any) => {
      item.durationDetails.forEach((durationItem: any) => {
        console.log(durationItem.discountValue, "dd<");
        if (durationItem.discountValue <= 0) {
          Validate = false;
        }
        if (durationItem.discountPrice <= 0) {
          Validate = false;
        }
      });
    });

    if (
      discountName.trim() !== "" &&
      discountDescription.trim() !== "" &&
      selectType.id !== null &&
      startDate !== undefined &&
      endDate !== undefined &&
      selectedUsage !== "" &&
      selectedSpaceType !== "" &&
      parseInt(selectedUsage) > 0
    ) {
      if (Validate === true) {
        addDiscountApi();
      }
    }
  };

  const addDiscountApi = async () => {
    setLoading(true);
    const createDiscount = {
      discountName: discountName,
      description: discountDescription,
      discountType: selectType.id,
      startDate: convertToISOFormat(startDate),
      endDate: convertToISOFormat(endDate),
      eligibleFor: parseInt(selectedValue),
      usageLimit: selectedUsage,
      discountDetails: allDiscountData,
    };

    console.log("Create Discount set", allDiscountData);
    console.log("The json structure is -->", createDiscount);
    try {
      const response = await axios.post(
        `${baseURL}odata/DiscountHeaders`,
        createDiscount,
        {
          headers: header,
        }
      );
      console.log("Response -->", response.data);
      alertStateSet(false);
      statusDialogSet(true);
      alertSet("Discount created successfully");
      setLoading(false);
    } catch (err: any) {
      // if (err.response.status === 500) {
      console.log(err);
      alertStateSet(true);
      setLoading(false);
      statusDialogSet(true);
      alertSet(err.response.data.detail);
      // }
    }
  };

  const navigateBack = () => {
    if (alertState === false) {
      navigate("/masters/discount");
    }
    statusDialogSet(false);
  };

  {
    /* Sri code */
  }
  const [discountName, discountNameSet] = useState("");
  const [discountDescription, discountDescriptionSet] = useState("");
  // const [selectedType, selectedTypeSet ] = useState("");
  const defaultType = { id: null, name: "Choose the discount type" };
  const [selectType, selectedTypeSet] = useState(defaultType);
  const handleDropdown = (e: any) => {
    console.log("The selected Dropdown value", e);
    selectedTypeSet(e);
    reset();
  };
  //Sri Code ^ below
  useEffect(() => {
    console.log("selected type is =>", selectType.id);
  }, [selectType]);

  const [nameError, nameErrorSet] = useState("");
  const [descriptionError, descriptionErrorSet] = useState("");
  const [typeError, typeErrorSet] = useState("");
  const [startError, startErrorSet] = useState("");
  const [endError, endErrorSet] = useState("");
  const [limitError, limitErrorSet] = useState("");
  const [spaceError, spaceErrorSet] = useState("");
  const [check, checkSet] = useState(false);

  useEffect(() => {
    if (check) {
      if (discountName.trim() === "") {
        nameErrorSet("Discount name must be provided");
      } else {
        nameErrorSet("");
      }
      if (discountDescription.trim() === "") {
        descriptionErrorSet("Description must be provided");
      } else {
        descriptionErrorSet("");
      }
      if (selectType.id === null) {
        typeErrorSet("Select the type");
      } else {
        typeErrorSet("");
      }
      if (startDate === undefined) {
        startErrorSet("Choose the Start Date");
      } else {
        startErrorSet("");
      }
      if (endDate === undefined) {
        endErrorSet("Choose the End Date");
      } else {
        endErrorSet("");
      }
      if (selectedUsage === "") {
        limitErrorSet("Enter the Usage limit");
      } else {
        limitErrorSet("");
      }
      if (selectedSpaces.length === 0) {
        spaceErrorSet("Select the Spaces");
        console.log(
          "Error handling",
          selectedSpaceType,
          selectedSpaceType === ""
        );
      } else {
        spaceErrorSet("");
      }
    }
  }, [
    check,
    discountName,
    discountDescription,
    selectType,
    startDate,
    endDate,
    selectedUsage,
    selectedSpaces,
  ]);

  // Restrict scroll & keyboart action in date picker
  // CSS styling for the components
  const customStyles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;
  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };

    if (element) {
      // Attach wheel event listener
      element.addEventListener("wheel", handleWheel, { passive: false });

      // Clean up event listener
      return () => {
        element.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const setTimeCalc = (value: any, name: string) => {
    const curDate = new Date();
    console.log(convertToISOFormat(value), "<<<drs<");
    if (name === "end") {
      endDateSet(value);
    } else {
      startDateSet(value);
      if (value > endDate! && endDate !== undefined) {
        endDateSet(value);
      }
    }
  };

  const [loading, setLoading] = useState(false); // State for loading

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      console.log("inputElement is: ", inputElement); // Log to check the reference type

      const handleWheel = (event: WheelEvent) => {
        event.preventDefault(); // Prevents mouse wheel scrolling
      };

      // Ensure inputElement is a valid HTMLInputElement before adding the event listener
      if (inputElement instanceof HTMLInputElement) {
        inputElement.addEventListener("wheel", handleWheel);
      }

      // Clean up event listener on component unmount
      return () => {
        if (inputElement instanceof HTMLInputElement) {
          inputElement.removeEventListener("wheel", handleWheel);
        }
      };
    }
  }, []);

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevents arrow key actions
    }
    if (event.key === "." || event.key === "," || event.key === "-") {
      event.preventDefault(); // Prevents decimal input
    }
  };

  return (
    <div>
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div className="overall-view">
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
            <hr className="hr-border" />
          </div>
          <div className="view-margin">
            <div className="common-margin view-margin">
              <div className="custom-input-containers">
                <div className="custom-input-views">
                  <Label className="common-label-size">
                    Discount Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    className="book-height"
                    type="text"
                    maxLength={25}
                    value={discountName}
                    onChange={(e) => discountNameSet(e.value)}
                    placeholder="Enter the discount name"
                  />
                  {nameError && <Error>{nameError}</Error>}
                </div>
                <div style={{ width: "40px" }}> </div>
                <div className="custom-input-views" style={{ width: "80%" }}>
                  <Label className="common-label-size">
                    Description <span style={{ color: "red" }}>*</span>
                  </Label>
                  <TextArea
                    maxLength={500}
                    value={discountDescription}
                    onChange={(e) => discountDescriptionSet(e.value)}
                    placeholder="Enter Description"
                  />
                  {descriptionError && <Error>{descriptionError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div className="custom-input-containers">
                <div className="custom-input-views">
                  <Label>
                    Discount Type <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="book-dropdown-new"
                    data={discountTypeList}
                    textField="name"
                    dataItemKey="id"
                    onChange={(e) => handleDropdown(e.target.value)}
                    value={selectType}
                  />
                  {typeError && <Error>{typeError}</Error>}
                </div>

                <div className="custom-input-views">
                  <Label>
                    Start Date <span style={{ color: "red" }}>*</span>
                  </Label>
                  <style>{customStyles}</style>
                  <div
                    className="no-wheel"
                    ref={timePickerRef}
                    style={{ width: "80%" }}
                  >
                    <MyDatePicker
                      minDate={new Date()}
                      checkInDate={startDate!}
                      isDisableCheck={false}
                      customFormatter={false}
                      setCheckInDate={(e: any) => {
                        // setCheckInDate(e);
                        setTimeCalc(e, "start");
                      }}
                    />
                  </div>
                  {startError && <Error>{startError}</Error>}
                </div>
                <style>{styles}</style>
                <div className="custom-input-views">
                  <Label>
                    End Date <span style={{ color: "red" }}>*</span>
                  </Label>
                  <style>{customStyles}</style>
                  <div
                    className="no-wheel"
                    ref={timePickerRef}
                    style={{ width: "80%" }}
                  >
                    <MyDatePicker
                      minDate={startDate !== undefined ? startDate : new Date()}
                      checkInDate={endDate!}
                      isDisableCheck={false}
                      customFormatter={false}
                      setCheckInDate={(e: any) => {
                        // setCheckInDate(e);
                        setTimeCalc(e, "end");
                      }}
                    />
                  </div>
                  {endError && <Error>{endError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  // alignItems: "center",
                  width: "50%",
                }}
              >
                <div>
                  <div>
                    Eligible For <span style={{ color: "red" }}>*</span>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-center",
                      // alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <RadioButton
                      name="group1"
                      value="1"
                      checked={selectedValue === "1"}
                      label="All Users"
                      onChange={() => setSelectedValue("1")}
                      // style={{marginRight:"5%"}}
                    />
                    <RadioButton
                      name="group1"
                      value="2"
                      checked={selectedValue === "2"}
                      label="New users"
                      onChange={() => setSelectedValue("2")}
                      // style={{marginRight:"5%"}}
                    />
                    <RadioButton
                      name="group1"
                      value="3"
                      checked={selectedValue === "3"}
                      label="Existing users"
                      onChange={() => setSelectedValue("3")}
                      // style={{marg`inRight:"5%"}}
                    />
                  </div>
                </div>
                <div style={{ width: "100px", marginLeft: "10%" }}>
                  <Label>
                    Usage Limit <span style={{ color: "red" }}>*</span>
                  </Label>
                  <style>{customStyles}</style>
                  <Input
                    type="number"
                    className="book-dropdown-news no-wheel"
                    value={selectedUsage}
                    min={0}
                    ref={inputRef}
                    // onWheel={handleWheel}
                    onKeyDown={handleKeyDown}
                    maxLength={5}
                    // max={1000}
                    onChange={(e) => {
                      console.log("Event ->", e.nativeEvent.inputType);
                      // console.log("Event Type ->", e.nativeEvent.type);
                      if (e.nativeEvent.inputType !== undefined) {
                        if (e.value.length <= 4) {
                          selectedUsageSet(e.value);
                        }
                        if (parseInt(e.value) <= 0) {
                          limitErrorSet("Enter the Usage Limit");
                        }
                      }
                    }}
                    placeholder="Enter limit"
                  />
                  {check && limitError && <Error>{limitError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              {spaceList.length !== 0 && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontWeight: "bold",
                  }}
                >
                  Select Applicable Spaces
                </div>
              )}
              <div style={{ display: "flex", justifyContent: "space-evenly" }}>
                {spaceList.map((item: any, index: any) => (
                  <Checkbox
                    style={{ borderColor: "black", borderStyle: "solid" }}
                    key={item.Index}
                    value={item.checked}
                    label={item.SpaceType}
                    onChange={() => handleCheckboxChange(item, item.Index)}
                  />
                ))}
              </div>
              {spaceList.length === 0 && (
                <div style={{ justifyContent: "center", display: "flex" }}>
                  {" "}
                  No Spaces Available for this discount
                </div>
              )}
              {spaceError && <Error>{spaceError}</Error>}
              {allDiscountData !== undefined &&
                allDiscountData.map((item: any, indexes: any) => (
                  <div
                    style={{
                      fontWeight: "bold",
                      border: "2px solid black",
                      padding: "10px",
                    }}
                  >
                    {item.spaceName}
                    <div>
                      <table cellPadding="10">
                        <thead>
                          <tr>
                            <th>Duration</th>
                            <th>Actual Price(₹)</th>
                            <th>
                              {" "}
                              {selectType.id === 2
                                ? " Discount Percentage(%)"
                                : "Discount Value(₹)"}{" "}
                              <span style={{ color: "red" }}>*</span>
                            </th>
                            <th>Discount Price(₹)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {item.durationDetails.map(
                            (durationItem: any, index: any) => (
                              <tr key={index}>
                                <td width={"250px"}>
                                  {durationItem.durationName}
                                </td>
                                {/* <td>{durationItem.actualPrice}</td> */}
                                <td>
                                  <Input
                                    style={{ color: "grey", textAlign: "end" }}
                                    type="number"
                                    value={durationItem.actualPrice}
                                    readOnly={true}
                                  />
                                </td>
                                <td>
                                  <Input
                                    style={{ textAlign: "end" }}
                                    type="number"
                                    value={durationItem.discountValue}
                                    valid={
                                      durationItem.discountValue > 0 ||
                                      durationItem.discountPrice <= 0
                                    }
                                    min={0}
                                    onChange={(e) => {
                                      if (
                                        e.nativeEvent.inputType !== undefined
                                      ) {
                                        handleDiscountChange(
                                          item.spaceName,
                                          durationItem.durationId,
                                          durationItem,
                                          parseInt(e.value) || 0
                                        );
                                      }
                                    }}
                                  />
                                  {check && durationItem.discountValue <= 0 && (
                                    <Error>Discount value not be 0</Error>
                                  )}
                                  {check && durationItem.discountPrice <= 0 && (
                                    <Error>
                                      Discount{" "}
                                      {selectType.id === 2
                                        ? "percentage"
                                        : "value"}{" "}
                                      cannot be greater than actual price
                                    </Error>
                                  )}
                                </td>
                                <td>
                                  <Input
                                    style={{ color: "grey", textAlign: "end" }}
                                    type="number"
                                    value={durationItem.discountPrice}
                                    readOnly={true}
                                  />
                                </td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                ))}
              <div
                className="center-view"
                style={{
                  paddingBottom: "50px",
                }}
              >
                <div className="form-row-area">
                  <div className="form-group-area"></div>
                </div>
                <div className="button-group">
                  {selectedSpaces.length !== 0 && (
                    <Button className="button-view" onClick={addDiscount}>
                      CREATE
                    </Button>
                  )}
                  <Button
                    // type="submit"
                    className="common-button-view"
                    onClick={() => navigate("/masters/discount")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
              {/* {durationSpaceList.length !== 0 && (
            <div
              style={{
                fontWeight: "bold",
                border: "2px",
                borderColor: "black",
              }}
            >
              {durationSpaceList[0].SpaceName === undefined
                ? ""
                : durationSpaceList[0].SpaceName}
              <div>
                <table cellPadding="10">
                  <thead>
                    <tr>
                      <th>Duration</th>
                      <th>Actual Price(₹)</th>
                      <th>Discount Value</th>
                      <th>Discount Price (₹)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {durationSpaceList.map((item: any, index: any) => (
                      <tr key={index}>
                        <td>{item.DurationName}</td>
                        <td>{item.RentAmount}</td>
                        <td>
                          <Input
                            value={item.DiscountValue}
                            // onChange={(e)=>handleChanges(index,"discount",e.target.value)}
                          />
                        </td>
                        <td>{item.RentAmount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )} */}
              {statusDialog && (
                <Dialog>
                  <div
                    style={{
                      justifyContent: "center",
                      alignItems: "center",
                      display: "flex",
                      flexDirection: "column",
                      alignContent: "center",
                    }}
                  >
                    <h3>{alert}</h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        paddingTop: "30px",
                      }}
                    >
                      <Button
                        className="button-view"
                        onClick={() => navigateBack()}
                      >
                        Ok
                      </Button>
                    </div>
                  </div>
                </Dialog>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
