import React, { useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Checkbox, Input, TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import { eyeIcon, pencilIcon, trashIcon } from "@progress/kendo-svg-icons";
import { Card } from "@progress/kendo-react-layout";
import { useNavigate } from "react-router-dom";
import { commonBaseUrl, commonImageUrl } from "../../services/CommonUtil";

const SubCategory = () => {
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();

  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const initialDataState: State = {
    take: 5,
    skip: 0,
    group: [],
  };
  const [deleteID, setDeleteID] = useState(null);
  const [dataActiveState, setActiveDataState] =
    useState<State>(initialDataState);
  const [dataExpiredState, setExpiredDataState] =
    useState<State>(initialDataState);

  const handleActivePageChange = (pageState: any) => {
    setActiveDataState({ ...dataActiveState, ...pageState });
  };

  const handleExpiredPageChange = (pageState: any) => {
    setExpiredDataState({ ...dataExpiredState, ...pageState });
  };

  const [activeDiscountList, setActiveDiscountList] = useState<any[]>([]);
  const [expiredDiscountList, setExpiredDiscountList] = useState<any[]>([]);
  const getActivePageableSettings = () => {
    const length = activeDiscountList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const getExpiredPageableSettings = () => {
    const length = expiredDiscountList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Cafeteria Management",
      route: "/cafeteria/overview",
    },
    {
      id: "2",
      text: "Sub-Categories",
      //   route: "",
    },
  ];
  const [categoryList, categoryListSet] = useState<any>([]);
  const CategoryApi = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/Categorys`);
      console.log("Category Response", response.data.value);
      categoryListSet(response.data.value);
      SubCategoryApi(response.data.value);
    } catch (err) { }
  };
  const [discountTypeList, discountTypeListSet] = useState<any>();
  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    CategoryApi();
    // SubCategoryApi();
    // DiscountTypeApi();
    // DiscountSummaryApi();
  });
  const [upcoming, upcomingSet] = useState("");
  const [active, activeSet] = useState("");
  const [expired, expiredSet] = useState("");
  const [soon, soonSet] = useState("");

  const SubCategoryApi = async (catList:any) => {
        try {
          const response = await axios.get(`${baseURL}odata/SubCategorys`);
          const subCategoryList: any[] = [];
          const expiredDiscounts: any[] = [];
          console.log("Discount headeers-->", response.data.value);
          //   console.log("Discount headers-->", response.data);
          for (let d = 0; d < response.data.value.length; d++) {
            const discount = response.data.value[d];
            console.log("Discount headeers-->", response.data.value[d]);
            console.log("Condition -->", discount.isActive);
            console.log("Condition -->", discount.isExpired);
            if (discount.isExpired) {
              expiredDiscounts.push(discount);
            } else {
              subCategoryList.push(discount);
            }
          }
          // console.log("Sub-Category Discount -->", subCategoryList);
          for (let ad = 0; ad < subCategoryList.length; ad++) {
            // console.log("Data Doc 1==>",subCategoryList[ad].Id);
            const response = await axios.get(
              `${baseURL}odata/DocumentUploads?$filter=refno eq ${subCategoryList[ad].Id} and contains(reftype, 'Food')`
            );
            subCategoryList[ad].sno = ad + 1;
            subCategoryList[ad].check = false;
            // subCategoryList[ad].Image = response.data.value[response.data.value.length - 1].ImagePath;
            // console.log("Data Doc 2==>",response.data.value);
            // console.log("Data Doc Category list", catList);
            for (let i = 0; i < catList.length; i++) {
              console.log("Data Doc before for", subCategoryList[ad].CategoryId, catList[i].Id);
              if (subCategoryList[ad].CategoryId === catList[i].Id) {
                const imageList = response.data.value;
                console.log("Data Doc after for", imageList);
                if (imageList.length > 0) {
                  subCategoryList[ad].image = imageList[imageList.length - 1].ImagePath;
                  console.log(i, "for image ==> ",subCategoryList[ad].image);
                  subCategoryList[ad].Category = catList[i].Name;
                }
              }
            }
          }
          console.log("Data Doc 3 Active Discount after loop-->", subCategoryList, subCategoryList.length);
          setActiveDiscountList(subCategoryList);
        } catch (err) { 
          console.log("Data Doc  called", err);
        }
      };
  // useEffect(() => {
  //   SubCategoryApi();
  // }, []);

  function formatDate(isoDateString: any) {
    const date = new Date(isoDateString);
    const day = String(date.getDate()).padStart(2, "0"); // Add leading zero if needed
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }
  const [edit, setEdit] = useState(false);
  const handleEdit = (item: any) => {
    console.log("Data came from --> ", item);
    navigate("/cafeteria/subcategories/update", { state: { ItemInfo: item } });
  };

  const [filteredData, setFilteredData] = useState(activeDiscountList);
  const [search, setSearch] = useState("");
  useEffect(() => {
    if (search) {
      const lowerCaseSearch = search.toLowerCase();
      setFilteredData(
        activeDiscountList.filter((item) =>
          Object.values(item).some(
            (value) =>
              value !== null &&
              value !== undefined &&
              value.toString().toLowerCase().includes(lowerCaseSearch)
          )
        )
      );
    } else {
      setFilteredData(activeDiscountList);
    }
  }, [search, activeDiscountList]);
  const handleSearchChange = (e: any) => {
    setActiveDataState(initialDataState);
    setSearch(e.target.value);
  };
  const handleActivePageChanges = (e: any) => {
    setActiveDataState(e.dataState);
  };
  // Reset filter values
  const resetSearch = () => {
    setSearch("");
    setFilteredData(activeDiscountList); // Reset to original data
  };

  const handleCheckboxChange = (index: number, tableRow: any) => {
    console.log("Data ==>", tableRow);
    console.log("Index ==>", index);
    console.log("The given set ==>", filteredData);
    titleCheckSet(false);
    checkdataSet(false);
    setFilteredData((prevData) =>
      prevData.map((row, idx) => {
        return idx === index
          ? {
            ...row,
            check: !row.check,
          }
          : row;
      })
    );
  };
  const [titleCheck, titleCheckSet] = useState(false);
  const [checkdata, checkdataSet] = useState(false);

  const CustomHeaderCell = (props: any) => {
    const handleClick = () => {
      titleCheckSet(!titleCheck);
      checkdataSet(!checkdata);
      console.log("Check Value is -->", checkdata);
      console.log("Title clicked!", dataActiveState);
      setFilteredData((prevData) =>
        prevData.map((row, idx) => {
          return {
            ...row,
            check: titleCheck ? false : true,
          };
        })

      );
    };
    return (
      <div style={{display:"flex"}}>
      <Checkbox 
      style={{ borderColor: "black", borderStyle: "solid", cursor:"pointer" }}
      checked = {checkdata}
      onClick={handleClick}
      />

      {/* <a onClick={handleClick} style={{cursor: "pointer", marginLeft:"10px"}}>
        {props.title}
      </a> */}
      </div>
    );
  };
  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };
  const SubCategoryStatusApi = async (status: boolean) => {
    const ids: any[] = [];

    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i].check === true) {
        ids.push(filteredData[i].Id);
      }
    }
    console.log("The collected ids were =>", ids);
    if (ids.length !== 0) {
      try {
        // console.log("send data>>", formSubmit);
        const response = await axios.post(`${baseURL}odata/UpdateSubCategoryIsAvailability?IsAvailable=${status}`, ids,
          {
            headers: header,
          });
        console.log("SubCategory status", response.data);
        window.location.reload();
      }
      catch (err) { }
    }
  };
  return (
    <div className="overall-view ">
      <div className="overall-view">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // paddingRight: "10px"
          }}
        >
          <Breadcrumbs breadCrumbData={breadCrumbsData} />
          <Button
            onClick={() => navigate("/cafeteria/subcategories/details")}
            // onClick={() => navigate("/masters/create")}
            className="edit-colors"
          >
            + CREATE NEW
          </Button>
        </div>
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin view-margin">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              alignItems: "flex-end",
            }}
          >
            <Label className="list-heading">List of Sub-Categories</Label>
            <Button
            onClick={() => SubCategoryStatusApi(true)}
            className="edit-colors"
          >
            Available
          </Button>
          <Button
                      className="delete-color"
                      style={{
                        padding: "10px",
                        fontWeight:"bold"
                      }}
                      onClick={() => SubCategoryStatusApi(false)}>
                        Unavailable
                      </Button>
            <TextBox
              className="textBoxcustom common-search-box-height"
              placeholder="Search using sub-category name"
              name="activeDiscount"
              value={search}
              onChange={handleSearchChange}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {search ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
          <div>
            <Grid
              data={process(filteredData, dataActiveState)}
              pageable={getActivePageableSettings()}
              skip={dataActiveState.skip}
              take={dataActiveState.take}
              total={filteredData.length}
              onPageChange={(e) => setActiveDataState(e.page)}
              onDataStateChange={handleActivePageChanges}
            >
              <GridColumn
                title={!titleCheck ? "Select all" : "Unselect all"}
                width="50px"
                minResizableWidth = {10}
                headerCell={CustomHeaderCell}
                cell={(props) => (
                  <td>
                    <Checkbox
                    style={{ borderColor: "black", borderStyle: "solid" }}
                      checked={props.dataItem.check}
                      onChange={() =>
                        handleCheckboxChange(props.dataIndex, props.dataItem)
                      }
                    />
                  </td>
                )}
              />
              {/* <GridColumn
                field="sno"
                title="S.No."
                // width={100}
                cell={(props) => <td>{props.dataIndex + 1}</td>}
              /> */}
              <GridColumn
                field="image"
                title="Image"
                // width={100}
                cell={(props) => (
                  <td>
                    <img src={`${imageURL}${props.dataItem.image}`} height={"50px"} width={"80px"} />
                    {/* Image should be display here */}

                  </td>
                )}
              />
              <GridColumn
                field="Name"
                title="Sub-Category Name"
                cell={(props) => <td>{props.dataItem.Name}</td>}
              />
              <GridColumn
                field="Category"
                title="Category Type"
              // cell={(props) => <td>{props.dataItem.Inclusion}</td>}
              />

              <GridColumn
                field="IsActive"
                title="Status"
                cell={(props) => (
                  <td>
                    {props.dataItem.IsAvailable === true ? "Available" : "Unavailable"}
                  </td>
                )}
              />
              <GridColumn field="Price" title="Price" />
              <GridColumn
                field="qty"
                title="Quantity"
                cell={(props) => (
                  <td>
                    {props.dataItem.Quantity !== null &&
                      props.dataItem.FixedQuantity !== null
                      ? `${props.dataItem.Quantity} / ${props.dataItem.FixedQuantity}`
                      : "-"}
                  </td>
                )}
              />
              <GridColumn
                title="Action"
                className="header-center"
                cell={(props) => (
                  <td>
                    <Button
                      className="edit-color"
                      onClick={() => handleEdit(props.dataItem)}
                      svgIcon={pencilIcon}
                    />
                    {/* <Button
                      className="delete-color"
                      //    onClick={() => setDeleteID(props.dataItem.Id)}
                      svgIcon={trashIcon}
                    /> */}
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog title="Confirm" onClose={() => setDeleteID(null)}>
                <p>Are you sure you want to delete this row?</p>
                <DialogActionsBar>
                  <Button>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubCategory;
