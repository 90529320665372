import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridRowProps,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Checkbox,
  Input,
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import {
  process,
  State,
  toDataSourceRequest,
} from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { useLocation, useNavigate } from "react-router-dom";
import { Error } from "@progress/kendo-react-labels";
import { Loader } from "@progress/kendo-react-indicators";
import { commonBaseUrl, commonImageUrl } from "../../services/CommonUtil";
interface Spac {
  Id: number;
  SpaceType: string;
  SpaceDetails: DurationDetail[];
  checked: boolean;
}

interface DurationData {
  DurationId: number;
  DurationName: string;
  RentAmount: number;
  DiscountValue?: number;
  DiscountPrice?: number;
}

type DurationDetail = {
  durationId: number;
  durationName: string;
  actualPrice: number;
  discountValue: number;
  discountPrice: number;
};

type Space = {
  id: number;
  spaceName: string;
  durationDetails: DurationDetail[];
};

const PackageEdit = () => {
  const location = useLocation();
  const DataItem = location?.state.Id || {};
  console.log("Location is -->", DataItem);
  const baseURL = commonBaseUrl();
  const imageURL = commonImageUrl();
  const navigate = useNavigate();
  const initialDataState: State = {
    take: 10,
    skip: 0,
    group: [],
  };
  //   const [newPackage, setNewPackage] = useState("");
  //   const [newInclusion, setNewInclusion] = useState(defaultInclusionItem);
  //   const [newExclusion, setNewExclusion] = useState(defaultExclusionItem);
  const [editID, setEditID] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState<State>(initialDataState);
  
  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = spaceList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Packages",
      route: "/masters/package",
    },
    {
      id: "3",
      text: "Edit Package",
      route: "",
    },
  ];
  const breadCrumbsDatumn = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Packages",
      route: "/masters/package",
    },
    {
      id: "3",
      text: "Edit Package",
      route: "",
    },
  ];
  const getActivePageableSettings = () => {
    const length = activeDiscountList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  //mani sir code
  const [spaces, setSpaces] = useState<Space[]>();

  const [selectedSpaces, setSelectedSpaces] = useState<string[]>([]);
  //   const [durationSpaceList, setDurationSpaceList] = useState<DurationData[]>(
  //     []
  //   );
  const [discountTypeList, discountTypeListSet] = useState<any>();

  const [spaceList, spaceListSet] = useState<Spac[]>([]);
  const [firsttime, setFirstTime] = useState(true);
  const [selectedDiscount, selectedDiscountSet] = useState();
  //   const [durationSpaceList, durationSpaceListSet] = useState<any>([]);
  const [allDiscountData, setAllDiscountData] = useState<any>();
  const storeDiscountData: any[] = [];

  const reset = () => {
    console.log("Data in the list before selecting ==>", allDiscountData);
    if (allDiscountData !== undefined) {
      if (allDiscountData.length !== 0) {
        setAllDiscountData((prevSpaces: any) =>
          prevSpaces.map((space: Space) => {
            return {
              ...space,
              durationDetails: space.durationDetails.map(
                (detail: DurationDetail) => {
                  return {
                    ...detail,
                    discountValue: 0,
                    discountPrice: 0,
                  };
                  // return detail;
                }
              ),
            };
          })
        );
      }
    }
  };

  const [allDurationData, setAllDurationData] = useState<{
    [key: string]: DurationData[];
  }>({});
  const datas: { [key: string]: DurationData[] } = {};

  const durationSpaceApi = async (data: any, selectedSpaceType: any) => {
    const durationList = data;
    console.log("the upcoming data was->", data);
    const transformedData = transformResponse(durationList, selectedSpaceType);
    if (allDiscountData !== undefined) {
      console.log("calling", storeDiscountData);
      for (let d = 0; d < allDiscountData.length; d++) {
        storeDiscountData.push(allDiscountData[d]);
      }
    }
    console.log("Before storing Data -->", storeDiscountData);
    console.log("Transformed Data -->", transformedData);
    storeDiscountData.push(transformedData);
    // setDurationSpaceList(transformedData);
    setAllDiscountData(storeDiscountData);
    console.log("After Store", storeDiscountData);
  };
  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}
 
.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}
 
.no-wheel input::-moz-inner-spin-button {
  display: none;
}`;

  const transformResponse = (response: any, selectedSpaceType: string) => {
    return {
      spaceName: selectedSpaceType,
      durationDetails: response.map((detail: any) => ({
        applicableSpacesId: detail.SpaceHeaderId,
        durationId: detail.DurationId,
        durationName: detail.Duration.DurationType,
        actualPrice: detail.RentAmount,
        discountValue: 0, // Default value
        discountPrice: 0, // Default value
      })),
    };
  };

  const [edit, setEdit] = useState(false);
  const [search, searchSet] = useState("");
  const [discountList, discountListSet] = useState([
    "Fixed off",
    "Percentage off",
  ]);
  const [selectedValue, setSelectedValue] = useState("1");

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setSelectedValue(e.value);
    },
    [setSelectedValue]
  );

  const [checked, checkedSet] = useState(false);
  const checkclicked = (item: any, index: any, checked: boolean) => {
    // console.log(index, item, "<");
    // console.log("State -->", checked);
    const field = item.Id;
    const checkupdate = spaceList.map((item: any, idx: any) => {
      //   console.log(idx, index, "<s");
      if (idx === index) {
        // console.log(idx, index, "<st");
        // console.log("Check when change", checked);
        return { ...item, ["checked"]: !checked };
      }
      return item;
    });
    spaceListSet(checkupdate);
    // console.log("Space List", checkupdate);
    // checkedSet(!checked);
    if (!checked) {
      //   durationSpaceApi(item.Id);
    }
  };

  //Handle mani code
  const handleDiscountChange = (
    spaceId: string,
    durationId: number,
    field: keyof DurationDetail,
    value: number
  ) => {
    setAllDiscountData((prevSpaces: any) =>
      prevSpaces.map((space: Space) => {
        if (space.spaceName === spaceId) {
          return {
            ...space,
            durationDetails: space.durationDetails.map(
              (detail: DurationDetail) => {
                if (detail.durationId === durationId) {
                  const updatedDetail = detail;
                  updatedDetail.discountValue = value;
                  if (selectType.id === 2) {
                    const discountAmount =
                      (updatedDetail.discountValue *
                        updatedDetail.actualPrice) /
                      100;
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - discountAmount >= 0
                        ? updatedDetail.actualPrice - discountAmount
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  } else {
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - updatedDetail.discountValue >=
                      0
                        ? updatedDetail.actualPrice -
                          updatedDetail.discountValue
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  }
                  // }
                  return updatedDetail;
                }
                return detail;
              }
            ),
          };
        }
        return space;
      })
    );
  };

  // Handle discount input change
  const handleDiscountChanges = (e: any, index: any, indexes: any) => {
    const updatedList = allDiscountData;
    console.log(
      "Data came from -->",
      updatedList[indexes].durationDetails[index]
    );
    var data = updatedList[indexes].durationDetails[index];
    data.discountValue = parseInt(e.target.value);
    data.discountPrice =
      parseInt(data.actualPrice) - parseInt(data.discountValue) >= 0
        ? parseInt(data.actualPrice) - parseInt(data.discountValue)
        : 0;
    // updatedList[indexes].durationDetails[index].discountValue = e.target.value;
    // updatedList[indexes].durationDetails[index].discountPrice = e.target.value;
    console.log("Value come from", data);
    console.log(
      "Before updation-->",
      updatedList[indexes].durationDetails[index]
    );
    updatedList[indexes].durationDetails[index] = data;
    console.log(
      "After updation-->",
      updatedList[indexes].durationDetails[index]
    );
    console.log("Updated Whole data-->", updatedList);
    setAllDiscountData(updatedList);
    // updatedList[index].DiscountPrice =
    //   updatedList[index].RentAmount - e.target.value;
    // setDurationSpaceList(updatedList);
  };

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const [startDate, startDateSet] = useState();
  const [endDate, endDateSet] = useState();
  const [selectedUsage, selectedUsageSet] = useState("");
  const convertToISOFormat = (inputDate: any): string => {
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);

    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );

    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;

    return isoFormattedDate;
  };
  const updatePackage = (data: any) => {
    newCheckSet(true);
    if (
      packageName.trim() !== "" &&
      newInclusionList.length !== 0 &&
      newExclusionList.length !== 0
    ) {
      let add = {
        id: DataItem,
        packageName: packageName,
        inclusion: newCollectInclusion,
        inclusionId: newInclusionId,
        exclusion: newCollectExclusion,
        exclusionId:  newExclusionId,
        packageFoodList: data
      };
      console.log("Ready for api", add);
      updatePackageApi(add);
      //API Calling
    }
    if (packageName.trim() === "") {
      newPackageErrorSet("Package name must be provide");
    } else {
      newPackageErrorSet("");
    }
    if (newInclusionList.length === 0) {
      newInclusionErrorSet("Choose any Inclusion from the list");
      setSelectedTab(0);
    } else {
      newInclusionErrorSet("");
    }
    if (newExclusionList.length === 0) {
      newExclusionErrorSet("Choose any Exclusion from the list");
      setSelectedTab(0);
    } else {
      newExclusionErrorSet("");
    }
  };
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [handler, handlerSet] = useState(false);
  const updatePackageApi = async (data: any) => {
    console.log("Filtered data The given data were -->",data)
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(`${baseURL}odata/UpdatePackage?key=${DataItem}`, data);
      console.log("Package Updated", response.data);
      alertSet("Package updated successfully");
      statusDialogSet(true);
      handlerSet(false);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
      handlerSet(true);
    }
  };
  const [packageName, packageNameSet] = useState("");
  const [discountDescription, discountDescriptionSet] = useState("");
  // const [selectedType, selectedTypeSet ] = useState("");
  const defaultType = { id: null, name: "Choose the discount type" };
  const [selectType, selectedTypeSet] = useState(defaultType);
  const handleDropdown = (e: any) => {
    console.log("The selected Dropdown value", e);
    selectedTypeSet(e);
    reset();
  };
  //Sri Code ^ below


  const [nameError, nameErrorSet] = useState("");
  const [descriptionError, descriptionErrorSet] = useState("");
  const [typeError, typeErrorSet] = useState("");
  const [startError, startErrorSet] = useState("");
  const [endError, endErrorSet] = useState("");
  const [limitError, limitErrorSet] = useState("");
  const [spaceError, spaceErrorSet] = useState("");
  const [check, checkSet] = useState(false);

  const customStyles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;
  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  const setTimeCalc = (value: any, name: string) => {
    const curDate = new Date();
    console.log(convertToISOFormat(value), "<<<drs<");
    if (name === "end") {
      endDateSet(value);
    } else {
      startDateSet(value);
      if (value > endDate! && endDate !== undefined) {
        endDateSet(value);
      }
    }
  };

  const [loading, setLoading] = useState(false); // State for loading


  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevents arrow key actions
    }
    if (event.key === "." || event.key === "," || event.key === "-") {
      event.preventDefault(); // Prevents decimal input
    }
  };

  const [newInclusionList, setNewInclusionList] = useState<any>([]);
  const [newExclusionList, setNewExclusionList] = useState<any>([]);
  const [amenityList, amenityListSet] = useState<any>([]);

  const inclusionDatum = amenityList.map(
    (amenity: any) => ({
      ...amenity,
      disabled: newExclusionList.some(
        (inclusionItem: any) => inclusionItem.Id === amenity.Id
      ),
    }),
    [newExclusionList]
  );
  const exclusionDatum = amenityList.map(
    (amenity: any) => ({
      ...amenity,
      disabled: newInclusionList.some(
        (inclusionItem: any) => inclusionItem.Id === amenity.Id
      ),
    }),
    [newInclusionList]
  );


  const [newCheck, newCheckSet] = useState(false);
  const [newCollectInclusion, newCollectInclusionSet] = useState("");
  const [newCollectExclusion, newCollectExclusionSet] = useState("");
  const [newPackageError, newPackageErrorSet] = useState("");
  const [newInclusionError, newInclusionErrorSet] = useState("");
  const [newExclusionError, newExclusionErrorSet] = useState("");
  const [newInclusionId, newInclusionIdSet] = useState("");
  const [newExclusionId, newExclusionIdSet] = useState("");
  
  function handleInclusionNew(value: any) {
    const newInclusion = value;
    // Concatenate the selected inclusion IDs into a string
    var collectedIds = newInclusion.map((item: any) => item.Name).join(",");
    var collected = newInclusion.map((item: any) => item.Id).join(",");
    setNewInclusionList(value);
    // setNewExclusionList([]);
    console.log("The updated list", value);
    newCollectInclusionSet(collectedIds);
    newInclusionIdSet(collected);
  }
  function handleExclusionNew(value: any) {
    const newExclusion = value;
    // Concatenate the selected inclusion IDs into a string
    var collectedId = newExclusion.map((item: any) => item.Name).join(",");
    var collected = newExclusion.map((item: any) => item.Id).join(",");
    setNewExclusionList(value);
    // setNewExclusionList([]);
    console.log("The updated list", value);
    newCollectExclusionSet(collectedId);
    newExclusionIdSet(collected);
  }
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabSelect = (e: any) => {
    console.log("Selected value", e.selected);
    let value = e.selected + 1;
    setSelectedTab(e.selected);
    console.log("Selected status value", value);
  }; 
  const [activeDiscountList, setActiveDiscountList] = useState<any[]>([]);
  const [categoryList, categoryListSet] = useState<any>([]);
  const CategoryApi = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/Categorys`);
      console.log("Category Response", response.data.value);
      categoryListSet(response.data.value);
      SubCategoryApi(response.data.value);
    } catch (err) {}
  };
  const hasFetched = useRef(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const usageRef = useRef<any>(null);
  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;
    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };
    if (element) {
      // Attach wheel event listener
      element.addEventListener("wheel", handleWheel, { passive: false });
      // Clean up event listener
      return () => {
        element.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);
  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      console.log("inputElement is: ", inputElement); // Log to check the reference type

      const handleWheel = (event: WheelEvent) => {
        event.preventDefault(); // Prevents mouse wheel scrolling
      };

      // Ensure inputElement is a valid HTMLInputElement before adding the event listener
      if (inputElement instanceof HTMLInputElement) {
        inputElement.addEventListener("wheel", handleWheel);
      }

      // Clean up event listener on component unmount
      return () => {
        if (inputElement instanceof HTMLInputElement) {
          inputElement.removeEventListener("wheel", handleWheel);
        }
      };
    }
  }, []);
  useEffect(() => {
    const inputElement = usageRef.current?.element?.querySelector("input");
    if (inputElement) {
      // Disable arrow keys
      inputElement.addEventListener("keydown", (event: KeyboardEvent) => {
        if (event.key === "ArrowUp" || event.key === "ArrowDown") {
          event.preventDefault();
        }
      });

      // Disable mouse wheel
      inputElement.addEventListener("wheel", (event: WheelEvent) => {
        event.preventDefault();
      });

      // Clean up event listeners
      return () => {
        inputElement.removeEventListener("keydown", () => {});
        inputElement.removeEventListener("wheel", () => {});
      };
    }
  }, [usageRef]);

    const AmenityApi = async (datumn :any,filterData:any) => {
      try {
        const response = await axios.get(`${baseURL}odata/Amenitys`);
        amenityListSet(response.data.value);
        console.log("Details -->",datumn);
        packageNameSet(datumn.PackageName);
        newCollectInclusionSet(datumn.Inclusion);
        newInclusionIdSet(datumn.InclusionId);
        newCollectExclusionSet(datumn.Exclusion);
        newExclusionIdSet(datumn.ExclusionId);
        setNewInclusionList(extractSelected(datumn.Inclusion,response.data.value))
        setNewExclusionList(extractSelected(datumn.Exclusion,response.data.value))
        console.log("Extracted set is", datumn);
        console.log("Extracted set is", extractSelected(datumn.Inclusion,response.data.value));
        console.log("Extracted set is filteredData", filterData);
        // console.log("Extracted set is", extractedSet(datumn.PackageFoodList))
        console.log("Extracted set is extraction", extract(datumn.PackageFoodList, filterData));
        packageDataSet(extract(datumn.PackageFoodList, filterData));
      } catch (err) {}
    };   
 const SubCategoryApi = async (categoryList:any) => {
    console.log("Category list -->",categoryList);
      try {
        const response = await axios.get(`${baseURL}odata/SubCategorys`);
        const subCategoryList: any[] = [];
        const expiredDiscounts: any[] = [];
        console.log("subcat headers-->", response.data.value);
        //   console.log("Discount headers-->", response.data);
        for (let d = 0; d < response.data.value.length; d++) {
          const discount = response.data.value[d];
          console.log("subcat headeers-->", response.data.value[d]);
          console.log("Condition -->", discount.isActive);
          console.log("Condition -->", discount.isExpired);

          if (discount.isExpired) {
            expiredDiscounts.push(discount);
          } else {
            subCategoryList.push(discount);
          }
        }
        console.log("Sub-Category Discount -->", subCategoryList);
        for (let ad = 0; ad < subCategoryList.length; ad++) {
          const response = await axios.get(
            `${baseURL}odata/DocumentUploads?$filter=refno eq ${subCategoryList[ad].Id} and contains(reftype, 'Food')`
          );
          subCategoryList[ad].sno = ad + 1;
          subCategoryList[ad].check = false;
          subCategoryList[ad].tempQuantity = 0;
          console.log("Category Discount -->", categoryList); 
          for (let i = 0; i < categoryList.length; i++) {
            if (subCategoryList[ad].CategoryId === categoryList[i].Id) {
              const imageList = response.data.value;
              console.log("Data Doc after for", imageList);
              if (imageList.length > 0) {
                subCategoryList[ad].image = imageList[imageList.length - 1].ImagePath;
                console.log(i, "for image ==> ",subCategoryList[ad].image);
                subCategoryList[ad].Category = categoryList[i].Name;
              }
            }
          }
        }
        console.log("Active subcat after loop-->", subCategoryList);
        setActiveDiscountList(subCategoryList);
        setFilteredData(subCategoryList);
        GetPackageApi(subCategoryList);  
      } catch (err) {}
    };
  //category,subcat and amenity
  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    CategoryApi();
  }, []);

  const [filteredData, setFilteredData] = useState(activeDiscountList); 

  const [dataActiveState, setActiveDataState] =
    useState<State>(initialDataState);
  const handleSearchChange = (e: any) => {
    setActiveDataState(initialDataState);
    searchSet(e.target.value);
  };
  const handleActivePageChanges = (e: any) => {
    setActiveDataState(e.dataState);
  };
  // Reset filter values


  const resetSearch = () => {
    searchSet("");
    setFilteredData(activeDiscountList); // Reset to original data
  };
  const handleValueSet = (packages:any)=>{
    console.log("Packages map",packages);
    for(let i=0;i<packages.length;i++){
    setFilteredData((prevData) =>
        prevData.map((row, idx) => {
          return row.Id === packages[i].SubCategoryId
            ? {
                ...row,
                check: true,
                tempQuantity: packages[i].PackageQty,
                personalid: packages[i].Id,
              }
            : row;
        })
      )
    }
    console.log("The required theme was",filteredData);
  }
  const handleCheckboxChange = (index: number, tableRow: any) => {
    console.log("Data ==>", tableRow);
    console.log("Index ==>", index);
    console.log("The given set ==>", filteredData);
    setFilteredData((prevData) =>
      prevData.map((row, idx) => {
        return idx === index
          ? {
              ...row,
              check: !row.check,
              tempQuantity: check===false ? 0: 1,
            }
          : row;
      })
    );
  };
  
  const handleQty = (e: any, index: any) => {
    setFilteredData((prev: any) =>
      prev.map((row: any, idx: any) => {
        return idx === index
          ? {
              ...row,
              tempQuantity: e.value,
            }
          : row;
      })
    );
  };
  const [data, dataSet] = useState<any[]>([]);
  const apiData: any[] = [];
  const handleUpdate = ()=>{
    console.log("Filtered data ==>", filteredData);
    console.log("Filtered data ==>", packageData);
    
     for(let i =0; i<filteredData.length;i++){
        if(filteredData[i].check === true){
            const detail = filteredData[i];
            console.log("Details",detail);
            apiData.push(detail);
        }
     }
     let Validate = true;
     filteredData.forEach((item:any)=>{
        console.log("TempQuantity -->", item.tempQuantity);
        if(item.tempQuantity <= 0 && item.check ){
            Validate = false;
        }
     })
     console.log("Filtered data Api Data was -->", apiData);
     const dats = apiData.map((dataItem: any, index: number) => {
        console.log("Space Index -->", index);
        console.log("Api data was dataitem-->",dataItem );
        return {
            id: dataItem.personalid || 0,
            packageHeaderId: DataItem,
            categoryId: dataItem.CategoryId,
            subCategoryId: dataItem.Id,
            packageQty: parseInt(dataItem.tempQuantity),
            isActive:true
        };
      });
      console.log("Filtered data Api Set -->",dats,Validate);
      if(Validate === true){
        updatePackage(dats);
      }
  }
  const extractSelected = (collectedInclusion: any, amnityList: any) => {
    // Convert the collectedInclusion string back to an array of names
    console.log("Extracted collection -->",collectedInclusion)
    const inclusionNames =
      collectedInclusion !== null ? collectedInclusion.split(",") : [];

    // Find the matching amenities by name
    console.log("Extracted collection names-->", inclusionNames);
    console.log("Extracted collection Amenity list -->", amnityList);
    const selectedInclusions = amnityList.filter((amenity: any) =>
      inclusionNames.includes(amenity.Name)
    );
    console.log("Extracted collection names selected-->", selectedInclusions);
    return selectedInclusions;
  };

 const extract = (packagefoodlist:any, subcategoryList:any)=>{
    const combinedList = packagefoodlist.reduce((acc:any, item:any) => {
        const relatedSubcategories = subcategoryList.filter((sub:any) => parseInt(sub.Id) === parseInt(item.SubCategoryId));  // adjust condition as needed
        relatedSubcategories.forEach((sub:any) => {
          acc.push({
            ...item,
            Name: sub.Name,  // Map fields from subcategory
            IsActive:true,
            check: true,
            price: sub.Price,
          });
        });
        return acc;
      }, []);
      console.log("Filtered final -->",combinedList);
      handleValueSet(combinedList);
      return combinedList;
 }
 const [packageData, packageDataSet] = useState<any>();
  const GetPackageApi = async(subList:any)=>{
    console.log("Extracted set is", DataItem);
    try {
        const response = await axios.get(`${baseURL}odata/PackageDetails(${DataItem})?$expand=PackageFoodList`);
        var datumn = response.data;
        AmenityApi(datumn,subList);
    } catch (error) {}
};

  return (
    <div>
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() =>handler? window.location.reload(): navigate("/masters/package")}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} /> 
        <hr className="hr-border" />
          </div>
          <div className="view-margin">
            <div className="common-margin view-margin">
              <div style={{ marginLeft: "10px" }}>
                <div className="custom-input-views">
                  <Label className="common-label-size">
                    Package Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    className="book-height"
                    type="text"
                    maxLength={25}
                    value={packageName}
                    onChange={(e) => packageNameSet(e.value)}
                    placeholder="Enter the package name"
                  />
                  {newPackageError && <Error>{newPackageError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div style={{display:"flex", justifyContent:"space-between"}}>
              <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
                <TabStripTab title="Inclusions / Exclusions">
                  <div
                    className="custom-inputs-container"
                    style={{ display: "flex" }}
                  >
                    <div
                      className="custom-inputs-view"
                      style={{ width: "100%" }}
                    >
                      <Label className="common-label-size">
                        Inclusion <span style={{ color: "red" }}>*</span>
                      </Label>
                      <MultiSelect
                        // className="custom-dropdowns"
                        data={inclusionDatum}
                        dataItemKey="Id"
                        textField="Name"
                        required={newCheck}
                        value={newInclusionList}
                        onChange={(e) => {
                          handleInclusionNew(e.target.value);
                          // setNewInclusionList(e.target.value); setNewExclusionList([]);console.log("The updated list",e.target.value);
                        }}
                        placeholder="Select Inclusions"
                      />
                      {newInclusionError && <Error>{newInclusionError}</Error>}
                    </div>
                    <div style={{ width: "40px" }}> </div>
                    <div
                      className="custom-inputs-view"
                      style={{ width: "100%" }}
                    >
                      <Label className="common-label-size">
                        Exclusion <span style={{ color: "red" }}>*</span>
                      </Label>
                      <MultiSelect
                        // className="custom-dropdowns"
                        data={exclusionDatum}
                        dataItemKey="Id"
                        textField="Name"
                        value={newExclusionList}
                        required={newCheck}
                        // value={newExclusionList}
                        onChange={(e) => {
                          handleExclusionNew(e.target.value);
                          // setNewExclusionList(e.target.value); console.log("The updated ex list",e.target.value);
                        }}
                        placeholder="Select Exclusions"
                      />
                      {newExclusionError && <Error>{newExclusionError}</Error>}
                    </div>
                  </div>
                </TabStripTab>
                <TabStripTab title="Foods">
                  <div>
                    <table cellPadding="10">
                        <thead>
                          <tr>
                            <th>Select</th>
                            <th style={{justifyContent:"center", display:"flex"}}>Image</th>
                            <th>Name</th>
                            <th>Category</th>
                            <th style={{justifyContent:"center", display:"flex"}}>Quantity</th>
                            <th>Price</th>
                          </tr>
                          </thead>
                          <tbody>
                            {filteredData.map((dataItem:any, Index)=>(
                             <tr>
                                <td>
                            <Checkbox
                              style={{
                                borderColor: "black",
                                borderStyle: "solid",
                              }}
                              checked={dataItem.check}
                              onChange={() =>
                                handleCheckboxChange(
                                  Index,
                                  dataItem
                                )
                              }
                            />
                          </td>
                          <td>
                              <img
                                src={`${imageURL}${dataItem.image}`}
                                height={"50px"}
                                width={"80px"}
                              />
                              {/* Image should be display here */}
                            </td>
                            <td>{dataItem.Name}</td>
                            <td>{dataItem.Category}</td>
                            <td>
                            <div
                              style={{ display: "flex", flexDirection: "row",justifyContent:"center",
                              }}
                            >
                              <Input
                                style={{
                                  width: "40%",
                                  fontSize: "1.3rem",
                                  marginLeft: "10px",
                                }}
                                disabled={!dataItem.check}
                                type="number"
                                min={!dataItem.check ?0:1}
                                maxLength={5}
                                max={dataItem.FixedQuantity}
                                onKeyDown={handleKeyDown}
                                // readOnly={edited}
                                className="book-dropdown-news"
                                value={dataItem.tempQuantity === 0 ? "":dataItem.tempQuantity}
                                valid = {!dataItem.check || dataItem.tempQuantity !== 0}
                                onChange={(e) => {
                                  console.log("Event ->",e.nativeEvent.inputType);
                                  console.log("Event index->", Index);
                                  if (e.nativeEvent.inputType !== undefined) {
                                    if(e.value <= dataItem.FixedQuantity){
                                    handleQty(e, Index);
                                    }
                                  }
                                }}
                              />
                              <Label
                                style={{
                                  fontSize: "1.3rem",
                                  marginLeft: "10px",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <b>/ {dataItem.FixedQuantity}</b>
                              </Label>
                            </div>
                            {dataItem.check && dataItem.tempQuantity <= 0 && (
                                    <Error>Value cannot be Empty or Zero</Error>
                                  )}
                          </td>
                            <td>{dataItem.Price}</td>
                            </tr>
                            ))}
                          </tbody>
                          </table>
                  </div>
                </TabStripTab>
              </TabStrip>
              <div style={{display:"flex", justifyContent:"start",alignItems:"start"}}>
               <Button
                  className="button-view "
                  onClick={() => handleUpdate()}
                >
                  Update
            
                </Button>
                <Button className="common-button-view" onClick={()=>navigate("/masters/package")}>
                  CANCEL
                </Button>
              </div>
            </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PackageEdit;
