import React, { useEffect, useRef, useState } from "react";
// import { format } from "date-fns";
import bcrypt from "bcryptjs";
// import moment from "moment";

export const encryptedBcryptPassword = (password) => {
  var salt = bcrypt.genSaltSync(10);
  var hashPassword = bcrypt.hashSync(password, salt);
  return hashPassword;
};

export const commonBaseUrl = () => {
  // const baseURL = `https://dev-cw-api.equadriga.in/`; // Dev
  const baseURL = `https://preprod-api.workfromhaven.in/`; // Pre
  // const baseURL = `https://uat-wfh-api.equadriga.in/`; // UAT
  return baseURL;
};

export const commonImageUrl = () => {
  // const imageURL = `https://cws-dev-stor.equadriga.in/`; // Dev
  const imageURL = `https://preprod-stor.workfromhaven.in/`; // Pre
  // const imageURL = `https://uat-wfh-stor.equadriga.in/`; // UAT
  return imageURL;
};
