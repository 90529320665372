import React, { createRef, useEffect, useRef, useState } from "react";
import "../../css/Dashboard.scss";
import "../../css/Common.scss";
import { Button, ToolbarItem } from "@progress/kendo-react-buttons";
import { useLocation, useNavigate } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Label } from "@progress/kendo-react-labels";
import Breadcrumbs from "../../components/Breadcrumbs";
import { formatDateDMY } from "../../components/commoncomponents";
import { MyDatePicker } from "../MyDatePicker";
import { ExternalDropZone, UploadFileInfo } from "@progress/kendo-react-upload";
import { Input, RadioButton } from "@progress/kendo-react-inputs";
import { Signature } from "@progress/kendo-react-inputs";
import { Upload } from "@progress/kendo-react-upload";
import axios from "axios";
import { commonBaseUrl, commonImageUrl } from "../../../services/CommonUtil";
import { Loader } from "@progress/kendo-react-indicators";
import { Dialog } from "@progress/kendo-react-dialogs";

interface BookingDetail {
  SpaceBooked: string;
  BookingDate: string;
  BookingFrom: Date;
  BookingTo: Date;
  Duration: string;
  Deposit: string;
  TotalCost: string;
}

interface CustomerDetails {
  Name: string;
  Mobile: string;
  Email: string;
  Address: string;
  Notes: string;
}

interface Row {
  type: string;
  description: string;
  rent: number;
  discount: number;
  subTotal: number;
}

export const EditContractMasterList = () => {
  const baseUrl = commonBaseUrl();
  const imageUrl = commonImageUrl();
  const Location = useLocation();
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [view, setView] = useState(false);
  const [BookingDetails, setBookingCustomerDetails] = useState<BookingDetail>();
  const [customerDetails, setCustomerDetails] = useState<CustomerDetails>();
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [errorstartDate, setErrorstartDate] = useState<string | null>(null);
  const [errorDate, setErrorDate] = useState<string | null>(null);
  const [editorContent, setEditorContent] = useState("");
  const handleEditorChange = (event: any) => {
    setEditorContent(event.html);
    console.log(event.html, "<<");
  };

  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [staffName, setStaffName] = useState("");
  const [staffDes, setStaffDes] = useState("");

  const [imageDatastaff, setImageDatastaff] = useState("");
  const [imageDatacustomer, setImageDatacustomer] = useState("");
  const [imageDatav1, setImageDatav1] = useState("");
  const [imageDatav2, setImageDatav2] = useState("");

  const [selectedValuestaff, selectedValuestaffSet] = useState("");
  const [selectedValuecustomer, selectedValuecustomerSet] = useState("");
  const [selectedValuev1, setSelectedValuev1] = useState("");
  const [selectedValuev2, selectedValuev2Set] = useState("");

  const [currentFilestaff, setCurrentFilestaff] = useState<UploadFileInfo>();
  const [currentFilecustomer, setCurrentFilecustomer] =
    useState<UploadFileInfo>();
  const [currentFilev1, setCurrentFilev1] = useState<UploadFileInfo>();
  const [currentFilev2, setCurrentFilev2] = useState<UploadFileInfo>();

  const [staffNameError, setStaffNameError] = useState("");
  const [staffDesError, setStaffError] = useState("");
  const [staffImageError, setStaffImageError] = useState("");
  const [customerImageError, serCustomerImageError] = useState("");
  const [witness1Error, setWitness1Error] = useState("");
  const [witness2Error, setWitness2Error] = useState("");

  const [statusDialog, statusDialogSet] = useState(false);
  const [alertState, alertStateSet] = useState(false);
  const [alert, alertSet] = useState("");
  const [loading, setLoading] = useState(false); // State for loading

  const validate = () => {
    // Returns true if no errors
  };
  const handleSubmit = () => {
    let validate = true;

    if (!staffName.trim()) {
      setStaffNameError("Staff Name is required.");
      validate = false;
    } else {
      setStaffNameError("");
    }

    if (!staffDes.trim()) {
      setStaffError("Staff Designation is required.");
      validate = false;
    } else {
      setStaffError("");
    }

    if (imageDatastaff === "") {
      setStaffImageError("Staff Signature is required");
      validate = false;
    } else {
      setStaffImageError("");
    }

    if (imageDatacustomer === "") {
      serCustomerImageError(" Customer Signature is required");
      validate = false;
    } else {
      serCustomerImageError("");
    }

    if (imageDatav1 === "") {
      setWitness1Error("Witness sign 1 is required");
      validate = false;
    } else {
      setWitness1Error("");
    }

    if (imageDatav2 === "") {
      setWitness2Error("Witness sign 2 is required");
      validate = false;
    } else {
      setWitness2Error("");
    }

    console.log("sign", imageDatastaff);
    if (validate == true) {
      addDetailInvoice();
      console.log("Form submitted successfully!");
    } else {
      console.log("Validation errors:");
    }
  };

  //styles
  const customStyles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;

  // Breadcrums
  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Contract master",
      route: "/masters/contractmaster",
    },
    {
      id: "3",
      text: "Add Contract",
      route: "",
    },
  ];

  const handleStartDateChange = (date: Date | null) => {
    setStartDate(date);
    validateDates(date, endDate); // Validate when start date is changed
  };

  const handleEndDateChange = (date: Date | null) => {
    setEndDate(date);
    validateDates(startDate, date); // Validate when end date is changed
  };

  const validateDates = (start: Date | null, end: Date | null) => {
    if (start && end && end < start) {
      setErrorDate("End date cannot be before start date.");
      return false;
    }
    setErrorDate(null); // Clear error if dates are valid
    return true;
  };

  ////////////////////////////////////////////////////////////////////////////////////
  const [selectedOption, setSelectedOption] = useState("Terms & Conditions");
  const [signatureMethod, setSignatureMethod] = useState("Upload File");
  const [uploadedFile, setUploadedFile] = useState(null);

  const termsOptions = [
    "Terms & Conditions",
    "Privacy Policy",
    "License Agreement",
  ];

  const signatureOptions = ["Saved Signs", "Upload File", "Draw Signature"];

  const handleUpload = (event: any) => {
    setUploadedFile(event.newState[0].name); // Capture uploaded file
  };

  const [newVarientError, newVarientErrorSet] = useState("");
  const uploadRefs = createRef<Upload>();
  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );

  const note = <span>Only JPEG, JPG, PNG and PDF files are allowed.</span>;
  const [errorimageMessage, setErrorimegeMessage] = useState<string>("");

  function onSaveRequeststaff(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFilestaff(currentFile);
    const uid = currentFile.uid;
    console.log(currentFile, "121");

    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageDatastaff(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onSaveRequestCustomer(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFilecustomer(currentFile);
    const uid = currentFile.uid;
    console.log(currentFile, "121");

    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageDatacustomer(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onSaveRequestv1(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFilev1(currentFile);
    const uid = currentFile.uid;
    console.log(currentFile, "121");

    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageDatav1(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onSaveRequestv2(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFilev2(currentFile);
    const uid = currentFile.uid;
    console.log(currentFile, "121");

    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageDatav2(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };

        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onRemoveRequeststaff(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageDatastaff("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  function onRemoveRequestcustomer(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageDatacustomer("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  function onRemoveRequestv1(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageDatav1("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }

  function onRemoveRequestv2(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageDatav2("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }
  ////////////////////////////////////////////////////////////////////////////////////////////

  const [value, setValue] = React.useState<string>();
  const [imageURL, setImageURL] = React.useState<string>();

  const handleChangestaff = async (event: any) => {
    const signatureDataURL = event;
    if (event.value !== undefined) {
      const [metadata, base64Data] = event.value.split(",");
      console.log(base64Data, "dfghjkjhgfdsfghj");
      setImageDatastaff(base64Data);

      const file = new File([base64Data], "signature.png", {
        type: "image/png",
      });
      const mockFile: UploadFileInfo = {
        uid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: file.name,
        size: file.size,
        extension: ".png",
        status: 0,
        progress: 0,
        validationErrors: [],
        getRawFile: () => file,
      };

      setCurrentFilestaff(mockFile);
    } else {
      setImageDatastaff("");
    }
  };

  const handleChangecustomer = async (event: any) => {
    const signatureDataURL = event;
    if (event.value !== undefined) {
      const [metadata, base64Data] = event.value.split(",");
      console.log(base64Data, "dfghjkjhgfdsfghj");
      setImageDatacustomer(base64Data);

      const file = new File([base64Data], "signature.png", {
        type: "image/png",
      });
      const mockFile: UploadFileInfo = {
        uid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: file.name,
        size: file.size,
        extension: ".png",
        status: 0,
        progress: 0,
        validationErrors: [],
        getRawFile: () => file,
      };

      setCurrentFilecustomer(mockFile);
    } else {
      setImageDatacustomer("");
    }
  };

  const handleChangev1 = async (event: any) => {
    const signatureDataURL = event;
    if (event.value !== undefined) {
      const [metadata, base64Data] = event.value.split(",");
      console.log(base64Data, "dfghjkjhgfdsfghj");
      setImageDatav1(base64Data);

      const file = new File([base64Data], "signature.png", {
        type: "image/png",
      });
      const mockFile: UploadFileInfo = {
        uid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: file.name,
        size: file.size,
        extension: ".png",
        status: 0,
        progress: 0,
        validationErrors: [],
        getRawFile: () => file,
      };

      setCurrentFilev1(mockFile);
    } else {
      setImageDatav1("");
    }
  };

  const handleChangev2 = async (event: any) => {
    const signatureDataURL = event;
    if (event.value !== undefined) {
      const [metadata, base64Data] = event.value.split(",");
      console.log(base64Data, "draw image");
      setImageDatav2(base64Data);

      const file = new File([base64Data], "signature.png", {
        type: "image/png",
      });
      const mockFile: UploadFileInfo = {
        uid: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
        name: file.name,
        size: file.size,
        extension: ".png",
        status: 0,
        progress: 0,
        validationErrors: [],
        getRawFile: () => file,
      };

      setCurrentFilev2(mockFile);
    } else {
      setImageDatav2("");
    }
  };

  const revokeImageURL = () => {
    if (imageURL) {
      console.log("cleanup");
      URL.revokeObjectURL(imageURL);
      setImageURL(undefined);
    }
  };

  const onSave = () => {
    if (value) {
      console.log(value, "sign");
      // saveAs(value, "signature.png");
    }
  };

  const onClear = () => {
    setValue(undefined);
    revokeImageURL();
  };

  // Dropdown flow
  const [users, setUsers] = useState([]);
  const [selectUser, setSelectUser] = useState({ Id: 0, Name: "Select User" });
  const [bookingId, bookingIdSet] = useState();
  const [selectBookingId, setSelectBookingId] = useState({
    Id: 0,
    bookingId: "Select Booking Id",
  });
  const [selectBookingIds, setSelectBookingIds] = useState({
    id: 0,
    bookingId: "Select Booking Id",
  });
  const [durationtype, setDurationType] = useState("");
  const [selectedTax, setSelectedTax] = useState();
  const [invoiceidEnabler, setInvoiceEnabler] = useState(false);
  const [bookingIdEnabler, setBookingIdEnabler] = useState(false);

  const [detailsEnabler, setDetailsEnabler] = useState(false);
  const [loader, setLoader] = useState(false);
  const [alertdilog, alertdilogSet] = useState("");
  const [deposit, setdeposit] = useState(0);
  const [taxRate, setTaxRate] = useState<number>(0);
  const [rentAmount, setRentAmount] = useState(0);
  const [rows, setRows] = useState<Row[]>([]);
  const [spaceId, getSpaceId] = useState();
  const locations = useLocation();

  useEffect(() => {
    // Prevent second call
    if (locations.state !== null) {
      console.log(locations.state, "kasytfdkhgsvdhkfkysdf");
      setDetailsEnabler(true);
      setInvoiceEnabler(true);
      setBookingIdEnabler(true);
      setView(locations.state.view);
      getContractMaster(locations.state.contractId);
    } else {
      if (hasFetched.current) return;
      hasFetched.current = true;
      fetchUsers();
    }
  }, []);

  const imageBaseUrl = commonImageUrl();
  const [ImagelistDetails, setImageListDetails] = useState([]);
  const getContractMaster = async (Id: any) => {
    try {
      //contractMasterget
      const responseDetails = await axios.get(
        `${baseUrl}odata/Contracts(${Id})`
      );
      console.log(responseDetails, "shugfusvludfvusydfuysdvugsv");

      //booking
      const responseBooking = await axios.get(
        `${baseUrl}odata/Bookings(${responseDetails.data.value[0].BookingId})`
      );
      const userId = responseBooking.data.UserId;

      const responceUser = await axios.get(`${baseUrl}odata/Users(${userId})`);
      const contractDetails = responseDetails.data.value[0];
      const bookingDetails = responseBooking.data;
      const userDetails = responceUser.data.value[0];

      console.log(
        contractDetails,
        "wedrtghyujioiuhygtfredswedfrgthyjukijuhygtfrde"
      );
      console.log(
        bookingDetails,
        "wedrtghyujioiuhygtfredswedfrgthyjukijuhygtfrdesss"
      );
      console.log(
        userDetails,
        "wedrtghyujioiuhygtfredswedfrgthyjukijuhygtfrde"
      );
      ///////
      const spaceIdContract = bookingDetails.SpaceId;
      const contractDurationId = bookingDetails.DurationId;
      const responseSpace = await axios.get(
        `${baseUrl}odata/SpaceHeaders(${spaceIdContract})`
      );

      const responseDuration = await axios.get(
        `${baseUrl}odata/Durations/${contractDurationId}`
      );

      const contractSpaceDetails = responseSpace.data;
      const contractDurationDetails = responseDuration.data.value[0];

      console.log(
        responseSpace.data,
        "wedrtghyujioiuhygtfredswedfrgthyjukijuhygtfrdes"
      );
      console.log(
        responseDuration.data.value,
        "wedrtghyujioiuhygtfredswedfrgthyjukijuhygtfrde"
      );
      ////////

      setSelectUser(userDetails);
      setSelectBookingIds(bookingDetails);
      setSelectBookingId(bookingDetails);
      setStaffName(contractDetails.StaffName);
      setStaffDes(contractDetails.StaffDesignation);
      setInvoiceNumber(contractDetails.InvoiceNumber);

      const spaceDetailss = {
        SpaceBooked: contractSpaceDetails.SpaceName,
        BookingDate: contractDetails.BookingDate,
        BookingFrom: contractDetails.PeriodStart,
        BookingTo: contractDetails.PeriodEnd,
        Duration: contractDurationDetails.Description,
        Deposit: `${bookingDetails.Deposit}`,
        TotalCost: bookingDetails.TotalAmount,
      };
      setBookingCustomerDetails(spaceDetailss);

      const userDetailsshow = {
        Name: userDetails.Name,
        Mobile: userDetails.Mobile,
        Email: userDetails.Email,
        Address: contractDetails.Address,
        Notes: "",
      };
      setCustomerDetails(userDetailsshow);

      // https://dev-cw-api.equadriga.in/odata/DocumentUploads?$filter=refno%20eq%2055

      const contractImageLists = await axios.get(
        `${baseUrl}odata/DocumentUploads?$filter=refno eq ${contractDetails.Id} and contains(reftype, 'Sign')`
      );
      console.log(
        contractImageLists.data.value,
        "sdfghjkjhgfdsasdfghjkloikjuhygtfrdewsdfghuji"
      );
      setImageListDetails(contractImageLists.data.value);
      const image11 = contractImageLists.data.value.filter(
        (Data: any) => Data.SubType === "staff"
      );
      setImg1(image11[0].ImagePath);
      const image12 = contractImageLists.data.value.filter(
        (Data: any) => Data.SubType === "customer"
      );
      setImg2(image12[0].ImagePath);
      const image13 = contractImageLists.data.value.filter(
        (Data: any) => Data.SubType === "witness1"
      );
      setImg3(image13[0].ImagePath);
      const image14 = contractImageLists.data.value.filter(
        (Data: any) => Data.SubType === "witness2"
      );
      setImg4(image14[0].ImagePath);

      console.log(image11, "sdfghjukijuhgfdsxdcfghjkl");
    } catch (e) {}
  };

  const [img1, setImg1] = useState("");
  const [img2, setImg2] = useState("");
  const [img3, setImg3] = useState("");
  const [img4, setImg4] = useState("");

  const fetchUsers = async (userId?: any) => {
    try {
      const response = await axios.get(
        `${baseUrl}odata/Users?$filter=(IsActive eq true)and(RoleId eq 2)`
      );

      const usersData = response.data.value || [];
      setUsers(usersData);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };

  //While select the user id
  const getinvoiceData = async (userData: any) => {
    console.log(userData, "sdfghjklkjhgbvfcdsxdfghjkjhgfdfghj");
    // const responceInvoiceData = await axios.get(`${baseUrl}odata/GetAllInvoiceByBookingId?bookingId=${}`)

    const details = {
      Name: userData.Name === null ? "" : userData.Name,
      Mobile: userData.Mobile === null ? "" : userData.Mobile,
      Email: userData.Email === null ? "" : userData.Email,
      Address: `${
        userData.Address !== null && userData.Address !== ""
          ? userData.Address + ","
          : ""
      } ${
        userData.Address2 !== null && userData.Address2.trim() !== ""
          ? userData.Address2 + ","
          : ""
      } ${
        userData.City !== null && userData.City !== ""
          ? userData.City + ","
          : ""
      } ${
        userData.Country !== null && userData.Country !== ""
          ? userData.Country + ","
          : ""
      }`,
      Notes: "",
    };
    setLoader(false);
    setCustomerDetails(details);
  };
  const [spaceIdValue, setSpaceIdValue] = useState(0);
  const fetchUserBooking = async (Id: any) => {
    console.log("Id from user -->", Id);
    try {
      const response = await axios.get(
        `${baseUrl}odata/GetAllInvoiceByUserId?userId=${Id}`
      );
      const bookingData = response.data || [];
      // const DataDetails = bookingData.map((gfd: any) => gfd.booking);

      // getinvoiceData(usersData);
      // setUsers(usersData);
      // console.log(DataDetails, "sjgfksdfguysldgfusdgfuy");
      bookingIdSet(bookingData);
    } catch (error) {
      console.error("Error fetching user booking data:", error);
    }
  };
  const [bookingDetails, setBookingDetails] = useState();
  const getBookingDetails = async (event: any) => {
    const BookingId = event.value.id;
    setSelectBookingIds(event.value);
    const responceInvoiceData = await axios.get(
      `${baseUrl}odata/GetAllInvoiceByBookingId?bookingId=${BookingId}`
    );
    const invoiceId =
      responceInvoiceData.data.length !== 0
        ? responceInvoiceData.data[0].invoiceCode
        : 0;
    setInvoiceNumber(invoiceId);

    console.log(invoiceId, "wsdfghjkjhgfdsdfghjk");
    setInvoiceEnabler(true);
    setSelectBookingId(event.value);
    console.log(event.value, "wertyuidfghj");
    setdeposit(event.value.deposit);

    try {
      const response = await axios.get(
        `${baseUrl}odata/GetAllTaxType?$filter=IsActive%20eq%20true`
      );

      const selectTaxs = response.data.find(
        (taxData: any) => taxData.id === event.value.taxId
      );
      setSelectedTax(selectTaxs);
      const responsecgstigst = await axios.get(
        `${baseUrl}odata/GetCGSTSGSTDetails?taxId=${event.value.taxId}`
      );

      const taxcget = responsecgstigst.data.cgstDetail;
      const taxsget = responsecgstigst.data.cgstDetail;
      const DataAdd = (taxcget.percentage + taxsget.percentage) / 100;
      console.log(selectTaxs, "1234rfvgbhjytrfdcv");
      setTaxRate(DataAdd);
      setRentAmount(parseInt(event.value.spaceRent));
      const rowDetails = [
        {
          type: "Space Rent",
          description: "",
          rent: event.value.spaceRent,
          discount: 0,
          // taxRate: selectTaxs,
          subTotal: event.value.spaceRent,
        },
      ];

      setRows(rowDetails);
    } catch (e) {}
    getSpaceId(event.value.spaceId);

    try {
      const responseSpace = await axios.get(
        `${baseUrl}odata/SpaceHeaders(${event.value.spaceId})`
      );

      const responseDuration = await axios.get(
        `${baseUrl}odata/Durations/${event.value.durationId}`
      );
      console.log(responseSpace.data, "responseSpace");
      setSpaceIdValue(responseSpace.data.SpaceTypeId);
      if (responseDuration.data.value.length === 0) {
        // Alert(responseDuration.data.value);
      }
      setDurationId(responseDuration.data.value[0]);

      console.log(
        "setBookingCustomerDetails",
        new Date(BookingDetails?.BookingFrom!)
      );
      console.log(event.value, "qwertyuioijuhgfdsxcvbhnjm");
      setBookingDetails(event.value);
      setBookingCustomerDetails({
        SpaceBooked: responseSpace.data.SpaceTypeName,
        BookingDate: event.value.bookingDatetime,
        BookingFrom: new Date(event.value.bookingFrom),
        BookingTo: new Date(event.value.bookingTo),
        Duration: responseDuration.data.value[0].DurationType,
        Deposit: event.value.deposit,
        TotalCost: event.value.totalAmount,
      });
      setDurationType(responseDuration.data.value[0].DurationType);
      setDetailsEnabler(true);
    } catch (error) {
      console.error("Error fetching user booking data:", error);
    }
  };

  const [durationId, setDurationId] = useState();

  const addDetailInvoice = async () => {
    setLoader(true);
    const bookingId = bookingDetails !== undefined ? bookingDetails["id"] : 0;
    const selectedDurationId = durationId !== undefined ? durationId["Id"] : 0;
    const DetailsSet = {
      statusId: 3,
      bookingId: bookingId,
      spaceBookedId: spaceIdValue,

      periodStart: BookingDetails?.BookingFrom,
      periodEnd: BookingDetails?.BookingTo,
      bookingDate: BookingDetails?.BookingDate + ".000Z",

      durationId: selectedDurationId,
      totalCost: BookingDetails?.TotalCost,

      name: customerDetails?.Name,
      mobile: customerDetails?.Mobile,
      email: customerDetails?.Email,
      address: customerDetails?.Address,

      termsConditionsId: 0,
      addendum: "",

      invoiceNumber: invoiceNumber,
      staffName: staffName,
      staffDesignation: staffDes,
    };
    // statusId: 3,
    // bookingId: bookingId,
    // spaceBookedId: spaceIdValue,
    // periodStart: BookingDetails?.BookingFrom,
    // periodEnd: BookingDetails?.BookingTo,
    // bookingDate: BookingDetails?.BookingDate,
    // durationId: selectedDurationId,
    // totalCost: BookingDetails?.TotalCost,
    // name: customerDetails?.Name,
    // mobile: customerDetails?.Mobile,
    // email: customerDetails?.Email,
    // address: customerDetails?.Address,
    // termsConditionsId: 0,
    // addendum: "",
    // invoiceNumber: invoiceNumber,
    // staffName: staffName,
    // staffDesignation: staffDes,

    try {
      const response = await axios.post(
        `${baseUrl}odata/Contracts`,
        DetailsSet
      );
      console.log(response.data.Id, "<-responceData");
      uploadDocumentFiles(response.data.Id);
    } catch (err) {
      console.log(err, "save");
      setLoader(false);
    }
  };

  //upload Image
  const uploadDocumentFiles = async (contractId: String) => {
    let header = {
      "Content-Type":
        "application/json;odata.metadata=minimal;odata.streaming=true",
      accept: "*/*",
    };
    let uploadData = [
      {
        docUploadGuid: currentFilestaff?.uid,
        docUploadRefType: "Sign",
        docUploadRefNo: contractId,
        docUploadRefSubType: "staff",
        image: [
          {
            value: imageDatastaff,
            fileName: currentFilestaff?.name,
            type: currentFilestaff?.extension?.split(".").pop(),
          },
        ],
      },
      {
        docUploadGuid: currentFilestaff?.uid,
        docUploadRefType: "Sign",
        docUploadRefNo: contractId,
        docUploadRefSubType: "customer",
        image: [
          {
            value: imageDatacustomer,
            fileName: currentFilecustomer?.name,
            type: currentFilecustomer?.extension?.split(".").pop(),
          },
        ],
      },
      {
        docUploadGuid: currentFilestaff?.uid,
        docUploadRefType: "Sign",
        docUploadRefNo: contractId,
        docUploadRefSubType: "witness1",
        image: [
          {
            value: imageDatav1,
            fileName: currentFilev1?.name,
            type: currentFilev1?.extension?.split(".").pop(),
          },
        ],
      },
      {
        docUploadGuid: currentFilestaff?.uid,
        docUploadRefType: "Sign",
        docUploadRefNo: contractId,
        docUploadRefSubType: "witness2",
        image: [
          {
            value: imageDatav2,
            fileName: currentFilev2?.name,
            type: currentFilev2?.extension?.split(".").pop(),
          },
        ],
      },
    ];

    console.log(uploadData, "sdfghjkjwyofgwau");
    try {
      const response = await axios.post(
        `${baseUrl}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        statusDialogSet(true);
        setLoader(false);
        alertSet("Contract created successfully");
      } else {
        console.log("else upload");
        setLoader(false);
      }
    } catch (err) {
      console.log(err);
      setLoader(false);
    }
  };

  const navigateBack = () => {
    if (alertState === false) {
      navigate("/masters/contractmaster");
    }
    statusDialogSet(false);
  };

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loader === true ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div>
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs breadCrumbData={breadCrumbsData} />
              </div>
              <hr className="hr-border" />
            </div>
          </div>
          <div className="overall-view">
            {/* Detail Screen*/}
            <div className="form-row-create">
              <div className="form-group">
                <Label>User Name</Label>
                <DropDownList
                  className="book-dropdown-new"
                  data={users}
                  disabled={view}
                  textField="Name"
                  value={selectUser}
                  onChange={(event) => {
                    bookingIdSet(undefined);
                    setInvoiceEnabler(false);
                    setSelectBookingId({
                      Id: 0,
                      bookingId: "Select Booking Id",
                    });
                    getinvoiceData(event.value);
                    setSelectUser(event.value);
                    console.log(event.value, "ertyukittyguuyutygigwetrb0q3t5");

                    fetchUserBooking(event.value.Id);
                    setBookingIdEnabler(true);
                    setDetailsEnabler(false);
                  }}
                  name="Name"
                />
              </div>

              {bookingIdEnabler === true ? (
                <div
                  className="form-group"
                  style={{ padding: "0px 10px 0px 0px" }}
                >
                  <Label>Booking ID</Label>
                  {selectBookingId.bookingId !== undefined ? (
                    <div>
                      <DropDownList
                        className="book-dropdown-new"
                        data={bookingId}
                        disabled={view}
                        textField="bookingId"
                        value={selectBookingIds}
                        onChange={(event) => getBookingDetails(event)}
                        name="bookingId"
                      />
                    </div>
                  ) : (
                    <DropDownList
                      className="book-dropdown-new"
                      data={bookingId}
                      disabled={view}
                      textField="BookingId"
                      value={selectBookingId}
                      onChange={(event) => getBookingDetails(event)}
                      name="bookingID"
                    />
                  )}
                </div>
              ) : (
                <div></div>
              )}

              <div className="form-group">
                {invoiceidEnabler === true ? (
                  <div>
                    <Label>Invoice ID</Label>
                    <Input
                      type="text"
                      disabled={true}
                      name="percentageBooking"
                      className="book-dropdown-new"
                      value={invoiceNumber}
                      onChange={(e) => {
                        setInvoiceNumber(e.value);
                      }}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
              <div className="form-group"></div>
            </div>
            <div>
              {detailsEnabler === true ? (
                <div>
                  <div className="form-row-create">
                    <div className="form-group" style={{}}>
                      <Label>Staff Name</Label>
                      <Input
                        type="text"
                        name="percentageBooking"
                        className="book-dropdown-new"
                        placeholder="Enter Staff Name"
                        value={staffName}
                        disabled={view}
                        maxLength={25}
                        onChange={(e) => {
                          if (/^[a-zA-Z\s]*$/.test(e.value)) {
                            setStaffName(e.value);
                          }
                        }}
                      ></Input>
                      {staffNameError && (
                        <span style={{ color: "red" }} className="error">
                          {staffNameError}
                        </span>
                      )}
                    </div>
                    <div className="form-group" style={{}}>
                      <Label>Staff Designation</Label>
                      <Input
                        type="text"
                        name="percentageBooking"
                        className="book-dropdown-new"
                        placeholder="Enter Staff Designation"
                        value={staffDes}
                        disabled={view}
                        maxLength={40}
                        onChange={(e) => {
                          if (/^[a-zA-Z\s]*$/.test(e.value)) {
                            setStaffDes(e.value);
                          }
                        }}
                      ></Input>
                      {staffDesError && (
                        <span style={{ color: "red" }} className="error">
                          {staffDesError}
                        </span>
                      )}
                    </div>
                    <div className="form-group" style={{}}></div>
                  </div>
                  <hr className="hr-border" />
                  <div style={{ backgroundColor: "#eef3f2" }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div
                        className="booking-details"
                        style={{ padding: "10px", width: "50%" }}
                      >
                        <h4>Space Booked</h4>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",

                            width: "100%",
                            padding: "20px",
                          }}
                        >
                          <div className="form-row-create">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Space Booked</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {BookingDetails?.SpaceBooked}
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Booking Date</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {BookingDetails?.BookingDate !== null
                                    ? `${formatDateDMY(
                                        `${BookingDetails?.BookingDate}`
                                      )}`
                                    : ""}
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Duration</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {BookingDetails?.Duration}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Deposit</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {BookingDetails?.Deposit}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Total Cost</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {BookingDetails?.TotalCost}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={{
                          width: "1px",
                          backgroundColor: "grey",
                          color: "black",
                        }}
                      >
                        {"."}
                      </div>

                      <div
                        className="booking-details"
                        style={{ padding: "10px", width: "50%" }}
                      >
                        <h4>Customer Details</h4>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",

                            width: "100%",
                            padding: "20px",
                          }}
                        >
                          <div className="form-row-create">
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Name</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {" "}
                                  {customerDetails?.Name}
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Mobile</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {customerDetails?.Mobile}
                                </div>
                              </div>
                            </div>

                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Email</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {customerDetails?.Email === null
                                    ? ""
                                    : customerDetails?.Email}
                                </div>
                              </div>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="form-group">
                                <div>Address</div>

                                <div style={{ fontWeight: "bold" }}>
                                  {customerDetails?.Address}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="form-row-create">
                    <div className="form-group" style={{}}>
                      <Label>
                        Booking Start Date{" "}
                        <span style={{ color: "red" }}>*</span>
                      </Label>
                      <style>{customStyles}</style>
                      <div className="no-wheel" style={{ width: "100%" }}>
                        <MyDatePicker
                          minDate={new Date()} // Start date can't be in the past
                          checkInDate={new Date(BookingDetails?.BookingFrom!)}
                          isDisableCheck={true}
                          customFormatter={false}
                          setCheckInDate={(e: any) => {
                            handleStartDateChange(e);
                          }}
                        />
                      </div>

                      {errorstartDate && (
                        <p style={{ color: "red" }}>{errorstartDate}</p>
                      )}
                    </div>
                    <div className="form-group" style={{}}>
                      <Label>
                        Booking End Date <span style={{ color: "red" }}>*</span>
                      </Label>
                      <style>{customStyles}</style>
                      <div className="no-wheel" style={{ width: "100%" }}>
                        <MyDatePicker
                          minDate={startDate || new Date()} // End date must be >= start date
                          checkInDate={new Date(BookingDetails?.BookingTo!)}
                          isDisableCheck={true}
                          customFormatter={false}
                          setCheckInDate={(e: any) => {
                            handleEndDateChange(e);
                          }}
                        />
                      </div>

                      {errorDate && <p style={{ color: "red" }}>{errorDate}</p>}
                    </div>
                    <div className="form-group" style={{}}></div>
                  </div>

                  <div className="form-row-create">
                    <div className="form-group" style={{}}></div>
                  </div>

                  {/* <div className="form-row-create">
                      <div className="form-group" style={{}}>
                        <Label>Terms and Conditions</Label>
                        <DropDownList
                          className="book-dropdown-new"
                          data={termsOptions}
                          value={selectedOption}
                          onChange={(e) => setSelectedOption(e.value)}
                          style={{ width: "300px", marginBottom: "20px" }}
                        />
                      </div>
                    </div> */}

                  <div style={{ padding: "20px" }}>
                    {/* <Label>Addendum</Label>
                      <Editor
                        tools={[
                          [Bold, Italic, Underline, Strikethrough],
                          [OrderedList, UnorderedList, Indent, Outdent],
                          [AlignLeft, AlignCenter, AlignRight, AlignJustify],
                        ]}
                        contentStyle={{ height: 200 }}
                        onChange={handleEditorChange}
                      /> */}
                    <div className="form-row-create">
                      <div className="form-group" style={{}}>
                        <h3 style={{ marginTop: "20px" }}>Staff Signature</h3>

                        <div>
                          {locations.state !== null ? (
                            img4 !== undefined ? (
                              <img
                                src={`${imageBaseUrl}${img1}`}
                                height={"120px"}
                              ></img>
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div>
                              {staffImageError && (
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {staffImageError}
                                </span>
                              )}

                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <h6>e-Sign by : </h6>
                                  <div style={{ width: "50px" }}></div>
                                  {/* <RadioButton
                                    name="group1"
                                    value={1}
                                    checked={selectedValuestaff === "1"}
                                    label="Saved Signs"
                                    onChange={(e) => {
                                      selectedValuestaffSet(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  /> */}
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group2"
                                    value={2}
                                    checked={selectedValuestaff === "2"}
                                    label="Upload Files"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      selectedValuestaffSet(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                      console.log(
                                        "Value changed from first to second"
                                      );
                                    }}
                                  />
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group3"
                                    value={3}
                                    checked={selectedValuestaff === "3"}
                                    label="Draw Signature"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      selectedValuestaffSet(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedValuestaff === "2" ? (
                                  <div>
                                    <Label>
                                      Upload File{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <ExternalDropZone
                                      className="field-space border-file-upload"
                                      uploadRef={uploadRefs}
                                      customHint={hint}
                                      customNote={note}
                                    />
                                    <div
                                      className="center-view"
                                      style={{ paddingTop: "10px" }}
                                    >
                                      <Upload
                                        ref={uploadRefs}
                                        restrictions={{
                                          maxFileSize: 2000000,
                                          allowedExtensions: [
                                            " .jpeg",
                                            ".png",
                                            ".jpg",
                                            ".pdf",
                                          ],
                                        }}
                                        batch={false}
                                        multiple={false}
                                        defaultFiles={[]}
                                        withCredentials={false}
                                        saveUrl={onSaveRequeststaff}
                                        removeUrl={onRemoveRequeststaff}
                                      />
                                      {errorimageMessage && (
                                        <div style={{ color: "red" }}>
                                          {errorimageMessage}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : selectedValuestaff === "3" ? (
                                  <div>
                                    <div className="example">
                                      <div className="content-wrapper">
                                        <ToolbarItem className="toolbar-tool">
                                          Upload your signature:
                                        </ToolbarItem>

                                        <div className="signature-wrapper">
                                          <Signature
                                            // height= {500}
                                            // width={500}
                                            value={value}
                                            onChange={handleChangestaff}
                                            color={""}
                                            backgroundColor={""}
                                            strokeWidth={2}
                                            smooth={true}
                                            maximizable={true}
                                            hideLine={true}
                                          />
                                        </div>

                                        {/* <Toolbar>
                                  <Button
                                    icon="save"
                                    themeColor="primary"
                                    disabled={!value}
                                    onClick={onSave}
                                  >
                                    Save
                                  </Button>
            
                                  <Button onClick={onClear}>Clear</Button>
                                </Toolbar> */}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group" style={{}}>
                        <h3 style={{ marginTop: "20px" }}>
                          Customer Signature
                        </h3>
                        <div>
                          {locations.state !== null ? (
                            img2 !== undefined ? (
                              <img
                                src={`${imageBaseUrl}${img2}`}
                                height={"120px"}
                              ></img>
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div>
                              {customerImageError && (
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {customerImageError}
                                </span>
                              )}
                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <h6>e-Sign by : </h6>
                                  <div style={{ width: "50px" }}></div>
                                  {/* <RadioButton
                                    name="group1"
                                    value={1}
                                    checked={selectedValuecustomer === "1"}
                                    label="Saved Signs"
                                    onChange={(e) => {
                                      selectedValuecustomerSet(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  /> */}
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group2"
                                    value={2}
                                    checked={selectedValuecustomer === "2"}
                                    label="Upload Files"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      selectedValuecustomerSet(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                      console.log(
                                        "Value changed from first to second"
                                      );
                                    }}
                                  />
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group3"
                                    value={3}
                                    checked={selectedValuecustomer === "3"}
                                    label="Draw Signature"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      selectedValuecustomerSet(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedValuecustomer === "2" ? (
                                  <div>
                                    <Label>
                                      Upload File{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <ExternalDropZone
                                      className="field-space border-file-upload"
                                      uploadRef={uploadRefs}
                                      customHint={hint}
                                      customNote={note}
                                    />
                                    <div
                                      className="center-view"
                                      style={{ paddingTop: "10px" }}
                                    >
                                      <Upload
                                        ref={uploadRefs}
                                        restrictions={{
                                          maxFileSize: 2000000,
                                          allowedExtensions: [
                                            " .jpeg",
                                            ".png",
                                            ".jpg",
                                            ".pdf",
                                          ],
                                        }}
                                        batch={false}
                                        multiple={false}
                                        defaultFiles={[]}
                                        withCredentials={false}
                                        saveUrl={onSaveRequestCustomer}
                                        removeUrl={onRemoveRequestcustomer}
                                      />
                                      {errorimageMessage && (
                                        <div style={{ color: "red" }}>
                                          {errorimageMessage}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : selectedValuecustomer === "3" ? (
                                  <div>
                                    <div className="example">
                                      <div className="content-wrapper">
                                        <ToolbarItem className="toolbar-tool">
                                          Upload your signature:
                                        </ToolbarItem>

                                        <div className="signature-wrapper">
                                          <Signature
                                            // height= {500}
                                            // width={500}
                                            value={value}
                                            onChange={handleChangecustomer}
                                            color={""}
                                            backgroundColor={""}
                                            strokeWidth={2}
                                            smooth={true}
                                            maximizable={true}
                                            hideLine={true}
                                          />
                                        </div>

                                        {/* <Toolbar>
                                  <Button
                                    icon="save"
                                    themeColor="primary"
                                    disabled={!value}
                                    onClick={onSave}
                                  >
                                    Save
                                  </Button>
            
                                  <Button onClick={onClear}>Clear</Button>
                                </Toolbar> */}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="form-row-create">
                      <div className="form-group" style={{}}>
                        <h3 style={{ marginTop: "20px" }}>
                          Witness Signature 1
                        </h3>

                        <div>
                          {locations.state !== null ? (
                            img3 !== undefined ? (
                              <img
                                src={`${imageBaseUrl}${img3}`}
                                height={"120px"}
                              ></img>
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div>
                              {witness1Error && (
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {witness1Error}
                                </span>
                              )}

                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <h6>e-Sign by : </h6>
                                  <div style={{ width: "50px" }}></div>
                                  {/* <RadioButton
                                    name="group1"
                                    value={1}
                                    checked={selectedValuev1 === "1"}
                                    label="Saved Signs"
                                    onChange={(e) => {
                                      setSelectedValuev1(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  /> */}
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group2"
                                    value={2}
                                    checked={selectedValuev1 === "2"}
                                    label="Upload Files"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      setSelectedValuev1(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                      console.log(
                                        "Value changed from first to second"
                                      );
                                    }}
                                  />
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group3"
                                    value={3}
                                    checked={selectedValuev1 === "3"}
                                    label="Draw Signature"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      setSelectedValuev1(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedValuev1 === "2" ? (
                                  <div>
                                    <Label>
                                      Upload File{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <ExternalDropZone
                                      className="field-space border-file-upload"
                                      uploadRef={uploadRefs}
                                      customHint={hint}
                                      customNote={note}
                                    />
                                    <div
                                      className="center-view"
                                      style={{ paddingTop: "10px" }}
                                    >
                                      <Upload
                                        ref={uploadRefs}
                                        restrictions={{
                                          maxFileSize: 2000000,
                                          allowedExtensions: [
                                            " .jpeg",
                                            ".png",
                                            ".jpg",
                                            ".pdf",
                                          ],
                                        }}
                                        batch={false}
                                        multiple={false}
                                        defaultFiles={[]}
                                        withCredentials={false}
                                        saveUrl={onSaveRequestv1}
                                        removeUrl={onRemoveRequestv1}
                                      />
                                      {errorimageMessage && (
                                        <div style={{ color: "red" }}>
                                          {errorimageMessage}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : selectedValuev1 === "3" ? (
                                  <div>
                                    <div className="example">
                                      <div className="content-wrapper">
                                        <ToolbarItem className="toolbar-tool">
                                          Upload your signature:
                                        </ToolbarItem>

                                        <div className="signature-wrapper">
                                          <Signature
                                            // height= {500}
                                            // width={500}
                                            value={value}
                                            onChange={handleChangev1}
                                            color={""}
                                            backgroundColor={""}
                                            strokeWidth={2}
                                            smooth={true}
                                            maximizable={true}
                                            hideLine={true}
                                          />
                                        </div>

                                        {/* <Toolbar>
                                  <Button
                                    icon="save"
                                    themeColor="primary"
                                    disabled={!value}
                                    onClick={onSave}
                                  >
                                    Save
                                  </Button>
            
                                  <Button onClick={onClear}>Clear</Button>
                                </Toolbar> */}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="form-group" style={{}}>
                        <h3 style={{ marginTop: "20px" }}>
                          Witness Signature 2
                        </h3>

                        <div>
                          {locations.state !== null ? (
                            img1 !== undefined ? (
                              <img
                                src={`${imageBaseUrl}${img4}`}
                                height={"120px"}
                              ></img>
                            ) : (
                              <div></div>
                            )
                          ) : (
                            <div>
                              {witness2Error && (
                                <span
                                  style={{ color: "red" }}
                                  className="error"
                                >
                                  {witness2Error}
                                </span>
                              )}

                              <div>
                                <div
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                  }}
                                >
                                  <h6>e-Sign by : </h6>
                                  <div style={{ width: "50px" }}></div>
                                  {/* <RadioButton
                                    name="group1"
                                    value={1}
                                    checked={selectedValuev2 === "1"}
                                    label="Saved Signs"
                                    onChange={(e) => {
                                      selectedValuev2Set(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  /> */}
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group2"
                                    value={2}
                                    checked={selectedValuev2 === "2"}
                                    label="Upload Files"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      selectedValuev2Set(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                      console.log(
                                        "Value changed from first to second"
                                      );
                                    }}
                                  />
                                  <div style={{ width: "30px" }}></div>
                                  <RadioButton
                                    name="group3"
                                    value={3}
                                    checked={selectedValuev2 === "3"}
                                    label="Draw Signature"
                                    onChange={(e) => {
                                      console.log("Value changed -->", e.value);
                                      selectedValuev2Set(`${e.value}`);
                                      if (e.value === "") {
                                        newVarientErrorSet("select feild");
                                      } else {
                                        newVarientErrorSet("");
                                      }
                                    }}
                                  />
                                </div>
                              </div>
                              <div>
                                {selectedValuev2 === "2" ? (
                                  <div>
                                    <Label>
                                      Upload File{" "}
                                      <span style={{ color: "red" }}>*</span>
                                    </Label>
                                    <ExternalDropZone
                                      className="field-space border-file-upload"
                                      uploadRef={uploadRefs}
                                      customHint={hint}
                                      customNote={note}
                                    />
                                    <div
                                      className="center-view"
                                      style={{ paddingTop: "10px" }}
                                    >
                                      <Upload
                                        ref={uploadRefs}
                                        restrictions={{
                                          maxFileSize: 2000000,
                                          allowedExtensions: [
                                            " .jpeg",
                                            ".png",
                                            ".jpg",
                                            ".pdf",
                                          ],
                                        }}
                                        batch={false}
                                        multiple={false}
                                        defaultFiles={[]}
                                        withCredentials={false}
                                        saveUrl={onSaveRequestv2}
                                        removeUrl={onRemoveRequestv2}
                                      />
                                      {errorimageMessage && (
                                        <div style={{ color: "red" }}>
                                          {errorimageMessage}
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : selectedValuev2 === "3" ? (
                                  <div>
                                    <div className="example">
                                      <div className="content-wrapper">
                                        <ToolbarItem className="toolbar-tool">
                                          Upload your signature:
                                        </ToolbarItem>

                                        <div className="signature-wrapper">
                                          <Signature
                                            // height= {500}
                                            // width={500}
                                            value={value}
                                            onChange={handleChangev2}
                                            color={""}
                                            backgroundColor={""}
                                            strokeWidth={2}
                                            smooth={true}
                                            maximizable={true}
                                            hideLine={true}
                                          />
                                        </div>

                                        {/* <Toolbar>
                                  <Button
                                    icon="save"
                                    themeColor="primary"
                                    disabled={!value}
                                    onClick={onSave}
                                  >
                                    Save
                                  </Button>
            
                                  <Button onClick={onClear}>Clear</Button>
                                </Toolbar> */}
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>

                    <div
                      className="center-view"
                      style={{
                        paddingBottom: "50px",
                      }}
                    >
                      <div className="form-row-area">
                        <div className="form-group-area"></div>
                      </div>
                      <div className="button-group">
                        {view === true ? (
                          <div></div>
                        ) : (
                          // <Button className="button-view" onClick={() => {}}>
                          //   {loader === false ? "UPDATE" : "Loading..."}
                          // </Button>
                          <Button
                            className="button-view"
                            onClick={() => {
                              handleSubmit();
                            }}
                          >
                            {loader === false ? "CREATE" : "Loading..."}
                          </Button>
                        )}
                        <Button
                          // type="submit"
                          className="common-button-view"
                          onClick={() => {
                            navigate("/masters/contractmaster");
                          }}
                        >
                          CANCEL
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
            </div>
            {statusDialog && (
              <Dialog>
                <div
                  style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignContent: "center",
                  }}
                >
                  <h3>{alert}</h3>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingTop: "30px",
                    }}
                  >
                    <Button
                      className="button-view"
                      onClick={() => navigateBack()}
                    >
                      Ok
                    </Button>
                  </div>
                </div>
              </Dialog>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const dataURLtoBlob = (dataurl: string): Blob => {
  if (!dataurl) {
    throw new Error("Invalid data URL");
  }

  const arr = dataurl.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1]; // Safely match MIME type
  if (!mime) {
    throw new Error("Invalid MIME type");
  }

  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
};
