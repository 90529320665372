import * as React from "react";
import { TabStrip, TabStripTab } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import "../cafeteria/Orders.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import { useEffect, useState } from "react";
import {
  checkIcon,
  pencilIcon,
  trashIcon,
  xIcon,
} from "@progress/kendo-svg-icons";
import axios from "axios";
import { commonBaseUrl } from "../../services/CommonUtil";

const Orders = () => {
  const baseURL = commonBaseUrl();
  // Example data for active orders
  const ordersData = [
    {
      orderNo: "001",
      status: "New",
      items: "2 Tea, 1 Water Bottle",
      customer: "ABC Corp",
    },
    {
      orderNo: "002",
      status: "In Progress",
      items: "2 Coffee",
      customer: "XYZ Corp",
    },
  ];
  const [selectedTab, setSelectedTab] = useState(0);
  const handleTabSelect = (e: any) => {
    console.log("Selected value", e.selected);
    let value = e.selected + 1;
    if (e.selected === 2) {
      value = 4;
    }
    setSelectedTab(e.selected);
    console.log("Selected status value", value);
    statusIdSet(value);
  };
  const breadCrumbsData = [
    {
      id: "1",
      text: "Cafeteria Management",
      route: "/cafeteria/overview",
    },
    {
      id: "2",
      text: "Orders",
      //   route: "",
    },
  ];
  const [statusId, statusIdSet] = useState(1);
  const [newList, newListSet] = useState([]);
  const [progressList, progressListSet] = useState([]);
  const [completeList, completeListSet] = useState([]);
  const [cancelList, cancelListSet] = useState([]);

  useEffect(() => {
    console.log("Status id", statusId);
    GetOrderList(statusId);
  }, [statusId]);
  const GetOrderList = async (id: any) => {
    // console.log("The updated time is ==>", fromDate , "==>", toDate);
    console.log(`${baseURL}odata/GetAllFoodOrderListByStatusId?statusId=${id}`);
    try {
      const response = await axios.get(
        `${baseURL}GetAllFoodOrderListByStatusId?statusId=${id}`
      );
      console.log("Fetched Data", response.data);
      let list = response.data;
      console.log("The updated list was", list);
      for (let i = 0; i < list.length; i++) {
        list[i].sno = i + 1;
      }
      if (id === 1) {
        newListSet(list);
      } else if (id === 2) {
        progressListSet(list);
      } else if (id === 3) {
        cancelListSet(list);
      } else {
        completeListSet(list);
      }
    } catch (err: any) {
      console.log("error", err.response.data.detail);
    }
  };
  const CustomItemsOrderedCell = (props: any) => {
    const { itemsOrdered } = props.dataItem;

    // Concatenating the itemsOrdered into a single string
    const formattedItems = itemsOrdered
      .map((item: any) => `${item.quantity} * ${item.itemName}`)
      .join(", ");

    return <td>{formattedItems}</td>;
  };
  const updateStatusApi = async (id: any, status: any) => {
    try {
      const response = await axios.post(
        `${baseURL}odata/UpdateFoodOrderStatus?foodId=${id}&statusId=${status}`
      );
      console.log("Fetched Data", response.data);
      GetOrderList(statusId);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err.response.data.detail);
    }
  };
  return (
    <div className="orders-container">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
      </div>
      <TabStrip selected={selectedTab} onSelect={handleTabSelect}>
        <TabStripTab title={`New`}>
          <Grid data={newList}>
            <GridColumn field="sno" title="Order No" />
            <GridColumn
              field="itemsOrdered"
              title="Items Ordered"
              cell={CustomItemsOrderedCell}
            />
            <GridColumn
              field="userName"
              title="Customer"
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "left",
                  }}
                >
                  {props.dataItem.userName}
                </td>
              )}
            />
            <GridColumn field="orderStatus" title="Status" />
            <GridColumn
              field="action"
              title="Actions"
              cell={(props) => (
                <td>
                  <div>
                    <Button
                      className="edit-color"
                      onClick={() => {
                        // GetOrderList(1);
                        updateStatusApi(props.dataItem.id, 2);
                      }}
                      svgIcon={checkIcon}
                    />
                  </div>
                </td>
              )}
            />
          </Grid>
        </TabStripTab>
        <TabStripTab title={`In-Progress`}>
          <Grid data={progressList}>
            <GridColumn field="sno" title="Order No" />
            <GridColumn
              field="itemsOrdered"
              title="Items Ordered"
              cell={CustomItemsOrderedCell}
            />
            <GridColumn
              field="userName"
              title="Customer"
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "left",
                  }}
                >
                  {props.dataItem.userName}
                </td>
              )}
            />
            <GridColumn field="orderStatus" title="Status" />
            <GridColumn
              field="action"
              title="Actions"
              cell={(props) => (
                <td>
                  <div>
                    <Button
                      className="edit-color"
                      onClick={() => {
                        // GetOrderList(2);
                        updateStatusApi(props.dataItem.id, 4);
                      }}
                      svgIcon={checkIcon}
                    />
                  </div>
                </td>
              )}
            />
          </Grid>
        </TabStripTab>
        {/* <TabStripTab title={`Cancelled`}>
                <Grid data={cancelList}>
                        <GridColumn field="sno" title="Order No" />
                        <GridColumn field="itemsOrdered" title="Items Ordered"
                            cell={CustomItemsOrderedCell}
                        />
                        <GridColumn field="userName" title="Customer" />
                        <GridColumn field="orderStatus" title="Status" />
                    </Grid>
                </TabStripTab> */}
        <TabStripTab title={`Completed`}>
          <Grid data={completeList}>
            <GridColumn field="sno" title="Order No" />
            <GridColumn
              field="itemsOrdered"
              title="Items Ordered"
              cell={CustomItemsOrderedCell}
            />
            <GridColumn
              field="userName"
              title="Customer"
              cell={(props) => (
                <td
                  style={{
                    height: "75px",
                    textAlign: "left",
                  }}
                >
                  {props.dataItem.userName}
                </td>
              )}
            />
            <GridColumn field="orderStatus" title="Status" />
          </Grid>
        </TabStripTab>
      </TabStrip>
    </div>
  );
};

export default Orders;
