import * as React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import {
  JapaneseFood,
  ItalianFood,
  Travel,
  Europe,
  NorthAmerica,
} from "./Content.jsx";
import { SignIn } from "./pages/SignIn.jsx";
import { SignUp } from "./pages/SignUp.jsx";
import { Home } from "./pages/Home.jsx";
import { Dashboard } from "./pages/Dashboard";
import { Duration } from "./pages/masters/Duration";
import { Packages } from "./pages/masters/Packages";
import { RoomMaster } from "./pages/masters/RoomMaster";
import { CreateRoom } from "./pages/masters/CreateRoom";
import { BookingForm } from "./pages/management/EditInfo";
import { BookingManagement } from "./pages/management/BookingList";
import { DrawerContainer } from "./components/DrawerContainer.jsx";
import "./styles.css";
import { Discount } from "./pages/masters/Discount";
import { Amenities } from "./pages/masters/Amenities";
import { CreateDiscount } from "./pages/masters/CreateDiscount";
import { InvoiceDashboard } from "./pages/masters/invoice/invoice.tsx";
import { CreateInvoice } from "./pages/masters/invoice/createInvoice.tsx";
import { EditDiscount } from "./pages/masters/EditDiscount";
import { UpdateInvoice } from "./pages/masters/invoice/updateInvoice.tsx";
import { DeviceConfiguration } from "./pages/biodevice/DeviceConfiguration.tsx";
import { UserEnrollment } from "./pages/biodevice/UserEnrollment.tsx";
import { AccessPermission } from "./pages/biodevice/AccessPermission.tsx";
import { AuditLogs } from "./pages/biodevice/AuditLogs.tsx";
import { AddDevice } from "./pages/biodevice/AddDevice.tsx";
import CafeteriaOverview from "./pages/cafeteria/Overview.tsx";
import Orders from "./pages/cafeteria/Orders.tsx";
import { ContractMasterList } from "./pages/masters/contractmaster/ContractMasterList.tsx";
import { EditContractMasterList } from "./pages/masters/contractmaster/EditContractMasterList.tsx";
import Categories from "./pages/cafeteria/categories.tsx";
import SubCategory from "./pages/cafeteria/SubCategory.tsx";
import SubCategoryDetails from "./pages/cafeteria/SubCategoryInfo.tsx";
import UpdateSubCategoryDetails from "./pages/cafeteria/subCategoryUpdate.tsx";
import { BookingSummary } from "./pages/report/BookingSummary.tsx";
import { UserActivity } from "./pages/report/UserActivity.tsx";
import { Revenue } from "./pages/report/Revenue.tsx";
import PackageUtilization from "./pages/management/PackageUtilization.tsx";
import { ViewBookingDetails } from "./pages/report/ViewBookingDetails.tsx";
import { ViewPaymentDetails } from "./pages/report/ViewPaymentDetails.tsx";
import { UserActivitySummary } from "./pages/report/UserActivitySummary.tsx";
import PackgaeInfo from "./pages/masters/PackageInfo.tsx";
import PackageEdit from "./pages/masters/PackageEdit.tsx";
const App = () => {
  const location = useLocation();
  const isAuthRoute =
    location.pathname === "/" || location.pathname === "/signup";
  return (
    <>
      <Routes>
        {isAuthRoute ? (
          <>
            <Route path="/" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
          </>
        ) : (
          <Route
            path="*"
            element={
              <DrawerContainer>
                <Routes>
                  <Route path="/home" element={<Home />} />
                  <Route path="/home/dashboard" element={<Dashboard />} />
                  <Route path="/food/japanese" element={<JapaneseFood />} />
                  <Route path="/food/italian" element={<ItalianFood />} />
                  <Route path="/travel" element={<Travel />} />
                  <Route path="/travel/europe" element={<Europe />} />
                  <Route path="/travel/america" element={<NorthAmerica />} />
                  <Route path="/masters/duration" element={<Duration />} />
                  <Route path="/masters/package" element={<Packages />} />
                  <Route path="/masters/room" element={<RoomMaster />} />
                  <Route path="/masters/discount" element={<Discount />} />
                  <Route path="/masters/amenities" element={<Amenities />} />
                  <Route
                    path="/cafeteria/overview"
                    element={<CafeteriaOverview />}
                  />
                  <Route path="/cafeteria/orders" element={<Orders />} />
                  <Route
                    path="/cafeteria/categories"
                    element={<Categories />}
                  />
                  <Route
                    path="/cafeteria/subcategories"
                    element={<SubCategory />}
                  />
                  <Route
                    path="/cafeteria/subcategories/details"
                    element={<SubCategoryDetails />}
                  />
                  <Route
                    path="/cafeteria/subcategories/update"
                    element={<UpdateSubCategoryDetails />}
                  />
                  <Route
                    path="/masters/discount/creatediscount"
                    element={<CreateDiscount />}
                  />
                  <Route
                    path="/masters/discount/editdiscount"
                    element={<EditDiscount />}
                  />
                  <Route
                    path="/masters/contractmaster"
                    element={<ContractMasterList />}
                  />
                  <Route
                    path="/masters/editcontractmaster"
                    element={<EditContractMasterList />}
                  />
                  <Route
                    path="/masters/room/createroom"
                    element={<CreateRoom />}
                  />
                  <Route
                    path="/management/editbooking"
                    element={<BookingForm />}
                  />
                  <Route
                    path="/management/listbooking"
                    element={<BookingManagement />}
                  />
                  <Route
                    path="/management/utilizepackage"
                    element={<PackageUtilization />}
                  />
                  <Route
                    path="/management/listbooking/invoice"
                    element={<InvoiceDashboard />}
                  />
                  <Route
                    path="/management/listbooking/invoice/create"
                    element={<CreateInvoice />}
                  />{" "}
                  <Route
                    path="/management/listbooking/invoice/update"
                    element={<UpdateInvoice />}
                  />
                  <Route
                    path="/biodevice/deviceconfig"
                    element={<DeviceConfiguration />}
                  />
                  <Route
                    path="/biodevice/deviceconfig/add"
                    element={<AddDevice />}
                  />
                  <Route
                    path="/biodevice/enrollment"
                    element={<UserEnrollment />}
                  />
                  <Route
                    path="/biodevice/access"
                    element={<AccessPermission />}
                  />
                  <Route path="/biodevice/logs" element={<AuditLogs />} />
                  <Route
                    path="/report/bookingsummary"
                    element={<BookingSummary />}
                  />
                  <Route
                    path="/report/useractivity"
                    element={<UserActivity />}
                  />
                  <Route path="/report/revenue" element={<Revenue />} />
                  <Route path="/viewbooking" element={<ViewBookingDetails />} />
                  <Route path="/viewpayment" element={<ViewPaymentDetails />} />
                  <Route path="/viewuser" element={<UserActivitySummary />} />
                  <Route path="/masters/packageinfo" element={<PackgaeInfo />} />
                  <Route path="/masters/packageedit" element={<PackageEdit />} />
                </Routes>
              </DrawerContainer>
            }
          />
        )}
      </Routes>
      {/* <Routes>
        <Route path="/" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
      </Routes>
      <DrawerContainer>
        <Routes>
          <Route path="/home" element={<Home />} />
          <Route path="/home/dashboard" element={<Dashboard />} />
          <Route path="/food/japanese" element={<JapaneseFood />} />
          <Route path="/food/italian" element={<ItalianFood />} />
          <Route path="/travel" element={<Travel />} />
          <Route path="/travel/europe" element={<Europe />} />
          <Route path="/travel/america" element={<NorthAmerica />} />
          <Route path="/masters/duration" element={<Duration />} />
          <Route path="/masters/package" element={<Packages />} />
          <Route path="/masters/room" element={<RoomMaster />} />
          <Route path="/masters/room/createroom" element={<CreateRoom />} />
          <Route path="/management/editbooking" element={<BookingForm />} />
          <Route
            path="/management/listbooking"
            element={<BookingManagement />}
          />
        </Routes>
      </DrawerContainer> */}
    </>
  );
};
export default App;
