import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Breadcrumbs from "../components/Breadcrumbs";
import { commonBaseUrl, commonImageUrl } from "../../services/CommonUtil";
import { Button } from "@progress/kendo-react-buttons";
import { Input, TextArea } from "@progress/kendo-react-inputs";
import { downloadIcon, eyeIcon } from "@progress/kendo-svg-icons";
import fileDownload from "js-file-download";
import axios from "axios";
import "../css/EditInfo.scss";
import "../css/Common.scss";

const baseURL = commonBaseUrl();
const imageURL = commonImageUrl();

export const UserActivitySummary = () => {
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const { state: { infoId, view } = {} } = useLocation();
  const [editFormData, setEditFormData] = useState(infoId);
  const [selectedKyc, setSelectedKyc] = useState("");
  const CustomeTypeFeildValue =
    Number(infoId.CustomerType) === 1 ? "Company" : "Individual";
  const KYCTypeFeildValue = infoId.KycDocumentId;
  const [fileName, setFileName] = useState("");
  var documents;

  const breadCrumbsData = [
    {
      id: "1",
      text: "Reports",
      route: "",
    },
    {
      id: "2",
      text: "User Activity Report",
      route: "/report/useractivity",
    },
    {
      id: "3",
      text: "User Summary",
      route: "",
    },
  ];

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    KycApi();
  });

  const KycApi = async () => {
    try {
      const response = await axios.get(`${baseURL}odata/KycDocuments`);
      DocumentApi();
      for (let d = 0; d < response.data.value.length; d++) {
        if (KYCTypeFeildValue === response.data.value[d].Id) {
          setSelectedKyc(response.data.value[d].Name);
          break;
        }
      }
    } catch (err) {}
  };

  const formatFileName = (fileName: string, fileType: string) => {
    const baseName = fileName + "." + fileType;
    return `${baseName}`;
  };

  const DocumentApi = async () => {
    try {
      const response = await axios.get(
        `${baseURL}odata/DocumentUploads?$filter=refno eq ${infoId.Id} and contains(reftype, 'User')`
      );
      console.log(response, "<image<<");
      let documentation = response.data.value;
      documents = response.data.value[documentation.length - 1];
      setdocument(response.data.value);
      setFileName(formatFileName(documents.Name, documents.Type));
    } catch (err) {}
  };

  const handlePreview = (url: any) => {
    window.open(`${imageURL}` + url, "_blank");
  };

  const handleDownload = (url: any, filename: any) => {
    axios
      .get(url, {
        responseType: "blob",
      })
      .then((res) => {
        fileDownload(
          res.data,
          filename + "." + document[document.length - 1]["Type"]
        );
      });
  };

  const [document, setdocument] = useState([]);
  function onClickdata(url: any, filename: any) {
    handleDownload(`${imageURL}` + url, filename);
  }

  return (
    <div>
      <div className="overall-view">
        <div>
          <div className="dashboard-button">
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
          </div>
          <hr className="hr-border" />
        </div>
      </div>
      <div className="overall-view ">
        <div className="common-margina edit-booking-margin">
          <form className="editForm">
            <div className="form-row-edit">
              <div className="form-group">
                <label>Customer ID</label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="CustomerId"
                  value={editFormData.CustomerId}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <label>Customer Type</label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="bookingid"
                  value={CustomeTypeFeildValue}
                  readOnly={true}
                />
              </div>
              {CustomeTypeFeildValue === "Company" ? (
                <div className="form-group">
                  <label>Company Name</label>
                  <Input
                    className="book-input-view book-height-edit disable-fields"
                    name="CompanyName"
                    value={editFormData.CompanyName}
                    readOnly={true}
                  />
                </div>
              ) : null}

              <div className="form-group">
                <label>Customer Name</label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="CustomerName"
                  value={`${editFormData.Name} ${editFormData.LastName}`}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <label>Contact Number</label>
                <Input
                  maxLength={10}
                  className="book-input-view book-height-edit disable-fields"
                  name="ContactNo"
                  value={editFormData.Mobile}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <label>Email Address</label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="Email"
                  readOnly={true}
                  value={editFormData.Email}
                />
              </div>
              <div className="form-group">
                <label>Address</label>
                <TextArea
                  className="book-input-view disable-fields"
                  name="Address"
                  rows={3}
                  readOnly={true}
                  value={editFormData.Address}
                />
              </div>
              <div className="form-group">
                <label>GST No</label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="GSTNumber"
                  readOnly={true}
                  value={editFormData.GstNumber}
                />
              </div>
              <div className="form-group">
                <label>KYC Documents</label>
                <Input
                  className="book-input-view book-height-edit disable-fields"
                  name="bookingid"
                  value={selectedKyc}
                  readOnly={true}
                />
              </div>
              <div className="form-group">
                <div className="button-group-file">
                  <label>{fileName}</label>
                  <Button
                    className="eye-icon"
                    type="button"
                    svgIcon={eyeIcon}
                    onClick={() =>
                      handlePreview(document[document.length - 1]["ImagePath"])
                    }
                  ></Button>

                  <Button
                    className="download-icon"
                    type="button"
                    svgIcon={downloadIcon}
                    onClick={() =>
                      onClickdata(
                        document[document.length - 1]["ImagePath"],
                        document[document.length - 1]["Name"]
                      )
                    }
                  ></Button>
                </div>
              </div>
            </div>
            <div className="button-group">
              <Button
                type="submit"
                className="common-button-view"
                onClick={(e) => navigate("/report/useractivity")}
              >
                CANCEL
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
