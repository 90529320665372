import React, { useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { Input, TextBox } from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import {
  pencilIcon,
  checkboxCheckedIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { Error } from "@progress/kendo-react-labels";
import { CollectionConfigurationComponent } from "@progress/kendo-react-charts";
import { commonBaseUrl } from "../../services/CommonUtil";
import { useNavigate } from "react-router-dom";
const inclusions = ["Inclusion 1", "Inclusion 2", "Inclusion 3"];
const exclusions = ["Exclusions 1", "Exclusions 2", "Exclusions 3"];
const defaultInclusionItem = "Choose Inclusion";
const defaultInclusionItems = { Id: null, Name: "Choose Inclusion" };
const defaultExclusionItems = { Id: null, Name: "Choose Exclusion" };
const defaultExclusionItem = "Choose Exclusion";

export const Packages = () => {
  const baseURL = commonBaseUrl();
  const [packages, setPackages] = useState([
    {
      id: 1,
      package: "package1",
      inclusion: "Electricity Charges Dedicated desk us.",
      exclusion: "NA",
    },
    {
      id: 2,
      package: "package2",
      inclusion: "NA",
      exclusion: "Electricity Charges not included in this desk.",
    },
  ]);
  const initialDataState: State = {
    take: 5,
    skip: 0,
    group: [],
  };

  const [newPackage, setNewPackage] = useState("");
  const [newInclusion, setNewInclusion] = useState(defaultInclusionItems);
  const [newInclusionList, setNewInclusionList] = useState<any>([]);
  const [newExclusionList, setNewExclusionList] = useState<any>([]);
  const [newExclusion, setNewExclusion] = useState(defaultExclusionItems);
  const [editID, setEditID] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState<State>(initialDataState);

  const clearInput = () => {
    newCheckSet(false);
    checkSet(false);
    setNewPackage("");
    newPackageErrorSet("");
    newExclusionErrorSet("");
    newInclusionErrorSet("");
    setNewInclusionList([]);
    setNewExclusionList([]);
    packageNameSet("");
    packageInclusionSet([]);
    packageExclusionSet([]);
    packageErrorSet("");
    inclusionErrorSet("");
    exclusionErrorSet("");
    setNewInclusion(defaultInclusionItems);
    setNewExclusion(defaultExclusionItems);
  };

  const updatePackage = (
    id: any,
    name: any,
    inclusion: any,
    exclusion: any
  ) => {
    setPackages(
      packageList.map((p: any) =>
        p.Id === id ? { ...p, name, inclusion, exclusion } : p
      )
    );
    setEditID(null);
  };

  const deletePackage = (id: any) => {
    setPackages(packageList.filter((p: any) => p.Id !== id));
    setDeleteID(null);
  };

  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = packageList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Packages",
      route: "",
    },
  ];
  const [packageList, setPackageList] = useState<any>([]);
  const [amenityList, amenityListSet] = useState<any>([]);
  const [tempAmenityList, setTempAmenityList] = useState<any>([]);
  const [firsttime, setFirstTime] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(packageList);
  useEffect(() => {
    const PackageApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/PackageDetails`);
        const tempList = response.data.value;
        for (let ad = 0; ad < tempList.length; ad++) {
          tempList[ad].sno = ad + 1;
        }
        setPackageList(tempList);
      } catch (err) {}
    };
    const AmenityApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/Amenitys`);
        amenityListSet(response.data.value);
        setTempAmenityList(response.data.value);
      } catch (err) {}
    };
    if (firsttime === true) {
      console.log("Called");
      PackageApi();
      AmenityApi();
      setFirstTime(false);
    }
  });
  const [newPackageError, newPackageErrorSet] = useState("");
  const [newInclusionError, newInclusionErrorSet] = useState("");
  const [newExclusionError, newExclusionErrorSet] = useState("");
  const [newCheck, newCheckSet] = useState(false);

  const addPackageApi = async (data: any) => {
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(`${baseURL}odata/PackageDetails`, data);
      console.log("Package Added", response.data);
      alertSet("Package added successfully");
      statusDialogSet(true);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };

  const addPackage = () => {
    newCheckSet(true);
    if (
      newPackage.trim() !== "" &&
      newInclusionList.length !== 0 &&
      newExclusionList.length !== 0
    ) {
      let add = {
        PackageName: newPackage,
        Inclusion: newCollectInclusion,
        Exclusion: newCollectExclusion,
      };
      console.log("Ready for api", add);
      addPackageApi(add);
      //API Calling
    }
    if (newPackage.trim() === "") {
      newPackageErrorSet("Package name must be provide");
    } else {
      newPackageErrorSet("");
    }
    if (newInclusionList.length === 0) {
      newInclusionErrorSet("Choose any Inclusion from the list");
    } else {
      newInclusionErrorSet("");
    }
    if (newExclusionList.length === 0) {
      newExclusionErrorSet("Choose any Exclusion from the list");
    } else {
      newExclusionErrorSet("");
    }
  };
  const [inclusionError, inclusionErrorSet] = useState("");
  const [exclusionError, exclusionErrorSet] = useState("");
  const [packageId, packageIdSet] = useState(0);
  const [packageName, packageNameSet] = useState("");
  const [packageError, packageErrorSet] = useState("");
  const [packageInclusion, packageInclusionSet] = useState<any>([]);
  const [packageExclusion, packageExclusionSet] = useState<any>([]);
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [check, checkSet] = useState(false);

  // Extract the selected inclusions from the collectedInclusion string
  const extractSelectedInclusions = (collectedInclusion: any) => {
    // Convert the collectedInclusion string back to an array of names
    const inclusionNames =
      collectedInclusion !== null ? collectedInclusion.split(",") : [];

    // Find the matching amenities by name
    const selectedInclusions = amenityList.filter((amenity: any) =>
      inclusionNames.includes(amenity.Name)
    );

    return selectedInclusions;
  };
  const enableField = async(id: any) => {
    // setEdit(true);
    // setEditID(id);
    console.log("The given data is", id);
    navigate("/masters/packageedit", {
      state: {Id: id},
    });
    // packageIdSet(id.Id);
    // packageNameSet(id.PackageName);
    // console.log("ids -->", id.Inclusion);
    // packageInclusionSet(extractSelectedInclusions(id.Inclusion));
    // collectInclusionSet(id.Inclusion);
    // console.log("Inclusion set -->", extractSelectedInclusions(id.Inclusion));
    // packageExclusionSet(extractSelectedInclusions(id.Exclusion));
    // collectExclusionSet(id.Exclusion);
    // console.log("Exclusion set -->", extractSelectedInclusions(id.Exclusion));
    // console.log("EditEnableId", id);
    // console.log("amenity List", amenityList);
    // setVisibleDialog(true);
  };

  const handleView = () => {
    checkSet(true);
    if (packageName.trim() !== "") {
      packageErrorSet("");
    } else {
      packageErrorSet("Package Name must be provide");
    }
    if (packageInclusion.length !== 0) {
      inclusionErrorSet("");
    } else {
      inclusionErrorSet("Choose any Inclusion");
    }
    if (packageExclusion.length !== 0) {
      exclusionErrorSet("");
    } else {
      exclusionErrorSet("Choose any Exclusion");
    }
    if (
      packageName.trim() !== "" &&
      packageInclusion.length !== 0 &&
      packageExclusion.length !== 0
    ) {
      let update = {
        id: packageId,
        packageName: packageName,
        inclusion: collectInclusion,
        exclusion: collectExclusion,
      };
      console.log("The update package variables are -->", update);
      updatePackageAPi(update);
    }
  };
  const updatePackageAPi = async (update: any) => {
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(
        `${baseURL}odata/UpdatePackage?key=${packageId}`,
        update
      );
      console.log("Package Updated", response.data);
      packageErrorSet("");
      alertSet("Package updated successfully");
      statusDialogSet(true);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };

  const inclusionDatum = amenityList.map(
    (amenity: any) => ({
      ...amenity,
      disabled: newExclusionList.some(
        (inclusionItem: any) => inclusionItem.Id === amenity.Id
      ),
    }),
    [newExclusionList]
  );
  const exclusionDatum = amenityList.map(
    (amenity: any) => ({
      ...amenity,
      disabled: newInclusionList.some(
        (inclusionItem: any) => inclusionItem.Id === amenity.Id
      ),
    }),
    [newInclusionList]
  );

  const exclusionDatumUpdate = amenityList.map(
    (amenity: any) => ({
      ...amenity,
      disabled: packageInclusion.some(
        (inclusionItem: any) => inclusionItem.Id === amenity.Id
      ),
    }),
    [packageInclusion]
  );
  const inclusionDatumUpdate = amenityList.map(
    (amenity: any) => ({
      ...amenity,
      disabled: packageExclusion.some(
        (inclusionItem: any) => inclusionItem.Id === amenity.Id
      ),
    }),
    [packageExclusion]
  );

  useEffect(() => {
    console.log("The new Data -->", inclusionDatumUpdate);
  }, [packageExclusion]);
  const [newCollectInclusion, newCollectInclusionSet] = useState("");
  const [newCollectExclusion, newCollectExclusionSet] = useState("");
  function handleInclusionNew(value: any) {
    const newInclusion = value;
    // Concatenate the selected inclusion IDs into a string
    var collectedIds = newInclusion.map((item: any) => item.Name).join(",");
    setNewInclusionList(value);
    // setNewExclusionList([]);
    console.log("The updated list", value);
    newCollectInclusionSet(collectedIds);
  }
  const [collectInclusion, collectInclusionSet] = useState("");
  function handleInclusion(value: any) {
    // const inclusion = value;
    // Concatenate the selected inclusion IDs into a string
    // var collectedIds = inclusion.map((item: any) => item.Name).join(",");
    packageInclusionSet(value);
    // packageExclusionSet([]);
    // console.log("The updated list", value);
    console.log("Update", formatInclusionId(value));
    collectInclusionSet(formatInclusionId(value));
  }
  const formatInclusionId = (list: any) => {
    const inclusion = list;
    var collectedIds = inclusion.map((item: any) => item.Name).join(",");
    // packageExclusionSet([]);
    console.log("The updated format ", list);
    console.log("The updated format ", collectedIds);
    return collectedIds;
    // collectInclusionSet(collectedIds);
  };
  const [collectExclusion, collectExclusionSet] = useState("");
  function handleExclusion(value: any) {
    const exclusion = value;
    // Concatenate the selected inclusion IDs into a string
    var collectedId = exclusion.map((item: any) => item.Name).join(",");
    packageExclusionSet(value);
    // setNewExclusionList([]);
    console.log("The updated list", value);
    collectExclusionSet(collectedId);
  }
  function handleExclusionNew(value: any) {
    const newExclusion = value;
    // Concatenate the selected inclusion IDs into a string
    var collectedId = newExclusion.map((item: any) => item.Name).join(",");
    setNewExclusionList(value);
    // setNewExclusionList([]);
    console.log("The updated list", value);
    newCollectExclusionSet(collectedId);
  }
  useEffect(() => {
    if (newCheck) {
      if (newExclusionList.length !== 0) {
        newExclusionErrorSet("");
      } else {
        newExclusionErrorSet("Choose any Exclusion from the list");
      }
      if (newInclusionList.length !== 0) {
        newInclusionErrorSet("");
      } else {
        newInclusionErrorSet("Choose any Inclusion from the list");
      }
      if (newPackage.trim() === "") {
        newPackageErrorSet("Package name must be provide");
      } else {
        newPackageErrorSet("");
      }
    }
    if (check) {
      console.log("Conditon true -->");
      if (packageName.trim() === "") {
        packageErrorSet("Package Name must be provide");
      } else {
        packageErrorSet("");
      }
      if (packageInclusion.length !== 0) {
        inclusionErrorSet("");
      } else {
        inclusionErrorSet("Choose any Inclusion");
      }
      if (packageExclusion.length !== 0) {
        exclusionErrorSet("");
      } else {
        exclusionErrorSet("Choose any Exclusion");
      }
    }
  }, [
    newExclusionList,
    newInclusionList,
    newPackage,
    packageName,
    packageInclusion,
    packageExclusion,
  ]);
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      setFilteredData(
        packageList.filter((item: any) =>
          item.PackageName.toLowerCase().includes(lowerCaseTerm)
        )
      );
      setDataState(initialDataState);
    } else {
      setFilteredData(packageList);
    }
  }, [searchTerm, packageList]);

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };
  // Reset filter values
  const resetSearch = () => {
    setSearchTerm("");
    setFilteredData(packageList); // Reset to original data
  };
  return (
    <div className="overall-view ">
      {visibleDialog == true ? (
        <Dialog
          height={"90%"}
          onClose={() => setVisibleDialog(false)}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "90%",
              marginTop: "5%",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px",
            }}
          >
            <div className="dialog-content">
              <div></div>
              <h1>Edit Package</h1>
              <div>
                <div className="dialog-item">
                  <div>
                    Package Name <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item" style={{ width: "220px" }}>
                  <Input
                    value={packageName}
                    maxLength={25}
                    required={check}
                    onChange={(e) => {
                      packageNameSet(e.value);
                      if (packageName.trim() !== "") {
                        packageErrorSet("");
                      }
                      console.log("Changed package name", e.value);
                    }}
                  />
                </div>
                {packageError && <Error>{packageError}</Error>}
              </div>
              <div>
                <div className="dialog-item">
                  <div>
                    Inclusions <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item" style={{ width: "220px" }}>
                  <MultiSelect
                    style={{ width: "220px" }}
                    data={inclusionDatumUpdate}
                    dataItemKey="Id"
                    textField="Name"
                    value={packageInclusion}
                    onChange={(e) => {
                      handleInclusion(e.target.value);
                      // setNewInclusionList(e.target.value); setNewExclusionList([]);console.log("The updated list",e.target.value);
                    }}
                    placeholder="Select Inclusions"
                  />
                </div>
                {inclusionError && <Error>{inclusionError}</Error>}
              </div>
              <div>
                <div className="dialog-item" style={{ width: "220px" }}>
                  <div>
                    Exclusions <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <MultiSelect
                    style={{ width: "220px" }}
                    data={exclusionDatumUpdate}
                    dataItemKey="Id"
                    textField="Name"
                    value={packageExclusion}
                    onChange={(e) => {
                      handleExclusion(e.target.value);
                      // setNewInclusionList(e.target.value); setNewExclusionList([]);console.log("The updated list",e.target.value);
                    }}
                    placeholder="Select Exclusion"
                  />
                </div>
                {exclusionError && <Error>{exclusionError}</Error>}
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleView}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#42B677",
                      padding: "10px",
                      color: "#fff",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    UPDATE
                  </Button>
                  <div style={{ padding: "10px" }} />
                  <Button
                    onClick={(e) => {
                      clearInput();
                      setVisibleDialog(false);
                    }}
                    style={{
                      backgroundColor: "#ED823A",
                      padding: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color: "#fff",
                    }}
                  >
                    CANCEL
                  </Button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <div> </div>
      )}
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() => window.location.reload()}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      <div className="overall-view">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            // paddingRight: "10px"
          }}
        >
          <Breadcrumbs breadCrumbData={breadCrumbsData} />
          <Button
            onClick={() => navigate("/masters/packageinfo")}
            className="edit-colors"
          >
            + CREATE NEW
          </Button>
        </div>
      <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin view-margin">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              marginBottom: "1%",
              // marginTop: "5%",
              alignItems: "center",
            }}
          >
            <Label className="list-heading">Package List</Label>
            <TextBox
              style={{marginTop:"2%"}}
              className="textBoxcustom"
              placeholder="Search using Package Name"
              name="roomcost"
              value={searchTerm}
              onChange={handleSearchChange}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {searchTerm ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handlePageChange}
            >
              <GridColumn field="sno" title="S.No." width="75px" />
              <GridColumn
                field="PackageName"
                title="Package Name"
                cell={(props) =>
                  editID === props.dataItem.Id ? (
                    <td>
                      <input
                        type="text"
                        value={props.dataItem.PackageName}
                        onChange={(e) =>
                          updatePackage(
                            props.dataItem.Id,
                            e.target.value,
                            props.dataItem.inclusion,
                            props.dataItem.exclusion
                          )
                        }
                      />
                    </td>
                  ) : (
                    <td>{props.dataItem.PackageName}</td>
                  )
                }
              />
              <GridColumn
                field="Inclusion"
                title="Inclusion"
                cell={(props) => <td>{props.dataItem.Inclusion}</td>}
              />
              <GridColumn
                field="Exclusion"
                title="Exclusion"
                cell={(props) =>
                  editID === props.dataItem.Id ? (
                    <td>
                      <DropDownList
                        data={exclusions}
                        value={props.dataItem.Exclusion}
                        onChange={(e) =>
                          updatePackage(
                            props.dataItem.Id,
                            props.dataItem.package,
                            props.dataItem.inclusion,
                            e.target.value
                          )
                        }
                      />
                    </td>
                  ) : (
                    <td>{props.dataItem.Exclusion}</td>
                  )
                }
              />
              <GridColumn
                title="Action"
                className="header-center"
                width="300px"
                cell={(props) => (
                  <td>
                    {/* {edit === false ? ( */}
                    <Button
                      className="edit-color"
                      onClick={() => enableField(props.dataItem.Id)}
                      svgIcon={pencilIcon}
                    />
                    {/* ) : (
                    <Button
                      className="edit-button-view"
                      onClick={() => setEditID(props.dataItem.Id)}
                      }
                      svgIcon={checkboxCheckedIcon}
                    /> 
                  )} */}
                    {/* <Button
                      className="delete-color"
                      onClick={() => setDeleteID(props.dataItem.Id)}
                      svgIcon={trashIcon}
                    /> */}
                  </td>
                )}
              />
            </Grid>
            {deleteID && (
              <Dialog title="Confirm" onClose={() => setDeleteID(null)}>
                <p>Are you sure you want to delete this row?</p>
                <DialogActionsBar>
                  <Button onClick={deletePackage}>Yes</Button>
                  <Button onClick={() => setDeleteID(null)}>No</Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
