import React, { useEffect, useRef, useState } from "react";
import { Grid, GridCellProps, GridColumn } from "@progress/kendo-react-grid";
import {
  Chart,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartSeries,
  ChartSeriesItem,
  ChartTooltip,
  TooltipContext,
} from "@progress/kendo-react-charts";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import '@progress/kendo-theme-default/dist/all.css';
import { process } from "@progress/kendo-data-query";
import "hammerjs";
import "../css/Reports.scss";
import { TextBox } from "@progress/kendo-react-inputs";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
  chartPieIcon,
  exportIcon,
  eyeIcon,
  listUnorderedIcon,
  percentIcon,
} from "@progress/kendo-svg-icons";
import Breadcrumbs from "../components/Breadcrumbs";
import { Label } from "@progress/kendo-react-labels";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import axios from "axios";
import { commonBaseUrl } from "../../services/CommonUtil";
import { useNavigate } from "react-router-dom";
import { PDFExport } from "@progress/kendo-react-pdf";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { Loader } from "@progress/kendo-react-indicators";

interface GraphData {
  userCount: number;
  userName: string;
}
export const RoleStatus = {
  Company: 1,
  Individual: 2,
};

export const UserActivity = () => {
  const baseURL = commonBaseUrl();
  const navigate = useNavigate();
  const hasFetched = useRef(false);
  const [selected, setSelected] = useState<string>("summary");
  const pieData: GraphData[] = [];
  const pdfExportComponent = React.useRef<PDFExport>(null);

  // BreadCrumbs
  const breadCrumbsData = [
    {
      id: "1",
      text: "Reports",
      route: "",
    },
    {
      id: "2",
      text: "User Activity Report",
      route: "",
    },
  ];

  const roles = [
    { Id: 1, Name: "Company" },
    { Id: 2, Name: "Individual" },
  ];
  const statuses = [
    { Id: 1, Name: "Active" },
    { Id: 2, Name: "Inactive" },
  ];
  const defaultRoleValue = {
    Id: 0,
    Name: "Select Role",
  };
  const defaultStatusValue = {
    Id: 0,
    Name: "Select Status",
  };

  const [dataState, setDataState] = useState({ skip: 0, take: 5 });

  const [searchQuery, setSearchQuery] = useState("");

  const [userListCount, setUserListCount] = useState([]);
  const [userList, setUserList] = useState([]);
  const [bargraphList, setBarGraphList] = useState([]);
  const [piegraphList, setPieGraphList] = useState<GraphData[]>([]);
  const [loading, setLoading] = React.useState(false);

  const [filterRole, setFilterRole] = useState(defaultRoleValue);
  const [filterRoleId, setFilterRoleId] = useState(0);
  const [filterStatus, setFilterStatus] = useState(defaultStatusValue);
  const [filterStatusId, setFilterStatusId] = useState(0);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>();
  const [filterEndDate, setFilterEndDate] = useState<Date | null>();
  const [currentPageData, setCurrentPageData] = useState([]);
  // const [currentBarGraphData, setCurrentBarGraphData] = useState([]);
  const [currentPieGraphData, setCurrentPieGraphData] = useState([]);
  const [currentBarGraphData, setCurrentBarGraphData] = useState<
    Array<{ userName: string; totalBookings: number }>
  >([]);

  // Function to format date to YYYY-MM-DD
  const formatDate = (date: any) => {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const labelContent = (e: { category: string }) => {
    const text = e.category;
    return text.length > 20 ? `${text.substring(0, 20)}...` : text;
  };

  const getStartDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth(), 1);
  };

  const getEndDate = () => {
    let now = new Date();
    return new Date(now.getFullYear(), now.getMonth() + 1, 0);
  };

  useEffect(() => {
    // Prevent second call
    if (hasFetched.current) return;
    hasFetched.current = true;
    const startDate = formatDate(getStartDate());
    const endDate = formatDate(getEndDate());
    setFilterStartDate(getStartDate());
    setFilterEndDate(getEndDate());
    UserList(startDate, endDate, filterRoleId, filterStatusId);
  });

  // Helper function to convert month number to short month name
  const getMonthName = (monthNumber: any) => {
    const monthNames = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthNames[monthNumber];
  };

  // Function to format the date string DD-MMM-YYYY
  const customDateOnly = (dateString: any) => {
    const date = new Date(dateString);

    const day = date.getDate();
    const month = getMonthName(date.getMonth());
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  };

  // Initial API call
  const UserList = async (
    fromDate: any,
    toDate: any,
    role: any,
    status: any
  ) => {
    let fullURL = ``;
    let roleURL = role === 0 ? "" : `&roleId=${role}`;
    let statusURL = status === 0 ? "" : `&status=${status}`;

    fullURL = `${baseURL}odata/GetUsersActivityList?fromDate=${fromDate}&toDate=${toDate}${roleURL}${statusURL}`;
    try {
      const response = await axios.get(fullURL);
      setUserListCount(response.data);
      const content = response.data.map((item: any, index: any) => ({
        ...item,
        id: index + 1,
      }));

      for (let i = 0; i < content.length; i++) {
        content[i]["customerType"] =
          content[i]["customerType"] === 1
            ? "Company"
            : content[i]["customerType"] === 2
            ? "Individual"
            : null;
      }
      setUserList(content);
      console.log("u", content);
      BarGraph(fromDate, toDate, role, status);
    } catch (err) {
      alert(err);
    }
  };

  const BarGraph = async (
    fromDate: any,
    toDate: any,
    role: any,
    status: any
  ) => {
    let fullURL = ``;
    let roleURL = role === 0 ? "" : `&roleId=${role}`;
    let statusURL = status === 0 ? "" : `&status=${status}`;

    fullURL = `${baseURL}odata/GetUsersActivityBarChart?fromDate=${fromDate}&toDate=${toDate}${roleURL}${statusURL}`;

    try {
      const response = await axios.get(fullURL);
      setBarGraphList(response.data); // Assuming response.data is the required array format
      PieGraph(fromDate, toDate, role, status); // Call PieGraph if necessary
    } catch (err) {
      alert(err);
    }
  };

  const PieGraph = async (
    fromDate: any,
    toDate: any,
    role: any,
    status: any
  ) => {
    let fullURL = ``;
    let roleURL = role === 0 ? "" : `&customerType=${role}`;
    let statusURL = status === 0 ? "" : `&status=${status}`;

    fullURL = `${baseURL}odata/GetUsersActivityPieChart?fromDate=${fromDate}&toDate=${toDate}${roleURL}${statusURL}`;
    console.log("pie", fullURL);
    try {
      const response = await axios.get(fullURL);
      if (response.data.activeUsersCount !== 0) {
        const activeGraphData: GraphData = {
          userCount: response.data.activeUsersCount,
          userName: "Active",
        };
        pieData.push(activeGraphData);
      }

      if (response.data.inactiveUsersCount !== 0) {
        const inactiveGraphData: GraphData = {
          userCount: response.data.inactiveUsersCount,
          userName: "InActive",
        };

        pieData.push(inactiveGraphData);
      }
      setPieGraphList(pieData);
    } catch (err) {
      alert(err);
    }
  };

  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => props.enterView(props.dataItem)}
        ></Button>
      </td>
    );
  };

  const enterView = (item: any) => {
    console.log(item);
    for (let d = 0; d < userListCount.length; d++) {
      if (item.email === userListCount[d]["email"]) {
        getUserData(userListCount[d]["email"], true);
        break;
      }
    }
  };

  const getUserData = async (email: any, view: any) => {
    try {
      const response = await axios.get(` ${baseURL}odata/Users`);
      for (let u = 0; u < response.data.value.length; u++) {
        if (email === response.data.value[u].Email) {
          console.log(response.data.value[u]);
          navigate("/viewuser", {
            state: { infoId: response.data.value[u], view },
          });
          break;
        }
      }
    } catch (err) {}
  };

  // Truncate long usernames for the x-axis labels
  const truncatedData = currentBarGraphData.map((item) => ({
    ...item,
    truncatedUserName:
      item.userName.length > 20
        ? `${item.userName.substring(0, 15)}...`
        : item.userName,
  }));
  // Current page Updation
  const pageSize = 5; // Number of bars to display per page
  const [barGraphStartIndex, setBarGraphStartIndex] = useState(0);

  // Update current bar graph data whenever bargraphList or start index changes
  useEffect(() => {
    setCurrentBarGraphData(
      bargraphList.slice(barGraphStartIndex, barGraphStartIndex + pageSize)
    );
  }, [barGraphStartIndex, bargraphList]);

  // Handle pagination for the bar chart
  const handleNextBarPage = () => {
    if (barGraphStartIndex + pageSize < bargraphList.length) {
      setBarGraphStartIndex(barGraphStartIndex + pageSize);
    }
  };

  const handlePreviousBarPage = () => {
    if (barGraphStartIndex > 0) {
      setBarGraphStartIndex(barGraphStartIndex - pageSize);
    }
  };
  // useEffect(() => {
  //   const paginatedData = userListCount.slice(dataState.skip, dataState.skip + dataState.take);
  //   setCurrentPageData(paginatedData);

  //   // Filter the bar and pie chart data according to the current page
  //   const updatedBarGraphData = bargraphList.filter(item =>
  //     paginatedData.some(user => user.userName === item.userName)
  //   );
  //   const updatedPieGraphData = piegraphList.filter(item =>
  //     paginatedData.some(user => user.userName === item.userName)
  //   );

  //   setCurrentBarGraphData(updatedBarGraphData);
  //   setCurrentPieGraphData(updatedPieGraphData);
  // }, [dataState, userListCount, bargraphList, piegraphList]);

  // Date  filter
  const onFilterChangefromdate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      setFilterStartDate(ev.value);
      // if (filterEndDate && ev.value > filterEndDate) {
      //   setFilterEndDate(null); // Clear the end date if it is less than the new start date
      // }
      if (filterEndDate && new Date(filterEndDate) < new Date(ev.value)) {
        setFilterEndDate(ev.value); // Set from date when from date less than to date
      }
    }
  };
  console.log("piegraphList:", piegraphList);

  const onFilterChangetodate = (ev: any) => {
    if (
      ev.nativeEvent.code === "ArrowUp" ||
      ev.nativeEvent.code === "ArrowDown" ||
      ev.nativeEvent.type === "input"
    ) {
      ev.syntheticEvent.preventDefault();
    } else {
      setFilterEndDate(ev.value);
    }
  };

  // Handle filtering
  const filteredUsers = userListCount.filter(
    (user) =>
      (filterRole.Name === "Company" || user["customerType"] === 1
        ? filterRole
        : filterRole) &&
      (filterStatus.Name === "Active" || user["status"] === filterStatus)
  );

  // Search
  const handleSearchChangess = (e: any) => {
    setSearchQuery(e.target.value);
  };

  const filtered = filteredUsers.filter((filteredDataSearch: any) => {
    const search = searchQuery.toLowerCase();

    return (
      filteredDataSearch.username?.toLowerCase().includes(search) || // Ensure fields exist before calling methods
      filteredDataSearch.email?.toLowerCase().includes(search) // Check correct casing of field name
    );
  });

  const handleFilterChange = () => {
    setBarGraphStartIndex(0);
    UserList(
      formatDate(filterStartDate),
      formatDate(filterEndDate),
      filterRole.Id,
      filterStatus.Id
    );
    // barGraphStartIndex === 0;
  };

  const handleClearFilters = () => {
    setFilterRole(defaultRoleValue);
    setFilterStatus(defaultStatusValue);
    setFilterRoleId(0);
    setFilterStatusId(0);
    setFilterStartDate(getStartDate());
    setFilterEndDate(getEndDate());
    const startDate = formatDate(getStartDate());
    const endDate = formatDate(getEndDate());
    UserList(startDate, endDate, filterRoleId, filterStatusId);
  };
  const handleSelect = (view: string) => {
    setSelected(view);
  };
  function wrapText(text: string, maxCharsPerLine: number): string {
    const words = text.split(" ");
    let lines = [""];
    let currentLine = 0;

    for (const word of words) {
      if ((lines[currentLine] + word).length > maxCharsPerLine) {
        lines.push("");
        currentLine++;
      }
      lines[currentLine] += word + " ";
    }

    return lines.join("\n").trim(); // Join lines with line breaks
  }

  const summaryView = () => {
    return (
      <div>
        <PDFExport
          ref={pdfExportComponent}
          paperSize="auto"
          margin={40}
          fileName="User Activity Report"
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "2rem",
            }}
          >
            Users Bookings
          </div>
          <div style={{ display: "flex", marginTop: "20px" }}>
            <div style={{ marginTop: "20px", width: "50%" }}>
              {/* Bar Chart - Bookings per User with Pagination */}

              {currentBarGraphData.length > 0 ? (
                <>
                  <Chart transitions={false}>
                    <ChartTooltip
                      render={(context: TooltipContext) => (
                        <div>{context.point?.dataItem?.userName}</div> // Display full username in tooltip
                      )}
                    />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={truncatedData.map(
                          (item) => item.truncatedUserName
                        )}
                        labels={{
                          rotation: "auto",
                          visual: (e) => {
                            const text = e.createVisual();
                            text.options.set("width", 80); // Set the width as needed
                            text.options.set("wrap", "word"); // Enable wrapping
                            return text;
                          },
                        }}
                      />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      <ChartSeriesItem
                        type="column"
                        data={truncatedData}
                        field="totalBookings"
                        categoryField="truncatedUserName"
                        labels={{ visible: true }}
                      />
                    </ChartSeries>
                  </Chart>
                  {/* <Chart transitions={false}>
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        labels={{
                          content: (e) => wrapText(e.value, 10), // Custom function to wrap text
                          rotation: "auto",
                        }}
                      />
                    </ChartCategoryAxis>
                    <ChartSeries>
                      <ChartSeriesItem
                        type="column"
                        data={currentBarGraphData}
                        field="totalBookings"
                        categoryField="userName"
                        labels={{ visible: true }}
                      />
                    </ChartSeries>
                  </Chart> */}

                  {/* <Chart transitions={false}>
                    <ChartTooltip
                      render={(context: TooltipContext) => (
                        <div>{context.point?.dataItem?.userName}</div> // Display full username in tooltip
                      )}
                    />
                    <ChartSeries>
                      <ChartSeriesItem
                        type="column"
                        data={currentBarGraphData}
                        field="totalBookings"
                        categoryField="userName"
                        labels={{ visible: true }}
                      />
                    </ChartSeries>
                  </Chart> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      className="common-button-view clear-color"
                      onClick={handlePreviousBarPage}
                      disabled={barGraphStartIndex === 0}
                    >
                      Previous
                    </Button>
                    <Button
                      className="common-button-view search-color"
                      onClick={handleNextBarPage}
                      disabled={
                        barGraphStartIndex + pageSize >= bargraphList.length
                      }
                    >
                      Next
                    </Button>
                  </div>
                </>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  No User Bookings data available
                </div>
              )}
            </div>
            <div style={{ marginTop: "-65px", width: "50%" }}>
              <div
                style={{
                  fontWeight: "bold",
                  fontSize: "2rem",
                }}
              >
                Users Status
              </div>

              {/* Pie Chart - Active vs Inactive Users */}

              {piegraphList.length > 0 ? (
                <Chart transitions={false} style={{ marginTop: "30px" }}>
                  <ChartLegend position="bottom" />
                  <ChartSeries>
                    <ChartSeriesItem
                      type="pie"
                      data={piegraphList}
                      field="userCount"
                      categoryField="userName"
                      labels={{ visible: true }}
                    />
                  </ChartSeries>
                </Chart>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  No User Status data available
                </div>
              )}
            </div>
          </div>
        </PDFExport>
      </div>
    );
  };
  const handleActiveExport = (e: any) => {
    setLoading(false);
  };
  const _export = React.useRef<ExcelExport | null>(null);
  const _grid = React.useRef<any>();

  const detailedView = () => {
    return (
      <div>
        <div
          style={{
            fontWeight: "bold",
            fontSize: "2rem",
          }}
        >
          Users List
        </div>
        <ExcelExport
          ref={_export}
          fileName="User Activity.xlsx"
          onExportComplete={(e) => handleActiveExport(e)}
        />
        <Grid
          className={loading ? "k-disabled" : ""}
          skip={dataState.skip}
          take={dataState.take}
          total={userListCount.length}
          pageable={{ buttonCount: 5, pageSizes: [5, 10, 15, 20] }}
          onPageChange={(e) => setDataState(e.page)}
          data={userListCount.slice(
            dataState.skip,
            dataState.skip + dataState.take
          )}
          ref={_grid}
        >
          <GridColumn
            field="id"
            title="S.No"
            width="50px"
            cell={(props) => <td>{props.dataIndex + 1}</td>}
          />
          <GridColumn
            field="name"
            title="Username"
            cell={(props) => (
              <td
                style={{
                  height: "75px",
                  textAlign: "left",
                }}
              >
                {props.dataItem.name}
              </td>
            )}
          />
          <GridColumn field="email" title="Email" />
          <GridColumn
            field="customerType"
            title="Customer Type"
            cell={(props) => (
              <td>
                {props.dataItem.customerType === 1 ? "Company" : "Individual"}
              </td>
            )}
          />
          <GridColumn field="status" title="Status" />
          <GridColumn
            title="Action"
            cell={(props) => (
              <td>
                <MyViewCommandCell {...props} enterView={enterView} />
              </td>
            )}
          />
        </Grid>
      </div>
    );
  };

  const exportPDFWithComponent = () => {
    setLoading(true);
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      setLoading(false);
    }
  };

  const excelExport = () => {
    setLoading(true);
    if (_export.current !== null && userList.length !== 0) {
      _export.current.save(userList, _grid.current.columns);
    }
  };

  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;

  return (
    <div className="dashboard-bg" style={{ padding: "10px" }}>
      {loading ? (
        <Loader
          size={"large"}
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
          type={"converging-spinner"}
        />
      ) : (
        <>
          <div className="overall-view">
            <div>
              <div className="dashboard-button">
                <Breadcrumbs breadCrumbData={breadCrumbsData} />
                {selected === "summary" ? (
                  <div>
                    {(bargraphList.length > 0 || piegraphList.length > 0) && (
                      <Button
                        className="bio-device-button-view"
                        onClick={exportPDFWithComponent}
                      >
                        Download Summary
                      </Button>
                    )}
                  </div>
                ) : (
                  <div>
                    {userListCount.length > 0 && (
                      <Button
                        className="bio-export-button-view"
                        svgIcon={exportIcon}
                        onClick={excelExport}
                      >
                        Export to csv
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <hr className="hr-border" />
            </div>
          </div>
          <div className="overall-view">
            <div>
              <div
                className="filter-group"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {/* <div
      // className="common-margin"
      style={{
        fontWeight: "bold",
        fontSize: "2rem",
        marginTop: "0px",
      }}
    >
      Filters
    </div> */}
                <div
                  className="filter-view"
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    width: "100%",
                    padding: "1%",
                  }}
                >
                  <div>
                    <Label>Select From Date</Label>
                    <style>{styles}</style>
                    <div className="no-wheel" ref={timePickerRef}>
                      <DatePicker
                        name="toDate"
                        className="dropdown-date"
                        onChange={onFilterChangefromdate}
                        value={filterStartDate}
                        format={"dd/MM/yyyy"}
                        placeholder="DD/MM/YYYY"
                        ref={timePickerRef}
                      />
                    </div>
                  </div>
                  <div>
                    <Label>Select To Date</Label>
                    <style>{styles}</style>
                    <div className="no-wheel" ref={timePickerRef}>
                      <DatePicker
                        name="toDate"
                        className="dropdown-date"
                        onChange={onFilterChangetodate}
                        value={filterEndDate}
                        format={"dd/MM/yyyy"}
                        placeholder="DD/MM/YYYY"
                        ref={timePickerRef}
                        min={filterStartDate ? filterStartDate : undefined}
                      />
                    </div>
                  </div>
                  <div>
                    <Label>Customer Type</Label>
                    <DropDownList
                      textField="Name"
                      dataItemKey="Id"
                      data={roles}
                      value={filterRole}
                      style={{ width: "100px", marginBottom: "10px" }}
                      onChange={(e) => setFilterRole(e.target.value)}
                    />
                  </div>
                  {/* <div style={{ width: "10%" }}></div> */}
                  <div>
                    <Label>Status</Label>
                    <DropDownList
                      textField="Name"
                      dataItemKey="Id"
                      data={statuses}
                      value={filterStatus}
                      style={{ width: "100px", marginBottom: "10px" }}
                      onChange={(e) => setFilterStatus(e.target.value)}
                    />
                  </div>
                  {/* <div style={{ width: "10%" }}></div> */}
                  {/* <div>
      <Label></Label>
      <TextBox
        placeholder="Search by User Name / Email-Id"
        value={searchQuery}
        onChange={handleSearchChangess}
        suffix={() => (
          <>
            <span className="input-icon">
              {searchQuery ? (
                <Button
                  icon="close"
                  onClick={() => setSearchQuery("")}
                />
              ) : (
                <Button icon="search" />
              )}
            </span>
          </>
        )}
        // onChange={(e) => setSearchQuery(e.value)}
        style={{
          width: "250px",
          height: "50%",
        }}
      />
    </div> */}
                  <div>
                    <Label></Label>
                    <Button
                      className="common-button-view search-color"
                      onClick={handleFilterChange}
                    >
                      Search
                    </Button>
                  </div>
                  <div>
                    <Label></Label>
                    <Button
                      className="common-button-view clear-color"
                      onClick={handleClearFilters}
                    >
                      Clear
                    </Button>
                  </div>
                </div>
              </div>

              <div className="button-toggle report-top">
                <ButtonGroup>
                  <Button
                    selected={selected === "summary"}
                    onClick={() => {
                      handleSelect("summary");
                    }}
                    className={
                      selected === "summary"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    svgIcon={chartPieIcon}
                    style={{ borderColor: "#42B677" }}
                  >
                    SUMMARY VIEW
                  </Button>
                  <Button
                    selected={selected === "detailed"}
                    onClick={() => handleSelect("detailed")}
                    svgIcon={listUnorderedIcon}
                    className={
                      selected === "detailed"
                        ? "selected-color"
                        : "unselect-color"
                    }
                    style={{ borderColor: "#42B677" }}
                  >
                    DETAILED VIEW
                  </Button>
                </ButtonGroup>
              </div>
              {selected === "summary" ? summaryView() : detailedView()}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}
