import React, { createRef, useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Checkbox,
  Input,
  RadioButton,
  RadioButtonChangeEvent,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import axios from "axios";
import {
  chevronDownIcon,
  eyeIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import { Card } from "@progress/kendo-react-layout";
import {
  DateInput,
  DatePicker,
  TimePicker,
} from "@progress/kendo-react-dateinputs";
import { useNavigate } from "react-router-dom";
import { Error } from "@progress/kendo-react-labels";
import { start } from "repl";
import { Loader } from "@progress/kendo-react-indicators";
import { MyDatePicker } from "../masters/MyDatePicker";
import {
  ExternalDropZone,
  Upload,
  UploadFileInfo,
} from "@progress/kendo-react-upload";
import { commonBaseUrl } from "../../services/CommonUtil";

interface Spac {
  Id: number;
  SpaceType: string;
  checked: boolean;
}

interface DurationData {
  DurationId: number;
  DurationName: string;
  RentAmount: number;
  DiscountValue?: number;
  DiscountPrice?: number;
}

interface TimeRange {
  startTime: Date | null;
  endTime: Date | null;
}

type DurationDetail = {
  durationId: number;
  durationName: string;
  actualPrice: number;
  discountValue: number;
  discountPrice: number;
};

type Space = {
  id: number;
  spaceName: string;
  durationDetails: DurationDetail[];
};

const SubCategoryDetails = () => {
  const navigate = useNavigate();
  const baseURL = commonBaseUrl();
  const initialDataState: State = {
    take: 3,
    skip: 0,
    group: [],
  };
  //   const [newPackage, setNewPackage] = useState("");
  //   const [newInclusion, setNewInclusion] = useState(defaultInclusionItem);
  //   const [newExclusion, setNewExclusion] = useState(defaultExclusionItem);
  const [editID, setEditID] = useState(null);
  const [deleteID, setDeleteID] = useState(null);
  const [dataState, setDataState] = useState<State>(initialDataState);

  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = spaceList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };
  const minTime = new Date();
  minTime.setHours(8, 0, 0); // 8:00 AM

  const maxTime = new Date();
  maxTime.setHours(20, 0, 59);
  const breadCrumbsData = [
    {
      id: "1",
      text: "Cafeteria Management",
      route: "/cafeteria/overview",
    },
    {
      id: "2",
      text: "Sub-Categories",
      route: "/cafeteria/subcategories",
    },
    {
      id: "3",
      text: "Sub-Categories Create",
      //   route: "",
    },
  ];

  //mani sir code
  const [spaces, setSpaces] = useState<Space[]>();

  const [selectedSpaces, setSelectedSpaces] = useState<string[]>([]);
  //   const [durationSpaceList, setDurationSpaceList] = useState<DurationData[]>(
  //     []
  //   );
  const [durationSpaceList, setDurationSpaceList] = useState<any>(undefined);
  const [discountTypeList, discountTypeListSet] = useState<any>();

  const [spaceList, spaceListSet] = useState<Spac[]>([]);
  const [selectedDiscount, selectedDiscountSet] = useState();
  //   const [durationSpaceList, durationSpaceListSet] = useState<any>([]);
  const [allDiscountData, setAllDiscountData] = useState<any>();
  const storeDiscountData: any[] = [];

  const reset = () => {
    console.log("Data in the list before selecting ==>", allDiscountData);
    if (allDiscountData !== undefined) {
      if (allDiscountData.length !== 0) {
        setAllDiscountData((prevSpaces: any) =>
          prevSpaces.map((space: Space) => {
            return {
              ...space,
              durationDetails: space.durationDetails.map(
                (detail: DurationDetail) => {
                  return {
                    ...detail,
                    discountValue: 0,
                    discountPrice: 0,
                  };
                  // return detail;
                }
              ),
            };
          })
        );
      }
    }
  };

  const [categoryList, categoryListSet] = useState<any>([]);
  const [firsttime, setFirstTime] = useState(true);
  const [openTime, setOpenTime] = useState<Date>();
  const [closeTime, setCloseTime] = useState<Date>();

  useEffect(() => {
    const CategoryApi = async () => {
      try {
        const response = await axios.get(
          `${baseURL}odata/Categorys`
        );
        console.log("Category Response", response.data.value);
        categoryListSet(response.data.value);
      } catch (err) {}
    };
    // setVisibleDialog(false);
    if (firsttime === true) {
      console.log("Called");
      CategoryApi();
      setFirstTime(false);
    }
  });
  //   useEffect(() => {
  //     console.log(durationSpaceList.spaceName, "Value changes");
  //   }, [durationSpaceList]);

  const [allDurationData, setAllDurationData] = useState<{
    [key: string]: DurationData[];
  }>({});
  const datas: { [key: string]: DurationData[] } = {};
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "ArrowUp" || event.key === "ArrowDown") {
      event.preventDefault(); // Prevents arrow key actions
    }
    if (event.key === "." || event.key === "," || event.key === "-") {
      event.preventDefault(); // Prevents decimal input
    }
  };
  const durationSpaceApi = async (spaceId: any, selectedSpaceType: string) => {
    // var list;
    // const fileList: any[] = [];
    // fileList.push(spaceId);
    // fileList.forEach(async (filename) => {
    //   try {
    //     const response = await axios.get(
    //       `https://dev-cw-api.equadriga.in/odata/SpaceMappings?$filter=SpaceId eq ${spaceId}`
    //     );
    //     const durationList = response.data.value;
    //     const transformedData = transformResponse(
    //       durationList,
    //       selectedSpaceType
    //     );
    //     setDurationSpaceList((duration: any) => [
    //       ...duration,
    //       {
    //         [filename]: transformedData,
    //       },
    //     ]);
    //     // setDurationSpaceList(transformedData);
    //     console.log(durationSpaceList);
    //     //   const datas = durationList.map((dataItem: any) => {
    //     //     return {
    //     //       "Lounge Space": [
    //     //         {
    //     //           DurationId: dataItem.Id,
    //     //           DurationName: dataItem.DurationType,
    //     //           RentAmount: 300,
    //     //         },
    //     //         {
    //     //           DurationId: dataItem.Id,
    //     //           DurationName: dataItem.DurationType,
    //     //           RentAmount: 4500,
    //     //         },
    //     //       ],
    //     //     };
    //     //   });
    //     //   setAllDurationData(datas);
    //     //   console.log("Duration", durationList);
    //     //   const datas = durationList.forEach((dataItem: any) => {
    //     //     // const spaceType = selectedSpaceType;
    //     //     console.log("Space Type", selectedSpaceType, dataItem);
    //     //     return {
    //     //       spaceName: selectedSpaceType,
    //     //       durationDetails: dataItem.map((detail: any) => ({
    //     //         applicableSpacesId: dataItem.Id,
    //     //         durationId: detail.DurationId,
    //     //         durationName: detail.DurationName,
    //     //         actualPrice: detail.RentAmount,
    //     //         discountValue: 0, // Default value
    //     //         discountPrice: 0, // Default value
    //     //       })),
    //     //     };
    //     //     // datas[selectedSpaceType].push({
    //     //     //   DurationId: dataItem.SpaceId,
    //     //     //   DurationName: dataItem.DurationName,
    //     //     //   RentAmount: Number(dataItem.RentAmount),
    //     //     // });
    //     //   });

    //     //   setAllDurationData(datas);
    //     //   console.log("All", durationList, datas);
    //     //   setDurationSpaceList(datas);
    //     //   console.log("All durations", datas);
    //     //   durationSpaceListSet(response.data.value);
    //     //   console.log("Duration length", durationSpaceList.length);
    //     //   console.log("Duration Api value", response.data.value);
    //     //   list = response.data.value;
    //     //   setDurationList(list);
    //     // spaceListSet(response.data.value);
    //   } catch (err) {}
    // }, []);

    var list;
    try {
      const response = await axios.get(
        `${baseURL}odata/SpaceDetails?$filter=SpaceHeaderId eq ${spaceId}`
      );
      const durationList = response.data.value;
      const transformedData = transformResponse(
        durationList,
        selectedSpaceType
      );
      if (allDiscountData !== undefined) {
        for (let d = 0; d < allDiscountData.length; d++) {
          storeDiscountData.push(allDiscountData[d]);
        }
      }

      storeDiscountData.push(transformedData);
      setDurationSpaceList(transformedData);
      setAllDiscountData(storeDiscountData);
      console.log("After Store", storeDiscountData);
    } catch (err) {}
    return list;
  };
  // CSS styling for the components
  const styles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}
 
.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}
 
.no-wheel input::-moz-inner-spin-button {
  display: none;
}`;
  const transformResponse = (response: any, selectedSpaceType: string) => {
    return {
      spaceName: selectedSpaceType,
      durationDetails: response.map((detail: any) => ({
        applicableSpacesId: detail.SpaceHeaderId,
        durationId: detail.DurationId,
        durationName: detail.DurationName,
        actualPrice: detail.RentAmount,
        discountValue: 0, // Default value
        discountPrice: 0, // Default value
      })),
    };
  };

  //   const setDurationList = (list: any) => {
  //     durationSpaceListSet;
  //   };

  const [edit, setEdit] = useState(false);
  const [search, searchSet] = useState("");
  const [discountList, discountListSet] = useState([
    "Fixed off",
    "Percentage off",
  ]);
  const [statusValue, setstatusValue] = useState("1");

  const handleChange = React.useCallback(
    (e: RadioButtonChangeEvent) => {
      setstatusValue(e.value);
    },
    [setstatusValue]
  );

  const [checked, checkedSet] = useState(false);
  const checkclicked = (item: any, index: any, checked: boolean) => {
    // console.log(index, item, "<");
    // console.log("State -->", checked);
    const field = item.Id;
    const checkupdate = spaceList.map((item: any, idx: any) => {
      //   console.log(idx, index, "<s");
      if (idx === index) {
        // console.log(idx, index, "<st");
        // console.log("Check when change", checked);
        return { ...item, ["checked"]: !checked };
      }
      return item;
    });
    spaceListSet(checkupdate);
    // console.log("Space List", checkupdate);
    // checkedSet(!checked);
    if (!checked) {
      //   durationSpaceApi(item.Id);
    }
  };
  // const initialData: TableRow[] = [
  //     {
  //       duration: "Hourly",
  //       variant: "Up to 2 hours",
  //       deposit: "",
  //       price: "",
  //       tax: "",
  //       checked: false,
  //     },
  //     {
  //       duration: "Hourly",
  //       variant: "3+ hours",
  //       deposit: "",
  //       price: "",
  //       tax: "",
  //       checked: false,
  //     },
  //     {
  //       duration: "Daily",
  //       variant: "NA",
  //       deposit: "",
  //       price: "",
  //       tax: "",
  //       checked: false,
  //     },
  //     {
  //       duration: "Monthly",
  //       variant: "NA",
  //       deposit: "",
  //       price: "",
  //       tax: "",
  //       checked: false,
  //     },
  //     {
  //       duration: "Quarterly",
  //       variant: "NA",
  //       deposit: "",
  //       price: "",
  //       tax: "",
  //       checked: false,
  //     },
  //     {
  //       duration: "Yearly",
  //       variant: "NA",
  //       deposit: "",
  //       price: "",
  //       tax: "",
  //       checked: false,
  //     },
  //   ];
  interface TableRow {
    duration: string;
    RentAmount: string;
    deposit: string;
    price: string;
    tax: string;
    checked: boolean;
  }
  // State to hold the table data
  //   const [tableData, setTableData] = useState<TableRow[]>(initialData);
  const handleChanges = (
    index: number,
    field: any,
    value: string | boolean
  ) => {
    const updatedData = durationSpaceList.map((item: any, idx: any) => {
      if (idx === index) {
        return { ...item, [field]: value };
      }
      return item;
    });
    // durationSpaceListSet(updatedData);
  };
  const [selectedSpaceType, selectedSpaceTypeSet] = useState("");
  // Handle checkbox click
  const handleCheckboxChange = (item: any, index: any) => {
    selectedSpaceTypeSet("");
    const updatedList = [...spaceList];
    updatedList[index].checked = !updatedList[index].checked;

    const selectedSpaceTypes = updatedList[index].SpaceType;
    selectedSpaceTypeSet(selectedSpaceTypes);
    // Toggle selected spaces and update duration data
    if (updatedList[index].checked) {
      setSelectedSpaces([...selectedSpaces, selectedSpaceTypes]);
      durationSpaceApi(item.Id, selectedSpaceTypes);
      //   setDurationSpaceList(allDurationData[selectedSpaceType]);
      console.log("Selected -->", selectedSpaces.length);
    } else {
      console.log("space", selectedSpaces, allDiscountData);
      setSelectedSpaces(
        selectedSpaces.filter((space) => space !== selectedSpaceTypes)
      );
      setAllDiscountData(
        allDiscountData.filter(
          (space: any) => space.spaceName !== selectedSpaceTypes
        )
      );
      console.log("Selected -->", selectedSpaces.length);
      // selectedSpaceType = ""
      // console.log("Space removed -->",selectedSpaceType);
      // setDurationSpaceList(undefined);
    }
  };

  //Handle mani code
  const handleDiscountChange = (
    spaceId: string,
    durationId: number,
    field: keyof DurationDetail,
    value: number
  ) => {
    setAllDiscountData((prevSpaces: any) =>
      prevSpaces.map((space: Space) => {
        if (space.spaceName === spaceId) {
          return {
            ...space,
            durationDetails: space.durationDetails.map(
              (detail: DurationDetail) => {
                if (detail.durationId === durationId) {
                  const updatedDetail = detail;
                  updatedDetail.discountValue = value;
                  if (selectType.id === 2) {
                    const discountAmount =
                      (updatedDetail.discountValue *
                        updatedDetail.actualPrice) /
                      100;
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - discountAmount >= 0
                        ? updatedDetail.actualPrice - discountAmount
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  } else {
                    updatedDetail.discountPrice =
                      updatedDetail.actualPrice - updatedDetail.discountValue >=
                      0
                        ? updatedDetail.actualPrice -
                          updatedDetail.discountValue
                        : 0;
                    console.log("Came --> ", updatedDetail.discountPrice);
                  }
                  // }
                  return updatedDetail;
                }
                return detail;
              }
            ),
          };
        }
        return space;
      })
    );
  };

  // Handle discount input change
  const handleDiscountChanges = (e: any, index: any, indexes: any) => {
    const updatedList = allDiscountData;
    console.log(
      "Data came from -->",
      updatedList[indexes].durationDetails[index]
    );
    var data = updatedList[indexes].durationDetails[index];
    data.discountValue = parseInt(e.target.value);
    data.discountPrice =
      parseInt(data.actualPrice) - parseInt(data.discountValue) >= 0
        ? parseInt(data.actualPrice) - parseInt(data.discountValue)
        : 0;
    // updatedList[indexes].durationDetails[index].discountValue = e.target.value;
    // updatedList[indexes].durationDetails[index].discountPrice = e.target.value;
    console.log("Value come from", data);
    console.log(
      "Before updation-->",
      updatedList[indexes].durationDetails[index]
    );
    updatedList[indexes].durationDetails[index] = data;
    console.log(
      "After updation-->",
      updatedList[indexes].durationDetails[index]
    );
    console.log("Updated Whole data-->", updatedList);
    setAllDiscountData(updatedList);
    // updatedList[index].DiscountPrice =
    //   updatedList[index].RentAmount - e.target.value;
    // setDurationSpaceList(updatedList);
  };

  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };

  const [startDate, startDateSet] = useState();
  const [endDate, endDateSet] = useState();
  const [quantity, quantitySet] = useState("");
  const [catTypeError, catTypeErrorSet] = useState("");
  const [error, errorSet] = useState("");
  const [errors, errorsSet] = useState("");
  const createSubCategory = () => {
    let Validate = true;
    checkSet(true);
    if (subCatName.trim() === "") {
      nameErrorSet("Name must be provided");
      Validate = false;
    } else {
      nameErrorSet("");
    }
    if (imageData === "") {
      console.log(imageData, "44");
      setErrorimegeMessage("Please Select image");
      // formIsValid = false;
    } else {
      setErrorimegeMessage("");
    }
    if (subCatDescription.trim() === "") {
      descriptionErrorSet("Description must be provided");
      Validate = false;
    } else {
      descriptionErrorSet("");
    }
    if (price.trim() === "") {
      priceErrorSet("Price was Empty");
      Validate = false;
    } else if (parseInt(price) <= 0) {
      priceErrorSet("Enter the Price above zero");
      Validate = false;
    } else {
      priceErrorSet("");
    }
    if (selectType.id === null) {
      typeErrorSet("Select the type");
      Validate = false;
    } else {
      typeErrorSet("");
    }
    if (selectCatType.Id === null) {
      catTypeErrorSet("Select the Category type");
      Validate = false;
    } else {
      catTypeErrorSet("");
    }
    if (quantity === "") {
      limitErrorSet("Enter the Quantity");
      Validate = false;
    } else if (parseInt(quantity) <= 0) {
      limitErrorSet("Enter the Quantity");
      Validate = false;
    } else {
      limitErrorSet("");
    }
    if (!openTime) {
      errorSet("Please select start time");
    } else {
      errorSet("");
    }

    if (!closeTime) {
      errorsSet("Please select end time");
    } else {
      errorsSet("");
    }
    if (
      subCatName.trim() !== "" &&
      subCatDescription.trim() !== "" &&
      selectType.id !== null &&
      selectCatType.Id !== null &&
      quantity.trim() !== "" &&
      price.trim() !== "" &&
      parseInt(quantity) > 0 &&
      imageData !== "" &&
      openTime &&
      closeTime
    ) {
      if (Validate === true) {
        addSubCatApi();
      }
    }
  };
  const [updatedTimeRange, updatedTimeRangeSet] = useState<TimeRange[]>([
    { startTime: null, endTime: null },
  ]);
  const convertToISOFormat = (inputDate: any): string => {
    console.log("Input Date->", inputDate);
    // Parse the input date string to a Date object
    const dateObj = new Date(inputDate);
    console.log("Date->", dateObj);

    // Extract the individual date components
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Month is zero-based, so add 1
    const day = String(dateObj.getDate()).padStart(2, "0");
    const hours = String(dateObj.getHours()).padStart(2, "0");
    const minutes = String(dateObj.getMinutes()).padStart(2, "0");
    const seconds = String(dateObj.getSeconds()).padStart(2, "0");

    // Get the timezone offset in hours and minutes
    const timezoneOffset = -dateObj.getTimezoneOffset(); // In minutes
    const offsetHours = String(Math.floor(timezoneOffset / 60)).padStart(
      2,
      "0"
    );
    const offsetMinutes = String(Math.abs(timezoneOffset % 60)).padStart(
      2,
      "0"
    );

    // Construct the ISO 8601 formatted string with timezone
    const isoFormattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}+${offsetHours}:${offsetMinutes}`;

    console.log("Formatted Date->", isoFormattedDate);
    return isoFormattedDate;
  };
  const addSubCatApi = async () => {
    // setLoading(true);
    const createSubCategory = {
      categoryId: selectCatType.Id,
      name: subCatName,
      isAvailable: statusValue === "1" ? true : false,
      price: parseInt(price),
      typeId: selectType.id,
      fixedQuantity: parseInt(quantity),
      quantity: parseInt(quantity),
      // jsonDate: "string",
      description: subCatDescription,
      fromDate: convertToISOFormat(openTime),
      toDate: convertToISOFormat(closeTime),
    };
    console.log("The json structure is -->", createSubCategory);

    try {
      const response = await axios.post(
        `${baseURL}odata/SubCategorys`,
        createSubCategory,
        {
          headers: header,
        }
      );
      console.log("Response -->", response.data.Id);
      const subcatId = response.data.Id;
      uploadDocumentFiles(subcatId);
      //   navigate("/cafeteria/subcategories");
    } catch (err: any) {
      if (err.response.status === 500) {
        console.log(err);
        alertSet(err.response.data.detail);
        actionSet(true);
        statusDialogSet(true);
      }
    }
  };
  {
    /* Sri code */
  }
  const [action, actionSet] = useState(false);
  const [subCatName, subCatNameSet] = useState("");
  const [subCatDescription, subCatDescriptionSet] = useState("");
  // const [selectedType, selectedTypeSet ] = useState("");
  const defaultType = { id: null, name: "Choose the type" };
  const defaultCatType = { Id: null, Name: "Choose the category type" };
  const [selectType, selectedTypeSet] = useState(defaultType);
  const [selectCatType, selectCatTypeSet] = useState(defaultCatType);
  const handleDropdown = (e: any) => {
    console.log("The selected Dropdown value", e);
    selectedTypeSet(e);
    reset();
  };
  const handleDropdownCat = (e: any) => {
    console.log("The selected Dropdown value", e);
    selectCatTypeSet(e);
    reset();
  };
  //Sri Code ^ below
  useEffect(() => {
    console.log("selected type is =>", selectType.id);
  }, [selectType]);

  const [nameError, nameErrorSet] = useState("");
  const [descriptionError, descriptionErrorSet] = useState("");
  const [typeError, typeErrorSet] = useState("");
  const [startError, startErrorSet] = useState("");
  const [endError, endErrorSet] = useState("");
  const [limitError, limitErrorSet] = useState("");
  const [spaceError, spaceErrorSet] = useState("");
  const [check, checkSet] = useState(false);
  const [type, typeSet] = useState([
    { id: 1, name: "Veg" },
    { id: 2, name: "Non-Veg" },
  ]);

  //   useEffect(() => {
  //     if (check) {
  //       if (subCatName.trim() === "") {
  //         nameErrorSet("Sub-Category name must be provided");
  //       } else {
  //         nameErrorSet("");
  //       }
  //       if (subCatDescription.trim() === "") {
  //         descriptionErrorSet("Description must be provided");
  //       } else {
  //         descriptionErrorSet("");
  //       }
  //       if (price.trim() === "") {
  //         priceErrorSet("Price was Empty");
  //     //   Validate = false;
  //     } else {
  //       priceErrorSet("");
  //     }
  //       if (selectType.id === null) {
  //         typeErrorSet("Select the type");
  //       } else {
  //         typeErrorSet("");
  //       }
  //       if (selectCatType.Id === null) {
  //         catTypeErrorSet("Select the category type");
  //       } else {
  //         catTypeErrorSet("");
  //       }
  //       if (startDate === undefined) {
  //         startErrorSet("Choose the Start Date");
  //       } else {
  //         startErrorSet("");
  //       }
  //       if (endDate === undefined) {
  //         endErrorSet("Choose the End Date");
  //       } else {
  //         endErrorSet("");
  //       }
  //       if (quantity === "") {
  //         limitErrorSet("Enter the Quantity");
  //       } else {
  //         limitErrorSet("");
  //       }
  //       if (selectedSpaces.length === 0) {
  //         spaceErrorSet("Select the Spaces");
  //         console.log(
  //           "Error handling",
  //           selectedSpaceType,
  //           selectedSpaceType === ""
  //         );
  //       } else {
  //         spaceErrorSet("");
  //       }
  //     }
  //   }, [
  //     check,
  //     subCatName,
  //     subCatDescription,
  //     selectType,
  //     startDate,
  //     endDate,
  //     quantity,
  //     selectedSpaces,
  //   ]);

  // Restrict scroll & keyboart action in date picker
  // CSS styling for the components

  const customStyles = `
.no-wheel input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* Hide spinner controls */
  pointer-events: none;
  user-select: none;
}

.no-wheel input::-webkit-inner-spin-button,
.no-wheel input::-webkit-calendar-picker-indicator {
  display: none;
}

.no-wheel input::-moz-inner-spin-button {
  display: none;
}
`;

  // Reference for the TimePicker input element
  const timePickerRef = useRef<HTMLDivElement | null>(null);

  // Prevent mouse wheel scrolling
  useEffect(() => {
    const element = timePickerRef.current;

    const handleWheel = (event: WheelEvent) => {
      event.preventDefault();
    };

    if (element) {
      // Attach wheel event listener
      element.addEventListener("wheel", handleWheel, { passive: false });

      // Clean up event listener
      return () => {
        element.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);

  const setTimeCalc = (value: any, name: string) => {
    const curDate = new Date();
    console.log(convertToISOFormat(value), "<<<drs<");
    if (name === "end") {
      endDateSet(value);
    } else {
      startDateSet(value);
      if (value > endDate! && endDate !== undefined) {
        endDateSet(value);
      }
    }
  };

  const [loading, setLoading] = useState(false); // State for loading
  const [price, priceSet] = useState(""); // State for loading
  const [priceError, priceErrorSet] = useState(""); // State for loading

  // Variables
  const [imageData, setImageData] = useState("");
  const [currentFile, setCurrentFile] = useState<UploadFileInfo>();
  const uploadRefs = createRef<Upload>();

  const hint = (
    <span>Click to select files or Drag and drop files here to upload.</span>
  );
  const note = <span>Only JPEG, JPG, and PNG files are allowed.</span>;

  function onSaveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any },
    onProgress: (uid: string, event: ProgressEvent<EventTarget>) => void
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    setCurrentFile(currentFile);
    const uid = currentFile.uid;
    console.log(currentFile, "121");
    return new Promise<{ uid: string }>((resolve, reject) => {
      if (
        currentFile.validationErrors &&
        currentFile.validationErrors.length > 0
      ) {
        reject({ uid: uid });
      } else {
        const reader = new FileReader();
        reader.onload = () => {
          if (reader.result && typeof reader.result === "string") {
            const base64Result = reader.result.split(",")[1];
            console.log(base64Result, "this< base64");
            setImageData(base64Result);
            resolve({ uid: uid });
          } else {
            reject({ uid: uid });
          }
        };
        reader.onprogress = (data) => {
          onProgress(uid, data);
        };
        reader.onabort = () => {
          reject({ uid: uid });
        };
        reader.onerror = () => {
          reject({ uid: uid });
        };
        reader.readAsDataURL(currentFile.getRawFile!());
      }
    });
  }

  function onRemoveRequest(
    files: UploadFileInfo[],
    options: { formData: FormData; requestOptions: any }
  ): Promise<{ uid: string }> {
    const currentFile = files[0] as UploadFileInfo;
    const uid = currentFile.uid;
    setImageData("");
    return new Promise<{ uid: string }>((resolve) => {
      resolve({ uid: uid });
    });
  }
  const [errorimageMessage, setErrorimegeMessage] = useState<string>("");
  //Image Validation

  // if (imageData === "" && update === false) {
  //     console.log(imageData, "44");
  //     setErrorimegeMessage("Please Select image");
  //     formIsValid = false;
  //   } else {
  //     setErrorimegeMessage("");
  //   }

  const [timeRanges, setTimeRanges] = useState<TimeRange[]>([
    { startTime: null, endTime: null },
  ]);

  const addTimeFields = () => {
    setTimeRanges([...timeRanges, { startTime: null, endTime: null }]);
  };

  const removeTimeFields = () => {
    if (timeRanges.length > 1) {
      setTimeRanges(timeRanges.slice(0, -1)); // Remove the last time range
    }
  };

  const handleStartTimeChange = (value: Date | null, index: number) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges[index].startTime = value;
    setTimeRanges(newTimeRanges);
  };

  const handleEndTimeChange = (value: Date | null, index: number) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges[index].endTime = value;
    setTimeRanges(newTimeRanges);
  };
  //upload Image
  const uploadDocumentFiles = async (subcatid: String) => {
    let header = {
      "Content-Type":
        "application/json;odata.metadata=minimal;odata.streaming=true",
      accept: "*/*",
    };
    let uploadData = [
      {
        docUploadGuid: currentFile?.uid,
        docUploadRefType: "Food",
        docUploadRefNo: subcatid,
        docUploadRefSubType: "SubCategory",
        image: [
          {
            value: imageData,
            fileName: currentFile?.name,
            type: currentFile?.extension?.split(".").pop(),
          },
        ],
      },
    ];
    try {
      const response = await axios.post(
        `${baseURL}odata/JsonUpload`,
        uploadData,
        {
          headers: header,
        }
      );
      if (response.status === 200) {
        alertSet("Sub-category created successfully");
        actionSet(false);
        statusDialogSet(true);
        // setVisibleDialog(true);
        // setVisibleDetails(false);
      } else {
        // setLoader(false)
        // setVisible(true);
        // setAlertContent("Server not rechable. Please try again later!");
        // setLoading(false);
      }
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true); // setLoading(false);
    }
  };
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");

  return (
    <div>
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() =>
                  action === false
                    ? navigate("/cafeteria/subcategories")
                    : window.location.reload()
                }
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      {loading ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view ">
          <div className="overall-view">
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
            <hr className="hr-border" />
          </div>
          <div className="view-margin">
            <div className="common-margin view-margin">
              <div className="custom-input-containers">
                <div className="custom-input-views">
                  <Label className="common-label-size">
                    Name <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    className="book-height"
                    type="text"
                    maxLength={25}
                    value={subCatName}
                    onChange={(e) => subCatNameSet(e.value)}
                    placeholder="Enter the name of the sub-category"
                  />
                  {nameError && <Error>{nameError}</Error>}
                </div>
                <div style={{ width: "40px" }}> </div>
                <div className="custom-input-views">
                  <Label>
                    Category Type <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="book-dropdown-new"
                    data={categoryList}
                    textField="Name"
                    dataItemKey="Id"
                    onChange={(e) => handleDropdownCat(e.target.value)}
                    value={selectCatType}
                  />
                  {catTypeError && <Error>{catTypeError}</Error>}
                </div>
                <div className="custom-input-views">
                  <Label>
                    {" "}
                    Type <span style={{ color: "red" }}>*</span>
                  </Label>
                  <DropDownList
                    svgIcon={chevronDownIcon}
                    className="book-dropdown-new"
                    data={type}
                    textField="name"
                    dataItemKey="id"
                    onChange={(e) => handleDropdown(e.target.value)}
                    value={selectType}
                  />
                  {typeError && <Error>{typeError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div>
                <Label>
                  Sub-Category Image <span style={{ color: "red" }}>*</span>
                </Label>
                <ExternalDropZone
                  className="field-space border-file-upload"
                  uploadRef={uploadRefs}
                  customHint={hint}
                  customNote={note}
                />
                <div className="center-view" style={{ paddingTop: "10px" }}>
                  <Upload
                    ref={uploadRefs}
                    restrictions={{
                      maxFileSize: 2000000,
                      allowedExtensions: [".jpeg", ".png", ".jpg"],
                    }}
                    batch={false}
                    multiple={false}
                    defaultFiles={[]}
                    withCredentials={false}
                    saveUrl={onSaveRequest}
                    removeUrl={onRemoveRequest}
                  />
                  {errorimageMessage && <Error>{errorimageMessage}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div
                className="custom-input-containers"
                style={{ display: "flex", justifyContent: "start" }}
              >
                <div className="custom-input-views" style={{ width: "35%" }}>
                  <Label className="common-label-size">
                    Description <span style={{ color: "red" }}>*</span>
                  </Label>
                  <TextArea
                    maxLength={500}
                    value={subCatDescription}
                    onChange={(e) => subCatDescriptionSet(e.value)}
                    placeholder="Enter Description"
                  />
                  {descriptionError && <Error>{descriptionError}</Error>}
                </div>
                <div
                  className="custom-input-views"
                  style={{ width: "10%", marginLeft: "20px" }}
                >
                  <Label>
                    Quantity <span style={{ color: "red" }}>*</span>
                  </Label>
                  <Input
                    type="number"
                    className="book-dropdown-news"
                    value={quantity}
                    onKeyDown={handleKeyDown}
                    min={0}
                    maxLength={3}
                    max={999}
                    onChange={(e) => {
                      console.log("Enter the Quantity");
                      if (parseInt(e.value) <= 0) {
                        limitErrorSet("Enter the Quantity");
                      } else if (e.value.length <= 3) {
                        quantitySet(e.value); // Allows up to 3 characters
                      }
                    }}
                    placeholder="Enter Quantity"
                  />
                  {check && limitError && <Error>{limitError}</Error>}
                </div>
                <div style={{ width: "40px" }}></div>
                <div className="custom-input-views" style={{ width: "11%" }}>
                  <Label>
                    Price (₹/piece)<span style={{ color: "red" }}> *</span>
                  </Label>
                  <Input
                    type="number"
                    className="book-dropdown-news"
                    value={price}
                    min={0}
                    onKeyDown={handleKeyDown}
                    maxLength={3}
                    max={999}
                    onChange={(e) => {
                      if (e.value.length <= 3) {
                        priceSet(e.value); // Allows up to 3 characters
                      }
                      // priceSet(e.value);
                      // console.log("Price changes from ==>", e.value);
                      // console.log("Condition 1 ==>", parseInt(e.value) <= 0);
                      // console.log("Condition 1 ==>", parseInt(e.value) > 1000);
                      if (parseInt(e.value) <= 0 || e.value === "") {
                        priceErrorSet("Enter the Price");
                      } else if (parseInt(e.value) > 999) {
                        priceErrorSet("Price not greater than 999");
                      } else {
                        priceErrorSet("");
                      }
                    }}
                    placeholder="Enter Price"
                  />
                  {check && priceError && <Error>{priceError}</Error>}
                </div>
              </div>
              <hr className="hr-border" />
              <div className="form-row-create">
                <style>{styles}</style>
                <div className="form-group no-wheel">
                  <Label>
                    Start Time <span style={{ color: "red" }}>*</span>
                  </Label>
                  <div className="createRoomTimePicker">
                    <TimePicker
                      value={openTime}
                      className="book-dropdown-new border_error_Change"
                      // format= {{hour: "2-digit",minute: "2-digit",}}
                      placeholder={"Hour / Min"}
                      // min={minTime}

                      // className=''
                      min={minTime}
                      max={new Date(maxTime.getTime() - 60 * 60 * 1000)}
                      onChange={(e) => {
                        setCloseTime(maxTime);
                        if (e.nativeEvent.type === "input") {
                          e.syntheticEvent.preventDefault();
                          console.log(e.value, "<tsfdytfsgsdhgsd");
                          if (e.value === null) {
                            setOpenTime(minTime);
                          }
                        } else {
                          if (
                            e.value !== null &&
                            e.value!.getHours() >= minTime.getHours() &&
                            e.value! < maxTime
                          ) {
                            setOpenTime(new Date(e.value!.setSeconds(0)));

                            console.log(e.value, "<backspaces");
                            errorSet("");
                          }
                        }
                        console.log(minTime, "<backspacess");
                        console.log(e.value, "<backspacess e.value");
                      }}
                    />
                  </div>
                  {error && <Error>{error}</Error>}
                </div>
                <style>{styles}</style>
                <div className="form-group no-wheel">
                  <Label>
                    End Time <span style={{ color: "red" }}>*</span>
                  </Label>
                  <div className="createRoomTimePicker">
                    <TimePicker
                      required={false}
                      disabled={openTime !== undefined ? false : true}
                      className="book-dropdown-new border_error_Change"
                      value={closeTime}
                      // format={{ hour: "2-digit", minute: "2-digit" }}
                      min={
                        openTime
                          ? new Date(
                              openTime.setSeconds(0o0) + +60 * 60 * 1000 + 1000
                            )
                          : undefined
                      }
                      max={new Date(maxTime.setHours(20, 0, 59))}
                      placeholder={"Hour / Min"}
                      onChange={(e) => {
                        if (e.nativeEvent.type === "input") {
                          e.syntheticEvent.preventDefault();

                          if (e.value === null) {
                            setCloseTime(maxTime);
                          }
                        } else {
                          if (
                            e.value!.getHours() > openTime!.getHours()! &&
                            e.value !== null &&
                            e.value! < maxTime
                          ) {
                            setCloseTime(e.value!);
                            errorSet("");
                          }
                        }
                      }}
                    />
                  </div>
                  {errors && <Error>{errors}</Error>}
                </div>
              </div>
              {/* <div>
                {timeRanges.map((timeRange, index) => (
                  <div
                    key={index}
                    style={{ display: "flex", marginBottom: "10px" }}
                  >
                    <div>
                      <div className="form-row-create">
                        <style>{styles}</style>
                        <div className="form-group no-wheel">
                          <Label>
                            Start Time <span style={{ color: "red" }}>*</span>
                          </Label>
                          <div className="createRoomTimePicker">
                            <TimePicker
                              value={timeRange.startTime}
                              className="book-dropdown-new border_error_Change"
                              // format= {{hour: "2-digit",minute: "2-digit",}}
                              placeholder={"Hour / Min"}
                              // onChange={(e) =>
                              //   handleStartTimeChange(e.value, index)
                              // }
                              min={minTime}
                    max={new Date(maxTime.getTime() - 60 * 60 * 1000)}
                    onChange={(e) => {
                      setCloseTime(maxTime);
                      if (e.nativeEvent.type === "input") {
                        e.syntheticEvent.preventDefault();
                        console.log(e.value, "<tsfdytfsgsdhgsd");
                        if (e.value === null) {
                          setOpenTime(minTime);
                        }
                      } else {
                        if (
                          e.value !== null &&
                          e.value!.getHours() >= minTime.getHours() &&
                          e.value! < maxTime
                        ) {
                          setOpenTime(new Date(e.value!.setSeconds(0)));

                          console.log(e.value, "<backspaces");
                          setErrors((prev) => ({ ...prev, openTimeError: "" }));
                        }
                      }
                      console.log(minTime, "<backspacess");
                      console.log(e.value, "<backspacess e.value");
                    }}
                            />
                          </div>
                        </div>
                        <style>{styles}</style>
                        <div className="form-group no-wheel">
                          <Label>
                            End Time <span style={{ color: "red" }}>*</span>
                          </Label>
                          <div className="createRoomTimePicker">
                            <TimePicker
                              required={false}
                              value={timeRange.endTime}
                              className="book-dropdown-new border_error_Change"
                              placeholder={"Hour / Min"}
                              onChange={(e) =>
                                handleEndTimeChange(e.value, index)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}
              <div
                className="center-view"
                style={{
                  paddingBottom: "50px",
                }}
              >
                <div className="form-row-area">
                  <div className="form-group-area"></div>
                </div>
                <div className="button-group">
                  <Button className="button-view" onClick={createSubCategory}>
                    CREATE
                  </Button>
                  <Button
                    // type="submit"
                    className="common-button-view"
                    onClick={() => navigate("/cafeteria/subcategories")}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  width: "50%",
                }}
              ></div>
              <hr className="hr-border" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubCategoryDetails;

interface TimeRange {
  startTime: Date | null;
  endTime: Date | null;
}

export const TimeRangeForm = () => {
  const [timeRanges, setTimeRanges] = useState<TimeRange[]>([
    { startTime: null, endTime: null },
  ]);

  const addTimeFields = () => {
    setTimeRanges([...timeRanges, { startTime: null, endTime: null }]);
  };

  const removeTimeFields = () => {
    if (timeRanges.length > 1) {
      setTimeRanges(timeRanges.slice(0, -1)); // Remove the last time range
    }
  };

  const handleStartTimeChange = (value: Date | null, index: number) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges[index].startTime = value;
    setTimeRanges(newTimeRanges);
  };

  const handleEndTimeChange = (value: Date | null, index: number) => {
    const newTimeRanges = [...timeRanges];
    newTimeRanges[index].endTime = value;
    setTimeRanges(newTimeRanges);
  };
  const styles = `
    .no-wheel input {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      /* Hide spinner controls */
      pointer-events: none;
      user-select: none;
    }
     
    .no-wheel input::-webkit-inner-spin-button,
    .no-wheel input::-webkit-calendar-picker-indicator {
      display: none;
    }
     
    .no-wheel input::-moz-inner-spin-button {
      display: none;
    }`;
  useEffect(() => {
    console.log("The timing set is==>", timeRanges);
    console.log("The timing set string is ==>", timeRanges.toString());
  }, [timeRanges]);
  return (
    <div>
      {timeRanges.map((timeRange, index) => (
        <div key={index} style={{ display: "flex", marginBottom: "10px" }}>
          <div>
            <div className="form-row-create">
              <style>{styles}</style>
              <div className="form-group no-wheel">
                <Label>
                  Start Time <span style={{ color: "red" }}>*</span>
                </Label>
                <div className="createRoomTimePicker">
                  <TimePicker
                    value={timeRange.startTime}
                    className="book-dropdown-new border_error_Change"
                    // format= {{hour: "2-digit",minute: "2-digit",}}
                    placeholder={"Hour / Min"}
                    onChange={(e) => handleStartTimeChange(e.value, index)}
                  />
                </div>
              </div>
              <style>{styles}</style>
              <div className="form-group no-wheel">
                <Label>
                  End Time <span style={{ color: "red" }}>*</span>
                </Label>
                <div className="createRoomTimePicker">
                  <TimePicker
                    required={false}
                    value={timeRange.endTime}
                    className="book-dropdown-new border_error_Change"
                    placeholder={"Hour / Min"}
                    onChange={(e) => handleEndTimeChange(e.value, index)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
