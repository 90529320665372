import React, { useEffect, useRef, useState } from "react";
import {
  Grid,
  GridCellProps,
  GridColumn,
  GridItemChangeEvent,
  GridToolbar,
} from "@progress/kendo-react-grid";
import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Checkbox,
  Input,
  RadioButton,
  TextArea,
  TextBox,
} from "@progress/kendo-react-inputs";
import { Label } from "@progress/kendo-react-labels";
import { process, State } from "@progress/kendo-data-query";
import "../css/Duration.scss";
import "../css/Common.scss";
import Breadcrumbs from "../components/Breadcrumbs";
import {
  chevronDownIcon,
  pencilIcon,
  trashIcon,
} from "@progress/kendo-svg-icons";
import axios from "axios";
import { Error } from "@progress/kendo-react-labels";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { commonBaseUrl } from "../../services/CommonUtil";

interface durationDataList {
  sno: number;
  Id: number;
  DurationName: string;
  DurationType: string;
  HasVarient: string;
  VarientName: string;
}

const Categories = () => {
  const baseURL = commonBaseUrl();
  const initialDataState: State = {
    take: 5,
    skip: 0,
    group: [],
  };
  const [dataState, setDataState] = useState<State>(initialDataState);

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  const getPageableSettings = () => {
    const length = categoryList.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 5 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 5 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  };

  const breadCrumbsData = [
    {
      id: "1",
      text: "Cafeteria Management",
      route: "/cafeteria/overview",
    },
    {
      id: "2",
      text: "Categories",
      //   route: "",
    },
  ];
  const [categoryList, categoryListSet] = useState<any>([]);
  const [firsttime, setFirstTime] = useState(true);
  let header = {
    "Content-Type":
      "application/json;odata.metadata=minimal;odata.streaming=true",
    accept: "*/*",
  };
  const CategoryStatusApi = async (status: boolean) => {
    const ids: any[] = [];

    for (let i = 0; i < filteredData.length; i++) {
      if (filteredData[i].check === true) {
        ids.push(filteredData[i].Id);
      }
    }
    console.log("The collected ids were =>", ids);
    if (ids.length !== 0) {
      try {
        // console.log("send data>>", formSubmit);
        const response = await axios.post(`${baseURL}odata/UpdateCategoryIsAvailability?IsAvailable=${status}`, ids,
          {
            headers: header,
          });
        console.log("Category status", response.data);
        window.location.reload();
      }
      catch (err) { }
    }
  };

  useEffect(() => {
    const CategoryApi = async () => {
      try {
        const response = await axios.get(`${baseURL}odata/Categorys`);
        console.log("Category Response", response.data.value);
        const tempCatList = response.data.value;
        for (let ad = 0; ad < tempCatList.length; ad++) {
          tempCatList[ad].sno = ad + 1;
          tempCatList[ad].check = false;
          if (tempCatList[ad].IsAvailable) {
            tempCatList[ad].isDisabled = false;
          } else {
            tempCatList[ad].isDisabled = true;
          }
          console.log("Category Discount -->", tempCatList);
        }
        console.log("Category Response", tempCatList);
        categoryListSet(response.data.value);
      } catch (err) { }
    };
    // setVisibleDialog(false);
    if (firsttime === true) {
      console.log("Called");
      CategoryApi();
      setFirstTime(false);
    }
  });

  const rowRender = (trElement: any, props: any) => {
    const item = props.dataItem;

    // Check if the row should be disabled
    const isRowDisabled = item.isDisabled;

    const newTrElement = React.cloneElement(
      trElement,
      {
        style: isRowDisabled
          ? { backgroundColor: "#f0f0f0", pointerEvents: "none", opacity: 0.5 }
          : {},
        "aria-disabled": isRowDisabled, // Accessibility
      },
      trElement.props.children
    );

    return newTrElement;
  };
  const [edit, setEdit] = useState(false);
  const [search, searchSet] = useState("");
  const [newCategory, newCategorySet] = useState("");
  const [newCategoryDescription, newCategoryDescriptionSet] = useState("");
  const [newCategoryDescriptionError, newCategoryDescriptionErrorSet] =
    useState("");
  const [newCheck, newCheckSet] = useState(false);
  const [newCategoryError, newCategoryErrorSet] = useState("");

  const addCategory = () => {
    newCheckSet(true);
    if (newCategory.trim() === "") {
      newCategoryErrorSet("Category Name must be provide");
    } else {
      newCategoryErrorSet("");
    }
    if (newCategoryDescription.trim() === "") {
      newCategoryDescriptionErrorSet("Description must be provide");
    } else {
      newCategoryDescriptionErrorSet("");
    }
    if (newCategory.trim() !== "" && newCategoryDescription.trim() !== "") {
      let addCategory = {
        name: newCategory,
        description: newCategoryDescription,
      };
      newCategoryApi(addCategory);
    }
  };
  const newCategoryApi = async (data: any) => {
    console.log(data, "Received data");
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(`${baseURL}odata/Categorys`, data);
      console.log("Category Added", response.data);
      alertSet("Category added successfully");
      statusDialogSet(true);
      // window.location.reload();
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };
  const clearInput = () => {
    newCategorySet("");
    newCategoryDescriptionSet("");
    newCheckSet(false);
    newCategoryErrorSet("");
    newCategoryDescriptionErrorSet("");
  };

  const [categoryId, categoryIdSet] = useState(0);
  const [categoryName, categoryNameSet] = useState("");
  const [categoryDescription, categoryDescriptionSet] = useState("");
  const [categoryError, categoryErrorSet] = useState("");
  const [categorydescriptionError, categorydescriptionErrorSet] = useState("");
  const [visibleDialog, setVisibleDialog] = useState(false);
  const [check, checkSet] = useState(false);

  const enableField = (id: any) => {
    // setEdit(true);
    // setEditID(id);
    categoryIdSet(id.Id);
    categoryNameSet(id.Name);
    categoryDescriptionSet(id.Description);
    console.log("EditEnableId", id, edit);
    console.log("Duration List", categoryList);
    setVisibleDialog(true);
  };

  const handleView = () => {
    checkSet(true);
    if (categoryName.trim() === "") {
      categoryErrorSet("Amenity Name cannot be empty");
    } else {
      categoryErrorSet("");
      //HANDLE THE API FUNCTIONALITY
    }
    if (categoryDescription.trim() === "") {
      categorydescriptionErrorSet("Description cannot be empty");
    } else {
      categorydescriptionErrorSet("");
      //HANDLE THE API FUNCTIONALITY
    }
    if (
      categoryError === "" &&
      categorydescriptionError === "" &&
      categoryName.trim() !== "" &&
      categoryDescription.trim() !== ""
    ) {
      console.log("the Selected id is -->", categoryId);
      console.log("the Selected id is -->", categoryName);
      console.log("the Selected id is -->", categoryDescription);
      updateCategory();
    }
  };
  const updateCategory = async () => {
    let update = {
      id: categoryId,
      name: categoryName,
      description: categoryDescription,
    };
    console.log("Recieved data -->", update);
    try {
      // console.log("send data>>", formSubmit);
      const response = await axios.post(
        `${baseURL}odata/UpdateCategory?key=${categoryId}`,
        update
      );
      console.log("Category Added", response.data);
      // window.location.reload();
      alertSet("Category updated successfully");
      statusDialogSet(true);
    } catch (err: any) {
      console.log("error", err.response.data.detail);
      alertSet(err.response.data.detail);
      statusDialogSet(true);
    }
  };

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredData, setFilteredData] = useState(categoryList);
  const [titleCheck, titleCheckSet] = useState(false);
  const [checkdata, checkdataSet] = useState(false);

  useEffect(() => {
    if (searchTerm) {
      const lowerCaseTerm = searchTerm.toLowerCase();
      setFilteredData(
        categoryList.filter(
          (item: any) =>
            item.Name.toLowerCase().includes(lowerCaseTerm) ||
          // item.IsAvailable.toLowerCase().includes(lowerCaseTerm) ||
            item.Description.toLowerCase().includes(lowerCaseTerm) 
        )
      );
      setDataState(initialDataState);
    } else {
      setFilteredData(categoryList);
    }
  }, [searchTerm, categoryList]);

  const handleSearchChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const handleDataStateChange = (e: any) => {
    setDataState(e.dataState);
  };
  const [statusDialog, statusDialogSet] = useState(false);
  const [alert, alertSet] = useState("");
  // Reset filter values
  const resetSearch = () => {
    setSearchTerm("");

    setFilteredData(categoryList); // Reset to original data
  };
  const handleCheckboxChange = (index: number, tableRow: any) => {
    console.log("Data ==>", tableRow);
    console.log("Index ==>", index);
    console.log("The given set ==>", filteredData);
    titleCheckSet(false);
    checkdataSet(false);
    setFilteredData((prevData: any) =>
      prevData.map((row: any, idx: any) => {
        return idx === index
          ? {
            ...row,
            check: !row.check,
          }
          : row;
      })
    );
  };
  const CustomHeaderCell = (props: any) => {
    const handleClick = () => {
      titleCheckSet(!titleCheck);
      checkdataSet(!checkdata);
      console.log("Title clicked!", dataState);
      setFilteredData((prevData: any) =>
        prevData.map((row: any) => {
          return {
            ...row,
            check: titleCheck ? false : true,
          };
        })
      );
    };

    return (
      <div style={{display:"flex"}}>
      <Checkbox 
      style={{ borderColor: "black", borderStyle: "solid", cursor:"pointer" }}
      checked = {checkdata}
      onClick={handleClick}
      />

      <a onClick={handleClick} style={{cursor: "pointer", marginLeft:"10px"}}>
        {props.title}
      </a>
      </div>
    );
  };

  return (
    <div className="overall-view ">
      {visibleDialog == true ? (
        <Dialog
          height={400}
          onClose={() => setVisibleDialog(false)}
          className="dialog-containers"
        >
          <div
            className="dilog-content-size"
            style={{
              width: "350px",
              height: "350px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              fontSize: "15px",
            }}
          >
            <div className="dialog-content">
              <h1>Edit Category</h1>
              <div>
                <div className="dialog-item">
                  <div>
                    Category Name<span style={{ color: "red" }}> *</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <Input
                    value={categoryName}
                    required={check}
                    maxLength={25}
                    onChange={(e) => {
                      categoryNameSet(e.value);
                      if (categoryName.trim() !== "") {
                        categoryErrorSet("");
                      }
                      console.log("Changed duration name", e.value);
                    }}
                  />
                  {/* <div>{durationName}</div> */}
                </div>
                {categoryError && <Error>{categoryError}</Error>}
              </div>
              <div>
                <div className="dialog-item">
                  <div>
                    Description <span style={{ color: "red" }}>*</span>
                  </div>
                </div>
                <div className="dialog-item">
                  <TextArea
                    value={categoryDescription}
                    required={check}
                    maxLength={500}
                    onChange={(e) => {
                      categoryDescriptionSet(e.value);
                      if (categoryDescription.trim() !== "") {
                        categorydescriptionErrorSet("");
                      }
                      console.log("Changed amenity name", e.value);
                    }}
                  />
                  {/* <div>{durationName}</div> */}
                </div>
                {categorydescriptionError && (
                  <Error>{categorydescriptionError}</Error>
                )}
              </div>
              <div
                style={{
                  width: "100%",
                }}
              >
                <div
                  style={{
                    paddingTop: "50px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <div className="content-button-padding"> */}
                  <Button
                    onClick={handleView}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#42B677",
                      padding: "10px",
                      color: "#fff",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                    }}
                  >
                    {/* <Button primary={true} onClick={handleView} onClickCapture={handleView}> */}
                    UPDATE
                  </Button>
                  <div style={{ padding: "10px" }} />
                  {/* </div> */}
                  {/* <div className="content-button-padding"> */}
                  <Button
                    onClick={(e) => {
                      setVisibleDialog(false);
                      categoryErrorSet("");
                      categorydescriptionErrorSet("");
                    }}
                    style={{
                      // marginRight: "20px",
                      backgroundColor: "#ED823A",
                      padding: "10px",
                      paddingRight: "20px",
                      paddingLeft: "20px",
                      color: "#fff",
                    }}
                  >
                    CANCEL
                  </Button>
                  {/* </div> */}
                </div>
              </div>
            </div>
          </div>
        </Dialog>
      ) : (
        <div> </div>
      )}
      {statusDialog && (
        <Dialog>
          <div
            style={{
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
              alignContent: "center",
            }}
          >
            <h3>{alert}</h3>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                paddingTop: "30px",
              }}
            >
              <Button
                className="button-view"
                onClick={() => window.location.reload()}
              >
                Ok
              </Button>
            </div>
          </div>
        </Dialog>
      )}
      <div className="overall-view">
        <Breadcrumbs breadCrumbData={breadCrumbsData} />
        <hr className="hr-border" />
      </div>
      <div className="view-margin">
        <div className="common-margin view-margin">
          <div className="custom-inputs-container">
            <div className="custom-inputs-view">
              <Label className="common-label-size">
                Category Name <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                className="book-height"
                type="text"
                value={newCategory}
                maxLength={25}
                required={newCheck}
                onChange={(e) => {
                  newCategorySet(e.value);
                  if (newCategory.trim() !== "") {
                    newCategoryErrorSet("");
                    // newCheckSet(false);
                  }
                }}
                placeholder="Enter the category name"
              />
              {newCategoryError && <Error>{newCategoryError}</Error>}
            </div>
            <div style={{ width: "40px" }}> </div>
            <div className="custom-inputs-view">
              <Label className="common-label-size">
                Description <span style={{ color: "red" }}>*</span>
              </Label>
              <TextArea
                className="book-height"
                value={newCategoryDescription}
                maxLength={500}
                required={newCheck}
                onChange={(e) => {
                  newCategoryDescriptionSet(e.value);
                  if (newCategoryDescription.trim() !== "") {
                    newCategoryDescriptionErrorSet("");
                    // newCheckSet(false);
                  }
                }}
                placeholder="Enter Description"
              />
              {newCategoryDescriptionError && (
                <Error>{newCategoryDescriptionError}</Error>
              )}
            </div>
          </div>
          <div
            className="custom-inputs-view common-label-padding"
            style={{ marginTop: "2%" }}
          >
            <Button className="button-view" onClick={addCategory}>
              Create
            </Button>
            <Button className="common-button-view" onClick={clearInput}>
              Clear
            </Button>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "10px",
              marginBottom: "1%",
              marginTop: "5%",
              alignItems: "center",
            }}
          >
            <Label className="list-heading">List of Categories</Label>
            <Button
              onClick={() => CategoryStatusApi(true)}
              className="edit-colors"
            >
              Available
            </Button>
            <Button
              className="delete-color"
              style={{
                padding: "10px",
                fontWeight: "bold"
              }}
              onClick={() => CategoryStatusApi(false)}>
              Unavailable
            </Button>
            <TextBox
              className="textBoxcustom"
              placeholder="Search using Category Name"
              name="roomcost"
              value={searchTerm}
              onChange={handleSearchChange}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {searchTerm ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
          </div>
          <div>
            <Grid
              data={process(filteredData, dataState)}
              pageable={getPageableSettings()}
              skip={dataState.skip}
              take={dataState.take}
              total={filteredData.length}
              onPageChange={(e) => setDataState(e.page)}
              onDataStateChange={handleDataStateChange}
            // rowRender={rowRender}
            >
              <GridColumn
                title={!titleCheck ? "Select all" : "Unselect all"}
                // width={100}
                headerCell={CustomHeaderCell}
                cell={(props) => (
                  <td>
                    <Checkbox
                      style={{ borderColor: "black", borderStyle: "solid" }}
                      checked={props.dataItem.check}
                      onChange={() =>
                        handleCheckboxChange(props.dataIndex, props.dataItem)
                      }
                    />
                  </td>
                )}
              />
              <GridColumn
                field="Id"
                title="S.No."
                width="75px"
                cell={(props) => <td>{props.dataIndex + 1}</td>}
              />
              <GridColumn
                field="Name"
                title="Category Name"
                cell={(props) => <td>{props.dataItem.Name}</td>}
              />
              <GridColumn
                field="Description"
                title="Description"
                cell={(props) => <td>{props.dataItem.Description}</td>}
              />
               <GridColumn
                field="IsActive"
                title="Status"
                cell={(props) => (
                  <td>
                    {props.dataItem.IsAvailable === true ? "Available" : "Unavailable"}
                  </td>
                )}
              />
              <GridColumn
                title="Action"
                className="header-center"
                width="300px"
                cell={(props) => (
                  <td>
                    <Button
                      className="edit-color"
                      onClick={() => {
                        enableField(props.dataItem);
                        console.log("Selected Data", props.dataItem);
                      }}
                      svgIcon={pencilIcon}
                    />
                  </td>
                )}
              />
            </Grid>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Categories;
