import { Button } from "@progress/kendo-react-buttons";
import Breadcrumbs from "../../components/Breadcrumbs";
import React, { useEffect, useMemo, useState } from "react";
import { Grid, GridColumn, GridCellProps } from "@progress/kendo-react-grid";
import * as XLSX from "xlsx";
import { Input, TextBox } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { eyeIcon, pencilIcon, trashIcon } from "@progress/kendo-svg-icons";
import { process, State } from "@progress/kendo-data-query";
import { Loader } from "@progress/kendo-react-indicators";
import { commonBaseUrl, commonImageUrl } from "../../../services/CommonUtil";

const initialDataState: State = {
  take: 5,
  skip: 0,
  group: [],
};

//interfaces
interface Invoice {
  invoiceId: number;
  invoiceNo: string;
  customerName: string;
  dateIssued: string;
  totalAmount: number;
  bookingId: number;
  amountDue: number;
  paymentStatus: string;
}

interface ViewCommandCellProps extends GridCellProps {
  enterView: (item: any) => void;
}

export const InvoiceDashboard = () => {
  const navigate = useNavigate();
  const [invoices, setInvoices] = useState([]);
  const [selectedInvoices, setSelectedInvoices] = useState<Invoice[]>([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [downloadInvoiceCopy, setDownloadInvoiceCopy] = useState(
    "Download Invoice Copy"
  );
  const [paymentStatuses, setPaymentStatuses] = useState([]);
  const baseUrl = commonBaseUrl();
  const imageURL = commonImageUrl();

  const [filteredInvoices, setFilteredInvoices] = useState(invoices);
  const [dataState, setDataState] = useState<State>(initialDataState);
  const [loader, setLoader] = useState(false);

  const breadCrumbsData = [
    {
      id: "1",
      text: "Masters",
      route: "",
    },
    {
      id: "2",
      text: "Booking",
      route: "/masters/room",
    },
  ];

  useEffect(() => {
    setLoader(true);
    fetchPaymentStatuses();
    fetchInvoices();
  }, [paymentStatuses.length > 0]);

  const formatDate = (dateString: Date) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, "0"); // Pad single digit day
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Pad single digit month
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const fetchPaymentStatuses = async () => {
    try {
      const response = await axios.get(`${baseUrl}odata/PaymentStatus`);
      setPaymentStatuses(response.data.value);
      fetchInvoices();
    } catch (error) {}
  };

  const fetchInvoices = async () => {
    try {
      const response = await axios.get(`${baseUrl}GetInvoiceHeader`);

      const Data = response.data || {};
      console.log(Data, "<<- this paymentId");
      const getData = Data.map((dataItem: any) => {
        const invoiceDetails = dataItem.invoiceDetails || [];
        const paymentStatus = paymentStatuses.find(
          (item: any) => item.Id === dataItem.statusId
        );

        return {
          invoiceId: dataItem.id,
          invoiceNo: dataItem.invoiceCode,
          customerName: dataItem.name,
          dateIssued: formatDate(dataItem.invoiceDate),
          totalAmount: invoiceDetails[0].invoiceTotal,
          amountDue: invoiceDetails[0].invoiceRent,
          paymentStatus: paymentStatus?.["Description"],
          bookingId: dataItem.bookingId,
        };
      });
      setInvoices(getData);
      setFilteredInvoices(getData);
    } catch (error) {
      console.error("Error fetching invoices:", error);
    }
    setLoader(false);
  };

  const handleSelectionChange = (event: any, invoice: Invoice) => {
    const isChecked = event.target.checked;

    setSelectedInvoices(() => {
      // If checked, set selectedInvoices to an array containing only the new invoice
      return isChecked ? [invoice] : [];
    });
  };

  const genPDF = async (e: any) => {
    console.log(selectedInvoices, "qwertyuihgvcvghjkjhgfd");
    for (var i = 0; i < selectedInvoices.length; i++) {
      const idInvoice = selectedInvoices[i].bookingId;
      setDownloadInvoiceCopy("Downloading...");
      try {
        const paymentResponce = await axios.get(
          `${baseUrl}odata/DocumentUploads?$filter=refno%20eq%20${idInvoice}%20and%20contains(reftype, 'Invoices')`
        );
        const paymentStatus = paymentResponce.data.value || [];
        const imagepaths = `${imageURL}${
          paymentStatus[paymentStatus.length - 1].ImagePath
        }`;
        const link = document.createElement("a");
        link.href = imagepaths;
        link.setAttribute(
          "download",
          `${paymentStatus[paymentStatus.length - 1].Name}`
        );
        window.open(imagepaths, "_blank");
      } catch (e: any) {}
    }

    window.setTimeout(() => {
      setDownloadInvoiceCopy("Download Invoice Copy");
    }, 100);
  };

  const MyViewCommandCell = (props: ViewCommandCellProps) => (
    <ViewCommandCell {...props} enterView={props.enterView} />
  );

  const enterView = async (item: any) => {
    console.log(item.invoiceId, "<<");

    navigate("/management/listbooking/invoice/create", {
      state: {
        invoiceId: item.invoiceId,
        bookingId: item.bookingId,
        view: true,
      },
    });
    //navigate
  };

  const ViewCommandCell = (props: ViewCommandCellProps) => {
    return (
      <td>
        <Button
          className="view-color"
          type="button"
          svgIcon={eyeIcon}
          onClick={() => {
            props.enterView(props.dataItem);
          }}
        ></Button>
      </td>
    );
  };

  const handleSearchChangess = (e: any) => {
    setDataState(initialDataState);
    setSearchQuery(e.target.value);
    const filteredInvoices = invoices.filter((invoice: any) => {
      return (
        invoice.invoiceNo
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        invoice.customerName
          ?.toLowerCase()
          .includes(e.target.value.toLowerCase()) ||
        invoice.SpaceTypeName?.toLowerCase().includes(
          e.target.value.toLowerCase()
        )
      );
    });
    console.log("filteredInvoices", filteredInvoices);
    setFilteredInvoices(filteredInvoices);
  };

  // Filter invoices based on search query
  // const filteredInvoices = invoices.filter((invoice: any) => {
  //   return invoice.invoiceNo !== null
  //     ? invoice.invoiceNo.toLowerCase().includes(searchQuery.toLowerCase())
  //     : invoice.invoiceNo.toLowerCase().includes("") ||
  //       invoice.customerName !== null
  //     ? invoice.customerName
  //         .toString()
  //         .toLowerCase()
  //         .includes(searchQuery.toLowerCase())
  //     : invoice.customerName.toLowerCase().includes("");
  // invoice.dateIssued !== null ?  invoice.dateIssued.toLowerCase().includes(searchQuery.toLowerCase()):   invoice.dateIssued.toLowerCase().includes("") ||
  // invoice.amountDue !== null ?  invoice.amountDue.toString().includes(searchQuery.toString()):   invoice.amountDue.toLowerCase().includes("") ||
  // invoice.totalAmount !== null ?  invoice.totalAmount.toString().includes(searchQuery.toString()):   invoice.totalAmount.toLowerCase().includes("") ||
  // invoice.paymentStatus !== null ?  invoice.paymentStatus.toLowerCase().includes(searchQuery.toLowerCase()) :   invoice.paymentStatus.toLowerCase().includes("")
  // });

  const getPageableSettings = useMemo(() => {
    const length = filteredInvoices.length;
    if (length === 0) {
      return false;
    } else if (length <= 5) {
      return { pageSizes: false, buttonCount: 1 };
    } else if (length <= 10) {
      return { pageSizes: [5, 10], buttonCount: 2 };
    } else if (length <= 20) {
      return { pageSizes: [5, 10, 20], buttonCount: 5 };
    } else {
      return { pageSizes: [5, 10, 20, 50], buttonCount: 5 };
    }
  }, [filteredInvoices.length]);

  const handlePageChange = (pageState: any) => {
    setDataState({ ...dataState, ...pageState });
  };

  // Reset filter values
  const resetSearch = () => {
    setSearchQuery("");

    setFilteredInvoices(invoices); // Reset to original data
  };

  return (
    <div className="overall-view ">
      {loader === true ? (
        <div className="loader">
          <Loader size="large" type={"converging-spinner"} />
        </div>
      ) : (
        <div className="overall-view">
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            <Breadcrumbs breadCrumbData={breadCrumbsData} />
            <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
              <Button
                style={{
                  height: "70%",
                  backgroundColor: "#42B677",
                  color: "white",
                }}
                onClick={() =>
                  navigate("/management/listbooking/invoice/create")
                }
                icon="plus"
              >
                Generate Invoice
              </Button>
            </div>
          </div>

          <hr className="hr-border" />

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "20px",
            }}
          >
            <label></label>
            <div
              style={{
                marginBottom: "20px",
                display: "flex",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Button
                onClick={genPDF}
                disabled={selectedInvoices.length === 0}
                className="k-button k-success"
              >
                {downloadInvoiceCopy}
              </Button>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              paddingRight: "20px",
              paddingTop: "20px",
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                fontSize: "2rem",
              }}
            >
              All Invoices
            </div>
            <TextBox
              style={{ marginBottom: "8px" }}
              className="textBoxcustom"
              placeholder="Search by Customer Name, Invoice No"
              name="roomcost"
              value={searchQuery}
              onChange={handleSearchChangess}
              suffix={() => (
                <>
                  <span className="input-icon">
                    {searchQuery ? (
                      <Button icon="close" onClick={resetSearch} />
                    ) : (
                      <Button icon="search" />
                    )}
                  </span>
                </>
              )}
            />
            {/* <Input
              placeholder="Search by Customer Name, Invoice No"
              value={searchQuery}
              onChange={handleSearchChangess}
              style={{ marginBottom: "10px", width: "300px" }}
            /> */}
          </div>

          <Grid
            pageable={getPageableSettings}
            skip={dataState.skip}
            take={dataState.take}
            total={filteredInvoices.length}
            onPageChange={(e) => setDataState(e.page)}
            onDataStateChange={handlePageChange}
            data={process(filteredInvoices, dataState)}
            selectable={{ mode: "single" }}
          >
            <GridColumn
              width="50px"
              title=""
              cell={(props) => (
                <td>
                  <input
                    type="checkbox"
                    checked={selectedInvoices.some(
                      (invoice) =>
                        invoice.invoiceNo === props.dataItem.invoiceNo
                    )}
                    onChange={(e) => handleSelectionChange(e, props.dataItem)}
                  />
                </td>
              )}
            />
            <GridColumn field="invoiceNo" title="Invoice No." />
            <GridColumn field="customerName" title="Customer Name" />
            <GridColumn field="dateIssued" title="Date Issued" />
            <GridColumn
              field="totalAmount"
              title="Total Amount"
              cell={(props) => (
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingRight: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    {props.dataItem.totalAmount}
                  </div>
                </td>
              )}
            />
            <GridColumn
              field="amountDue"
              title="Amount Due"
              cell={(props) => (
                <td>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "end",
                      paddingRight: "50%",
                      alignItems: "flex-end",
                    }}
                  >
                    {props.dataItem.amountDue}
                  </div>
                </td>
              )}
            />
            <GridColumn
              title="Payment Status"
              cell={(props) => (
                <td>
                  <div
                    style={{
                      color:
                        props.dataItem.paymentStatus === "Paid"
                          ? "green"
                          : props.dataItem.paymentStatus === "Pending"
                          ? "red"
                          : props.dataItem.paymentStatus === "Partial"
                          ? "orange"
                          : "grey",
                    }}
                  >
                    {props.dataItem.paymentStatus}
                  </div>
                </td>
              )}
            />
            <GridColumn
              title="Actions"
              cell={(props) => (
                <div>
                  <MyViewCommandCell {...props} enterView={enterView} />
                </div>
              )}
            />
          </Grid>
        </div>
      )}
    </div>
  );
};
